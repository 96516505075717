.dashboard {
  flex: 1;
  margin: 0;
  padding: 0;
  
  .col-md-2.sidebar {
     padding: 0;
     background-color: $navy;
     .dashboard-nav {
       min-height: 75vh;
       background-color: $navy;
       ul {
         height: 100%;
         li {
           border-bottom: 2px solid $dash-active-border;
           background-color: $navy;
           padding: 20px;
           &.active {   
             padding-left: 20px;
             border-bottom: 0;
             border-left: 10px solid $navy;
             background-color: $dashboard-nav-active;
           }
           a {
             color: white;
             display: block;
             text-decoration: none;
             .icon {
               vertical-align: middle;
               display: inline-block;
               padding-right: 10px;
               font-size: 25px;
             }
           }
         }
       }
     }
   }
  
  .controls.agent .selected {
    color: white !important;
    background-color: #8b8d8f !important;
  }

  .controls.agent a {
      width: 100%;
      padding: 5px 10px;
      border: 0;
      display: block;
      color: #898989;
      background-color: #d9d9d9 !important;
      border-bottom: 1px solid #CDCDCD;
      text-decoration: none;
      cursor: pointer;
  }
        
  .previous {
      padding-right: 15px;
  }    
  .next {
      padding-left: 15px;
  }
  .selected-page{
    font-weight: bold !important;
    font-size: 17px !important;
    color: #071d49 !important;
  }  
  .col-md-2.sidebar.dashboard-agent {
    background-color: $dashboard-agent;
  }
  
  .dashboard-content {
    background-color: white;
    padding: 0;

    table {
      &.table-bordered {
        th, td {
          border: 1px solid $new-ui-border;
        }
      }
      input:focus {
        border: 1px solid blue;
      }
      th {
        padding: 5px;
        color: $main-nav;
        font-weight: bold;
      }
      td {
        padding: 10px;
        position: relative;
        vertical-align: middle;
        .fa {
          font-size: 18px;
        }
        .controls {
          top: 0;
          width: 150px;
          right: -160px;
          position: absolute;
          display: none;
          &.show {
            display: block;
          }
          .btn {
            width: 100%;
          }
          &.agent {
            width: 100px;
            right: -100px;
            border: 1px solid $fixed;
            button {
              width: 100%;
              padding: 5px 10px;
              border: 0;
              display: block;
              color: $main-nav;
              border-bottom: 1px solid $fixed;
              &:hover {
                cursor: pointer;
              }
              &.selected {
                color: white;
                background-color: $dark-grey;
              }
            }
          }
        }
      }
      &.shorter {
        width: 80%;
      }
      &.shorter-2{
        width:90%;
      }
    }
    
    .row {
      &.border-top-bottom {
        padding-top: 15px;
        border-top: 1px solid $dark-grey;
        border-bottom: 1px solid $dark-grey;
        background-color: $dashboard-content;
      }
    }

    .text-container-dashboard {
      h4 {
        display: inline;
      }
    }
    
    h2 {
      span {
        color: grey;
      }
    }
    
    .dropdown {
      .btn {
        color: black;
        border: 1px solid black;
      }
    }
    
    .card {
      margin: 40px 0;
      &.analytics {
        position: relative;
        img {
          margin: 0 auto;
          display: block;
          max-width: 130px;
        }
        img[src$=".svg"] {
          right: 5px;
          bottom: 5px;
          position: absolute;
        }
        .card-block {
          min-height: 300px;
          padding-top: 70px;
          &.minor-padding {
            padding-top: 30px;
            height: 400px;
          }
        }
        &.disabled {
          opacity: 0.6;
        }
      }
      .update-unit-info {
        position: relative;
        .edit-buttons {
          width: 100%;
          bottom: 30px;
          display: flex;
          position: absolute;
          .edit {
            color: $navy;
            min-width: 20%;
            margin-left: 3%;
            border: 1px solid $navy;
            background-color: white;
            &.active {
              background-color: $navy;
              color: white;
            }
          }
        }
        .btn-primary {
          top: 20px;
          right: 30px;
          position: absolute;
        }
      }
      .card-header {
        background-color: $dashboard-card-header;
        &.card-header-white {
          background-color: white;
        }
      }
      .card-block {
        padding: 40px;
        &.small-padding {
          padding: 20px;
        }
        .logos-wrapper {
          display: flex;
          .logo-container {
            text-align: center;
            margin-right: 40px;
            .logo-block {
              color: $sold;
              width: 200px;
              height: 200px;
              padding: 15px;              
              border: 1px solid $sold;
              .logo-block-text {
                .icon {
                  display: block;                
                  font-size: 100px;  
                  margin: -30px 0 -40px;
                }
                a {
                  color: $sold;
                  text-decoration: underline;
                }
              }
              .logo-uploaded {
                display: none;
                position: relative;
                .icon {
                  top: 10px;
                  right: 10px;
                  color: white;
                  font-size: 20px;
                  position: absolute;
                }
              }
            }
            .logo-title {
              margin-top: 20px;
            }
          }
        }
        .comp-projects {
          margin-top: -20px;
          font-weight: 900;
          font-size: 12px;
          display: block;
        }
        .add-more {
          color: black;
          text-decoration: none;
        }
        .scrolling-area {
          height: 300px;
          overflow-y: scroll;
          padding-bottom: 20px;
        }
        div.strike-through-text {
          -webkit-text-decoration-line: line-through; /* Safari */
          text-decoration-line: line-through; 
        }
      }
    }
    
    .dashboard-btn-container {
      .btn {
        color: white;
        padding: 15px;
        min-width: 100px;
        margin-right: 20px;
        &.btn-cancel {
          background: $red;
        }
        &.btn-register {
          background: $navy;
        }
      }
    }
    .nice-select {
      width: 100%;
      height: 38px;
      border-radius: 0;
      .list {
        width: 100%;
        border-radius: 0;
      }
    }
  }

  ul.footer-pagination li small {
    a {
      color: #0077c8;
    }
    
    a:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    a.disabled {
      color: inherit;
      pointer-events: none;
    }
  }
  
  label span.mandatory {
    color: red;
  }
}

.dashboard-eoi-level {
  p {
    margin-bottom: .8rem;
  }
}

.dashboard-scroll {
  width: 1000px;
  overflow: auto;
  min-height: 400px;
  white-space: nowrap;
  padding-bottom: 10px;
  .buttons-row {
    display: flex;
    gap: 0.25rem;
    .btn-container {
      flex-shrink: 0;
      flex-basis: 95px;
      /*margin-right: 2px;*/
      position: relative;
      button {
        border: 0;
        min-height: 30px;
        height: 100%;
        width: 100%;
        max-width: 95px;
        color: white;
        font-size: 14px;
        /*margin-left: 3px;
        margin-bottom: 5px;*/
        z-index: 1;
        text-align: center;

        word-wrap: break-word;
        white-space: normal;

        .stack-cell-info {
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: normal;
          white-space: nowrap;
        }
      }
      .dropdown {
        top: 5px;
        left: 90%;
        z-index: -1;
        opacity: 0;
        width: 250px;
        background: white;
        position: absolute;
        border: 1px solid $fixed;
        //transition: opacity .4s ease-in-out;
        &:before {
          top: 5px;
          width: 0;
          height: 0;
          left: -5px;
          content: "";
          border-style: solid;
          border-width: 5px 5px 5px 0;
          border-color: transparent white transparent transparent;
          position: absolute;
        }
        p {
          margin: 0;
          padding: 10px;
          color: black;
          font-size: 14px;
          border-bottom: 1px solid $fixed;
          span {
            padding-left: 20px;
          }
          &:last-child {
            border: 0;
          }
        }
      }
      .dropup {
        top: -145px;
        left: 90%;
        z-index: -1;
        opacity: 0;
        width: 250px;
        background: white;
        position: absolute;
        border: 1px solid $fixed;
        //transition: opacity .4s ease-in-out;
        &:before {
          bottom: 10px;
          width: 0;
          height: 0;
          left: -5px;
          content: "";
          border-style: solid;
          border-width: 5px 5px 5px 0;
          border-color: transparent white transparent transparent;
          position: absolute;
        }
        p {
          margin: 0;
          padding: 10px;
          color: black;
          font-size: 14px;
          border-bottom: 1px solid $fixed;
          span {
            padding-left: 20px;
          }
          &:last-child {
            border: 0;
          }
        }
      }
      &:hover {
        .dropdown {
          opacity: 1;
          z-index: 2;
        }
        .dropup {
          opacity: 1;
          z-index: 2;
        }
      }
    }
  }
}
.w-full {
  width: 100% !important;
}
.db-darker-navy {
  background-color: $db-darker-navy;
}
.db-dark-navy {
  background-color: $db-dark-navy;
}
.db-light-navy {
  background-color: $db-light-navy;
}
.db-blue {
  background-color: $db-blue;
}
.db-lt-blue {
  background-color: $db-lt-blue;
}
.db-cyan {
  background-color: $db-cyan;
}
.db-lt-gray {
  background-color: $db-lt-gray;
}

.legend-container {
  display: flex;
  gap: 1px;
  flex-grow: 1;
  .legend-block {
    /*flex-shrink: 0;*/
    flex-basis: 85px;
    .legend-color {
      height: 30px;
    }
    .legend {
      margin-top: 5px;
      font-size: 12px;
      text-align: center;
    }
  }
}

.type {
  width: 100%;
  border: 1px solid $fixed;
  .count {
    padding: 10px 15px;
    display: flex;    
    border-bottom: 1px solid $fixed;
    &:last-child {
      border: 0;
    }
    p {
      margin: 0;
      flex: 1 50%;
    }
  }
}

.unit-btn-container {
  display: inline-block;
  button.unit {
    border: 0;
    height: 60px;
    width: 160px;
    color: $dark-grey;
    margin-left: -4px;
    border-right: 1px solid $fixed;
    &.selected {
      color: white;
      background-color: $dark-grey;
    }
    &:first-child {
      border-left: 1px solid $fixed;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.tabs-btn-container {
  display: inline-block;
  button.tab {
    border: 0;
    height: 40px;
    min-width: 170px;
    max-width: 220px;
    color: $dark-grey;
    margin-left: 0px;
    border-right: 1px solid $fixed;
    &.selected {
      color: white;
      background-color: $dark-grey;
    }
    &:first-child {
      border-left: 1px solid $fixed;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.card-block {
  &.border-top {
    border-top: 1px solid $fixed;
  }

  table {
    font-size: 14px;
    margin-bottom: 0;
    td {
      &.saved {
        background-color: yellow;
      }
      &.edited {
        border: 1px solid blue;
      }
    }
  }

  label {
    font-size: 14px;
  }
}

.add-agency {
  padding: 15px;
  border: 1px dashed $fixed;
  &:hover {
    cursor: pointer;
  }
}

.authorisation {
  padding: 100px 30px;
  background-color: $dashboard-content;
}

.unit-selection-filter {
  background-color: $dashboard-content;
  select {
    width: 100%;
  }
}

.otp-doc-container {
  height: 500px;
  width: 80%;
  overflow-y: scroll;
}

.document-navigation-container {
  height: 70px;
  display: flex;
  button {
    border: 0;
    outline: 0;
    font-size: 14px;
    color: $main-nav;
    border: 1px solid $fixed;
    flex: 1 calc(100% / 6 - 50px);
    &:hover {
      cursor: pointer;
    }
    &.nav-control {
      color: white;
      flex: 1 25px;
      background: $dark-grey;
    }
    &.selected {
      color: white;
      background-color: $dt;
    }
  }
}

.fixed-height {
  .scroll {
    height: 500px;
    overflow: auto;
  }
}

@media screen and (max-width: 1024px) {
  .col-sm-12.col-md-10.dashboard-content .col-lg-9 {
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .row-offcanvas {
    position: relative;
    transition: all 0.25s ease-out;
  }

  .row-offcanvas-left
  .sidebar-offcanvas {
    left: -50%;
  }

  .row-offcanvas-left.active {
    left: 50%;
  }

  .sidebar-offcanvas {
    top: 0;
    width: 50%;
    margin-left: 12px;
    position: absolute;
    transform: translate3d;
  }
  
  .col-md-10.dashboard-content {
    .text-right {
      margin-top: 20px;
      text-align: left !important;  
    }
    .col-lg-9 {
      padding-left: 30px;
    }
  }
}

.dashboard-title-panel {
  top: 0;
  width: 200px;
  left: 80px;
  bottom: 0;
  z-index: 1;
  height: 100vh; 
  color: white;
  position: fixed;
  transition: 0.5s;
  overflow-x: hidden;
  background-color: $mild-navy;
  h1 {
    color: white;
    padding: 15px;
    margin: 0;
  }
  .content {
    padding: 15px;
    color: white;

    a {
      color: white;
      font-size: 14px;
      display: block;
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  opacity: 0.25;
  background-color: #e5e8ef;
  &.dark {
    background-color: $lavender-blue;
  }
  &.white {
    background-color: white;
  }
  &.top {
    opacity: 1;
  }
}

.dms-content {
  top: 0;
  right: 0;
  min-height: 100%;
  position: absolute;
  overflow-x: hidden;
  height: auto !important;
  width: calc(100% - 80px);
  transition: margin-left .5s;
  background-color: $dashboard-bg;
  &.no-side-panel {
    width: calc(100% - 80px);
  }
  h2 {
    color: $navy;
    font-size: 21px;
  }
  h3 {
    color: #00204e;
    font-size: 48px;
    font-weight: bold;
  }
  .custom-select {
    width: 100%;
    border-radius: 4px;
  }
  .top-content {
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 32, 78, 0.25);
    header {
      display: flex;
      justify-content: flex-end;
      .list-unstyled {
        padding: 8px 0;
        margin-bottom: 0;
        margin-right: 100px;
        li {
          color: $navy;
          font-size: 14px;
          font-weight: bold;
          margin-left: 20px;
          position: relative;
          display: inline-block;
          position: relative;
          .quantity {
            top: -7px;
            right: -12px;
            width: 22px;
            height: 22px;
            color: white;
            font-size: 10px;
            padding-top: 5px;
            border-radius: 50%;
            position: absolute;
            text-align: center;
            background-color: $error-red;
          }
          .icon {
            font-size: 18px;
          }
          img {
            border-radius: 50%;
          }
          a {
            color: $navy;
            text-decoration: none;
          }
          .topbar-dropdown {
            display: none;
            top: 40px;
            right: 0;
            width: 287px;
            z-index: 1000;
            border-radius: 4px;
            position: absolute;
            background-color: white;
            box-shadow: 0 1px 3px 0 rgba(31, 80, 156, 0.08), 0 4px 6px 0 rgba(31, 80, 156, 0.25);
            span, button {
              display: block;
              margin: 10px;
            }
            span {
              &.user-name {
                color: $navy;
                font-size: 14px;
                font-weight: bold;
              }
              &.user-email {
                font-size: 12px;
                font-weight: 500;
                color: $carolina-blue;
              }
            }
            .links-container {
              margin-top: 17px;
              a {
                color: $navy;
                padding: 10px;
                font-size: 12px;
                font-weight: 500;
                display: block;
                &:last-child {
                  border-radius: 0 0 4px 4px;
                  background-color: $ice-blue;
                }
              }
            }
          }
        }
      }
    }
    .breadcrumbs {
      margin: 0;
      padding: 20px;
      list-style-type: none;
      li {
        display: inline;
        font-size: 14px;
        color: black;
        a {
          color: #2a6ebb;
        }
      }
    }
    p {
      margin: 0;
      color: $navy;
      padding: 20px;
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.dms-info-panel {
  color: $navy;
  padding: 20px;
  margin: 20px 0;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.08), 
              0 0 3px 1px rgba(6, 44, 82, 0.1);
  &.gradient {
    color: white;
    background-color: $mild-navy;
    h2 {
      color: white;
    }
    p {
      margin-top: 20px;
      font-weight: bold;
      font-size: 14px;
      .icon {
        font-size: 30px;
        display: block;
      }
      cursor: pointer;
    } 
  }
  &.project {
    padding: 0;
    width: 100%;
    
    figure {
      height: 100px;
      position: relative;
      .edit {
        top: 5px;
        right: 5px;
        width: 24px;
        height: 24px;
        display: table;
        color: white;
        cursor: pointer;
        padding-top: 5px;
        position: absolute;
        border-radius: 50%;
        background-color: $navy;
        .icon {
          font-size: 12px;
          text-align: center;
          display: table-cell;
        }
      }
      figcaption {
        color: $navy;
        padding: 5px 0;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        background-color: white;
      }
    }
    
  }
  .add-project {
    padding: 20px;
    cursor: pointer;
    text-align: center;
    color: $medium-blue;
    i {
      display: block;
    }
    .title {
      color: $navy;
      display: block;
    }
    .generic-quantity {
      font-size: 42px;
    }
  }
  .add-project-sale {
    cursor: pointer;
    text-align: center;
    color: $medium-blue;
    i {
      display: block;
    }
    .title {
      color: $navy;
      display: block;
    }
    .generic-quantity {
      font-size: 42px;
    }
  }
  .project-quick-info {
    padding: 10px;
    font-size: 12px;
    h5 {
      color: $navy;
      padding: 10px 0;
      font-weight: bold;
    }
    p {
      color: $navy;
      font-size: 18px;
    }
    span {
      display: block;
      &.date {
        color: $lavender-blue;
        font-weight: bold;
      }
      &.time {
        color: $cornflower;
        padding-bottom: 10px;
      }
      &.highlight {
        padding: 10px 0;
        font-size: 28px;
        font-weight: bold; 
        color: $medium-blue;
        &.limegreen {
          color: $lime-green;
        }
        i {
          display: inline-block;
        }
      }
    }
    a {
      font-size: 14px;
      font-weight: bold;
      color: $medium-blue;
      padding-top: 5px;
    }
  }
}

.list-unstyled {
  &.tabs {
    li {
      margin-right: 20px;
      display: inline-block;
      color: $navy;
      font-size: 21px;
      font-weight: bold;
      text-decoration: none;
      cursor: pointer;
      &.selected {
        color: $medium-blue;
        padding-bottom: 20px;
        border-bottom: 2px solid $medium-blue;
      }
      a {
        i[class*="angle"] {
          font-size: 30px;
        }
      }
    }
  }
}

.project-navigation {
  height: 80px;
  display: flex;
  padding: 20px;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
  align-items: center;
  background-color: white;
  justify-content: space-between;

  &--variation {
    justify-content: initial;

    span {
      &.view-title {
        margin-left: 20%;
        display: inline-block;
      }
    }
  }

  .view-icon {
    width: 60px;
    height: 60px;
    img {
      width: 100%;
      height: auto;
    }
  }
  span {
    color: $navy;
    &.view-title {
      font-size: 14px;
      font-weight: bold;
    }
  }
  &.selected {
    border: 1px solid $medium-blue;
    background-color: lightblue;
  }
  .options-dropdown {
    left: 0;
    right: 0;
    top: 80px;
    width: 100%;
    height: auto;
    padding: 20px;
    z-index: 1000;
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.08), 
                0 0 3px 1px rgba(6, 44, 82, 0.1);
    a {
      color: $navy;
      display: block;
      padding: 10px 0;
      font-size: 14px;
      font-weight: bold;
    }
    &.active {
      display: block;
    }
  }
}

.card {
  box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.08), 0 0 3px 1px rgba(6, 44, 82, 0.1);
  &.grey-header {
    border-radius: 4px;
    .card-header {
      background-color: $dashboard-bg;
      font-weight: bold;
      font-size: 23px;
      color: $navy;
      button.primary {
        padding: 0px 10px;
        box-shadow: 0 1px 3px 0 rgba(31, 80, 156, 0.08), 0 4px 6px 0 rgba(31, 80, 156, 0.25);
      }
      .pull-right {
        display: flex;
        align-items: center;
      }
    }
    .card-block {
      padding: 20px;
      &.grey {
        background-color: $dashboard-bg;
      }
      input[type="text"] {
        border-radius: 3px;
      }
      a.cancel {
        display: block;
        font-size: 14px;
        padding-top: 8px;
        font-weight: bold;
        color: $medium-blue;
      }
      p {
        &.title {
          font-size: 14px;
          font-weight: bold;
          color: $cornflower;
          text-transform: uppercase;
        }
        &.info {
          color: $navy;
          font-size: 16px;
          font-weight: bold;
          span {
            &.minus {
              width: 9px;
              height: 4px;
              margin-right: 5px;
              display: inline-block;
              background-color: red;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}

.drag-and-drop {
  padding: 50px;
  border-radius: 2px;
  text-align: center;
  color: $lavender-blue;
  border: 1px dashed $lavender-blue;
  i {
    font-size: 40px;
    padding-bottom: 30px;
  }
  span {
    display: block;
    font-size: 12px;
    font-weight: bold;
  }
}

.eoi-phase-nav {
  float: right;
  &:after {
    display: table;
    clear: both;
  }
  ul {
    display: flex;
    list-style-type: none;
    li {
      margin-left: 20px;
      a {
        font-size: 14px;
        font-weight: bold;
        color: $cornflower;
        display: inline-block;
        vertical-align: middle;
        text-transform: uppercase;
        .bedroom {
          width: 16px;
          height: 16px;
          border-radius: 4px;
          display: inline-block;
          &.bed-1 {
            background-color: $bed1;
          }
        }
        i {
          font-size: 20px;
        }
      }
    }
  }
}

.storey-wrapper {
  max-width: 1040px;
  overflow-x: scroll;
  .storey-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .level {
      color: $navy;
      width: 20px
    }
    button {
      width: 132px;
      height: 49px;
      border-radius: 4px;
      margin: 0 20px;
    }
  }
}

.eoi-phase-input {
  margin-bottom: 20px;
  position: relative;
  width: 250px;
  input {
    color: pink;
  }
  .fa {
    top: 50%;
    right: 10px;
    position: absolute;
    transform: translateY(-50%);
  }
  &.eoi-phase-input--fill {
    width: 100%;
  }
}

.dms-filter-tags {
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
    font-weight: bold;
  }
}

.promo-codes-wrapper {  
  .promo-code-controls {
    display: flex;
    height: 70px;
    border-top: 1px solid $lavender-blue;
    border-bottom: 1px solid $lavender-blue;
    &.no-border {
      .control {
        text-align: center;
        width: calc(100% / 11);
        justify-content: center;
        &:first-child {
          border-right: 0;
          padding: 0 40px;
        }
      }
    }
    &.input-editing {
      .control {
        &:first-child {
          border: none;
        }
        input::placeholder, select {
          color: $carolina-blue;
        }
        input:focus, select:focus {
          color: $medium-blue;
          border: 1px solid $medium-blue;
        }
      }
    }
    .control {
      display: flex;
      font-size: 14px;
      cursor: pointer;
      font-weight: bold;
      position: relative;
      padding: 0 20px;
      line-height: 15px;
      align-items: center;
      color: $carolina-blue;
      text-transform: uppercase;
      width: calc(100% - 150px / 6);
      i {
        font-size: 20px;
        margin-left: 10px;
      }
      &:first-child {
        justify-content: flex-end;
        border-right: 1px solid $lavender-blue;
      }
      &.control--6-children {
        &:first-child {
          justify-content: center;
          width: 50px;
        }
      }
      &.control--5-children {
        &:first-child {
          width: 50px;
          justify-content: center;
        }
      }
    }
    &.less {
      .control {
        justify-content: center;
        width: calc((100% - 10%) / 5);
        &:first-child {
          border-right: 0;
          width: 10%;
        }
      }
    }
    &--eight-items {
      .control {
        width: calc(100% / 8);
        &:first-child {
          border-right: 0;
          justify-content: center;
        }
      }
    }
    &--nine-items {
      .control {
        width: calc(100% / 9);
        &:first-child {
          border-right: 0;
          justify-content: center;
        }
      }
    }
  }
  .promo-code-row {
    display: flex;
    height: 70px;
    border-bottom: 1px solid $lavender-blue;
    transition: .5s all ease-in-out;
    &:nth-child(odd) {
      background-color: $dashboard-bg;
    }
    input[type="text"], select {
      border: 1px solid $medium-blue;
    }
    .promo-code-control, .promo-code-control label .custom-control-description {
      color: $navy;
      font-size: 16px;
      font-weight: bold;
    }
    .promo-code-control {
      display: flex;
      padding: 0 20px;
      align-items: center;
      position: relative;
      width: calc(100% - 150px / 6);
      .fa-angle-right {
        right: 20px;
        font-size: 30px;
        font-weight: bold;
        position: absolute;
      }
      button.primary {
        width: 150px;
        height: 40px;
        font-size: 14px;
        font-weight: bold;
      }
      .fa {
        &.fa-caret-down {
          color: $medium-blue;
          margin-left: 10px;
        }
      }
      &.applied {
        color: $cornflower;
      }
      &:first-child {
        justify-content: flex-end;
        border-right: 1px solid $lavender-blue;
      }
      .fixed-container {
        display: flex;
        .fa {
          font-size: 20px;
          padding-top: 3px;
        }
      }
      .dynamic-container {
        display: flex;
        display: none;
        .dms-control {
          margin-left: 5px;
        }
      }
      &.promo-code-control--6-children {
        &:first-child {
          justify-content: center;
          width: 50px;
        }
      }
      &.promo-code-control--5-children {
        &:first-child {
          justify-content: center;
          width: 50px;
        }
      }
    }
    &.promo-code-row--no-hover {
      &:hover {
        cursor: default;
        border: none;
      }
    }
    &:hover {
      cursor: pointer;
      background-color: $mild-blue;
      border: 1px solid $medium-blue;
      .promo-code-control {
        .fixed-container {
          display: none;
        }
        .dynamic-container {
          display: block;
        }
      }
    }
    &.no-border {
      .promo-code-control {
        font-size: 15px;
        line-height: 1.2;
        text-align: center;
        width: calc(100% / 11);
        justify-content: center;
        
        &:first-child {
          border-right: 0;
        }
      }
    }
    &.less {
      .promo-code-control {
        justify-content: center;
        width: calc((100% - 10%) / 5);
        .fa-caret-right {
          color: $medium-blue;
          font-size: 30px;
        }
        &:first-child {
          border-right: 0;
          width: 10%;
        }
      }
    }
    &--eight-items {
      .promo-code-control {
        width: calc(100% / 8);
        &:first-child {
          border-right: 0;
          justify-content: center;
        }
        &:last-child {
          justify-content: center;
        }
        .fa-angle-right {
          position: relative;
        }
      }
    }
    &--nine-items {
      .promo-code-control {
        width: calc(100% / 9);
        &:first-child {
          border-right: 0;
          justify-content: center;
        }
        &:last-child {
          justify-content: center;
        }
        .fa-angle-right {
          position: relative;
        }
      }
    }
    &--one-item {
      .promo-code-control {
        &:first-child {
          justify-content: center;
          border-right: none;
        }
      }
    }
  }
}

.promo-code-nav {
  display: flex;
  justify-content: space-between;
  .middle, .end {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    color: $cornflower;
    ul {
      display: flex;
      margin: 0;
      li {
        margin: 0 15px;
        a {
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          color: $cornflower;
        }
        &.selected {
          a {
            color: $navy;
            border-bottom: 1px solid $navy;
          }
        }
      }
    }
  }
  .middle {
    justify-content: center;
    width: calc(100% - 200px);
  }
  .end {
    width: 200px;
    justify-content: flex-end;
  }
}

.project-table {
  .project-table-nav {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid $lavender-blue;
    a {
      padding: 10px;
      font-size: 14px;
      font-weight: bold;
      color: $carolina-blue !important;
      text-transform: uppercase;
      border-left: 1px solid $carolina-blue;
      i {
        font-size: 30px !important;
      }
    }
  }
  .project-table-header, .project-table-row {
    display: flex;
    border-bottom: 1px solid $lavender-blue;

    .control, .content {
      width: calc(100% / 6);
      text-align: center;
      &:first-child {
        text-align: left;
        padding-left: 10px;
      }
    }
    .control {
      font-size: 14px;
      font-weight: bold;
      color: $carolina-blue;
      text-transform: uppercase;
    }
    .content {
      padding: 20px 0;
      color: $navy;
    }
  }
  .project-table-header {
    padding: 10px 0;
    cursor: pointer;
  }
  .project-table-row {
    &:nth-child(even) {
      background-color: $dashboard-bg;
    }
  }
  &.smaller {
    .project-table-header {
      border-top: 1px solid $lavender-blue;
      align-items: center;
      padding: 5px 0;
      .control {
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        text-transform: uppercase;
      }
    }
    .project-table-row {
      &:nth-child(even) {
        background-color: white;
      }
      .content {
        &:first-child {
          font-weight: bold;
        }
      }
    }
  }
  &.wider {
    .project-table-header, .project-table-row {
      .control, .content {
        width: calc(100% / 7);
      }
    }
  }
  &.large {
    .project-table-header, .project-table-row {
      .control, .content {
        width: calc(100% / 9);
      }
    }
  }
}

.dms-grid-project-card {
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.08), 0 0 3px 1px rgba(6, 44, 82, 0.1);
  .project-thumb {
    height: 200px;
    position: relative;
    .fa-ellipsis-v {
      top: 10px;
      right: 10px;
      color: white;
      cursor: pointer;
      position: absolute;
    }
  }
  h3 {
    padding: 10px;
    color: $navy;
    span {
      margin-right: 5px;
    }
  }
  .status {
    display: flex;
    justify-content: space-between;
    span {
      padding: 10px;
      font-size: 12px;
      font-weight: 500;
      &.status {
        &.active {
          color: $lime-green;
        }
      }
      &.date {
        color: $cornflower;
      }
    }
  }
}

.create-phase-dropdown {
  position: relative;
  .dropdown-wrapper {
    top: 25px;
    left: 15px;
    width: 120px;
    position: absolute;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.08), 
                0 0 3px 1px rgba(6, 44, 82, 0.1);
    a {
      padding: 5px;
      color: $navy;
      display: block;
      font-size: 14px;
      font-weight: bold;
      text-decoration: none;
      transition: .3s ease-in-out;
      &:first-child {
        border-radius: 4px 4px 0 0;
      }
      &:last-child {
        border-radius: 0 0 4px 4px;
      }
      &:hover {
        color: white;
        background: $medium-blue;
      }
    }
    &.right {
      top: 35px;
      left: -90px;
    }
  }
}

.search-filter-control-wrapper {
  display: flex;
  .search-controls-container {
    width: 85%;
    display: flex;
    justify-content: space-between;
    border-right: 1px solid $lavender-blue;
    .checkbox-container, .rows-number-select-container {
      display: flex;
      align-items: center;
      padding: 15px;
      p {
        width: 200px;
        font-size: 14px;
        font-weight: bold;
        color: $carolina-blue;
      }
      select {
        width: 70px;
        margin-left: 10px;
      }
    }
  }
  .filter-by-controls {
    width: 15%;
    display: flex;
    padding: 15px 0;
    align-items: center;
    justify-content: space-around;
    .icon {
      &.selected {
        color: $medium-blue;
      }
      cursor: pointer;
    }
    &.fewer {
      width: 10%;
      border-left: 1px solid $lavender-blue;
    }
  }
}

.bottom-search-filters {
  display: flex;
  justify-content: space-between;
  .filtered-items,
  .filtering-nav,
  .filter-dropdown {
    display: flex;
    align-items: center;
  }
  .filtered-items {
    p {
      color: $navy;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .filtering-nav {
    ul {
      display: flex;
      li {
        a {
          font-size: 14px;
          font-weight: bold;
          margin-left: 10px;
          color: $carolina-blue;
          &.selected {
            color: $medium-blue;
            border-bottom: 1px solid $medium-blue;
          }
          .fa {
            font-size: 25px;
            font-weight: bold;
          }
        }
      }
    }
  }
  .filter-dropdown {
    p {
      width: 150px;
      font-size: 16px;
      font-weight: bold;
      color: $carolina-blue;
    }
  }
}

.pricelist-schedule {
  display: flex;
  .pricelist-calendar {
    padding: 0 40px;
  }
  .pricelist-info {
    padding: 20px 40px;
    p {
      color: $navy;
      font-size: 20px;
      font-weight: bold;
      margin: 0;
    }
    a {
      font-size: 14px;
      font-weight: bold;
      color: $medium-blue !important;
      text-decoration: underline !important;
    }
  }
}

.pull-right {
  span {
    font-size: 16px;
    font-weight: bold;
    margin-right: 5px;
    color: $medium-blue;
    display: inline-block;
  }
}

.calendar-wrapper {
  .schedule-price {
    font-size: 23px;
    font-weight: bold;
    color: $medium-blue;
    span {
      width: 14px;
      height: 14px;
      margin-right: 5px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      background-color: $medium-blue;
    }
  }
  .calendar-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      color: $navy !important;
      i {
        font-size: 40px !important;
        font-weight: bold !important;
      }
    }
    h3 {
      color: $navy;
      font-size: 26px;
      font-weight: bold;
    }
  }
  .calendar-days-wrapper {
    .calendar-day-row {
      display: flex;
      padding: 25px 0;
      justify-content: space-between;
      .calendar-day {
        width: 18px;
        font-size: 18px;
        color: $carolina-blue;
        span {
          width: 8px;
          height: 8px;
          display: block;
          border-radius: 50%;
          background-color: $medium-blue;
        }
        &.header {
          color: $navy;
          font-size: 23px;
          font-weight: bold;
        }
      }
    }
  }
}

.function-container {
  padding: 20px;
  display: flex;
  a {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: $medium-blue !important;
  }
  button {
    margin-left: 20px;
  }
}
.unit-assets-wrapper {
  height: 210px;
  display: flex;
  background-color: $lavender-blue;
  .drop-in-assets {
    width: 30%;
    padding: 25px;
    .image-drop {
      height: 160px;
      padding-top: 40px;
      text-align: center;
      color: $carolina-blue;
      background-color: white;
      border: 1px dashed $lavender-blue;
      .icon {
        display: block;
        font-size: 40px;
      }
      span {
        font-size: 12px;
      }
    }
  }
  .unit-information {
    width: 50%;
    padding: 25px 0;
    border-right: 1px solid $navy;
  }

  .unit-controls {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    .buttons-container {
      display: flex;
      max-width: 60%;
      flex-direction: column;
      button {
        width: 100%;
        padding: 10px;
        display: block;
        font-size: 14px;
        font-weight: bold;  
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.add-clause {
  padding: 10px;
  display: flex;
  cursor: pointer;
  border-radius: 4px;
  color: $medium-blue;
  background-color: white;
  border: 1px dashed $medium-blue;
  font-size: 14px;
  font-weight: bold;
  i {
    margin-right: 5px;
  }
}

.stackview-project-filter {
  color: $navy;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  select {
    width: 30%;
    margin-left: 20px;
  }
}

.stackview-tabs {
  padding: 20px;
  border-bottom: 1px solid $lavender-blue;
  a {
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    color: $carolina-blue !important;
    &.active {
      color: $medium-blue !important;
      border-bottom: 1px solid $medium-blue !important;
    }
  }
}

.stackview-nav-container {
  .stackview-navigation {
    display: flex;
    &.stackview-navigation--blocktower {
      button {
        margin-right: 20px;
      }
    }
    &.stackview-navigation--status, &.stackview-navigation--bed-filters {
      justify-content: space-between;
    }
  }
}

.stackview-tower-container {
  width: 900px;
  overflow-x: scroll;
  .stackview-tower-row {
    display: flex;
    font-size: 10px;
    margin: 10px 0;
    font-weight: bold;
    align-items: center;
    color: $carolina-blue;
    .stackview-tower-row__unit {
      width: 72px;
      margin-right: 5px;
      button {
        width: 100%;
      }
    }
  }
}

.stackview-tower-legend {
  padding: 0 5%;
  display: flex;
  .stackview-tower-legend-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    width: calc((100% /7) - 10px);
    .stackview-tower-legend-visual {
      width: 40px;
      height: 30px;
      margin: 0 auto 10px;
      border-radius: 4px;
      &.stackview-tower-legend-visual--abd {
        border: solid 3px $stackview-abd;
      }
      &.stackview-tower-legend-visual--rba {
        border: 3px solid $post-it-blue;
      }
      &.stackview-tower-legend-visual--bba {
        border: 3px solid $stackview-bba;
      }
      &.stackview-tower-legend-visual--sold {
        border: 3px solid $error-red;
      }
      &.stackview-tower-legend-visual--selected {
        background-color: $ice-blue;
        border: solid 1px $medium-blue;
      }
      &.stackview-tower-legend-visual--unreleased {
        border: solid 1px $lavender-blue;
      }
      &.stackview-tower-legend-visual--of {
        background-color: $lavender-blue;
      }
    }
    .stackview-tower-legend-desc {
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      color: $navy;
    }
  }
}

.new-project-add {
  width: 146px;
  height: 136px;
  display: flex;
  border-radius: 4px;
  color: $medium-blue;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white; 
  box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.08), 0 0 3px 1px rgba(6, 44, 82, 0.1);
  &:hover {
    cursor: pointer;
  }
  span {
    display: block;
    font-size: 14px;
    font-weight: bold;
  }
}

a.all {
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  padding-bottom: 20px;
  border-bottom: 1px solid $medium-blue;
}

.main-buyer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    width: 75px;
    height: 75px;
    margin: 0 auto;
  }
  span {
    display: block;
    font-size: 14px;
    font-weight: normal;
    color: $medium-blue;
  }
}

.generated-documents-list__row {
  display: flex;
  padding: 20px 0;
  .generated-documents-list__block {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: $carolina-blue;
    align-items: center;
    a:link {
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.2px;
      color: $medium-blue;
    }
    &:first-child {
      width: 5%;
    }
    &:nth-of-type(2) {
      width: 35%;
    }
    &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5) {
      width: 20%;
    }
  }
}

.remarks {
  display: flex;
  &__element {
    width: 20%;
  }
}

.agent-tagger-cobroker {
  display: flex;
  padding: 20px 0;
  align-items: center;
  &__element {
    text-align: center;
    &:nth-of-type(1), &:nth-of-type(4), &:nth-of-type(5) {
      width: calc(50% / 3);
    }
    &:nth-of-type(2), &:nth-of-type(3) {
      width: 25%;
    }
  }
  &--row {
    border-top: solid 1px $lavender-blue;
    &:nth-child(even) {
      background: $lavender-blue;
    }
    &:nth-child(odd) {
      background: #f5f6f9;
    }
  }
}

.search-input-container {
  position: relative;
  i {
    top: 10px;
    left: 10px;
    position: absolute;
  }
  input {
    padding-left: 30px;
  }
}

.search-btn-container {
  display: flex;
  justify-content: flex-end;

  button {
    width: 135px;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    margin-left: 20px;
  }
}

.project-unit-payment {
  display: flex;
  &:not(:last-child) {
    border-bottom: 1px solid $lavender-blue;
  }
  .project-unit-payment__status {
    width: 5%;
    padding: 20px;
  }
  .project-unit-payment__info {
    width: 65%;
    padding: 20px;
    padding-right: 5%;
    border-right: 1px solid $lavender-blue;
  }
  .project-unit-payment__info__extra {
    width: 30%;
    padding: 20px;
  }
}

.unit-payment__status {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  &.in__progress {
    background-color: $medium-blue;
  }
}

.project-unit-name {
  display: flex;
  align-items: center;
  .project-unit__logo {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: lightgrey;
  }
  .project-unit__name {
    font-size: 23px;
    font-weight: 500;
  }
}

.project-label {
  padding: 3px;
  width: 90px;
  height: 25px;
  margin: 10px 0;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  &--approved {
    color: #017842;
    background-color: #54d290;
  }
}

.project-unit-payment__progress {
  .project-unit-payment__progress__title {
    font-size: 14px;
    font-weight: bold;
    color: $carolina-blue;
    &.percent {
      color: $medium-blue;
    }
  }
}

.project-unit-sale-info {
  font-size: 14px;
  font-weight: 500;
  color: $carolina-blue;
}

.project-unit-payment__info__extra {
  .project-unit-payment__date {
    font-size: 12px;
    font-weight: 500;
    text-align: right;
    color: $carolina-blue;
  }
  .project-unit-payment__view {
    width: 60%;
    display: flex;
    margin: 30px auto;
    flex-direction: column;
    .project-unit-payment__remarks {
      margin: 10px 0;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      color: $post-it-blue;
      letter-spacing: -0.2px;
      display: inline-block;
    }
    button {
      width: 144px;
      height: 48px;
      border-radius: 4px;
      position: relative;
      margin: 0 auto;
      box-shadow: 
      0 1px 3px 0 rgba(31, 80, 156, 0.08), 
      0 4px 6px 0 rgba(31, 80, 156, 0.25);
      i {
        top: 8px;
        right: 10px;
        font-size: 30px;
        position: absolute;
      }
    }
  }
}