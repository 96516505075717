// DMS Reports Download Modals

.modal {


  &.dms-reports-category {
    .modal-dialog {
      max-width: 750px !important;
      .modal-content {
        border-radius: 8px;

        .modal-header {
          .close {
            color: $navy !important;
          }
          border-bottom: 1px solid $carolina-blue;
        }

        .modal-title {
          color: $navy;
          font-size: 23px;
          font-weight: 500;
          padding-left: 0;
          &__secondary {
            color: $navy;
            display: block;
            font-size: 14px;
            font-weight: 500;
          }
        }



        .modal-body {
          padding: 20px;
          padding-bottom: 100px;

          &__paragraph {
            color: $navy;
            padding-top: 50px;
            font-size: 16px;
            text-align: center;
          }

          .dms-report-category-alert__error {
            font-weight: 300;
            font-size: 14px;
            color: $red;

          }
          .dms-modal-input {
            &__title {
              color: $navy;
              font-size: 16px;
              font-weight: bold;
              padding: 15px 0 4px 0;
            }

            &__container {
              position: relative;
              i {
                top: 17px;
                position: absolute;
                color: $carolina-blue;
              }
              &__icon-left {
                .fa {
                  left: 10px;
                }

                input {
                  padding-left: 40px !important;
                }
              }
              &__icon-right {
                .fa {
                  right: 10px;
                  color: $carolina-blue;
                }

                input {
                  padding-left: 10px !important;
                }
              }
              input {
                width: 100%;
                height: 50px;
                border-radius: 4px;
                padding-left: 10px;
                outline: none;
                border: 1px solid $carolina-blue;
                box-shadow: 0px 3px 6px #00204E;

                &:focus {
                  color: $navy;
                  font-weight: bold;
                  text-transform: uppercase;
                }

                &::-webkit-input-placeholder { /* Edge */
                  color: $carolina-blue;
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                  color: $carolina-blue;
                }

                &::placeholder {
                  color: $carolina-blue;
                }
              }
              .dms-modal-input__dropdown {
                left: 0;
                right: 0;
                width: 100%;
                z-index: 1000;
                border-top: 0;
                position: absolute;
                border: 1px solid $carolina-blue;

                &__block {
                  height: 75px;
                  display: flex;
                  align-items: center;
                  padding-left: 20px;
                  font-size: 14px;
                  font-weight: bold;
                  color: $carolina-blue;
                  background-color: white;

                  &:not(:last-child) {
                    border-bottom: 1px solid $carolina-blue;
                  }
                }
              }
            }
          }
        }

        .modal-footer {
          z-index: 1;
          border-top: 0;
          position: relative;
          padding-bottom: 35px;
          justify-content: space-between !important;
          .dms-modal-btn {
            width: 190px;
            height: 40px;
            font-size: 14px;
            font-weight: bold;
            border-radius: 4px;
            box-shadow: 2px 3px 2px #1F509C;
            &--cancel {
              color: $navy;
              background-color: $ice-blue;
            }
            &--save {
              color: white;
              background-color: $medium-blue;
            }
          }
        }
      }
    }
  }

  &--report {
    .modal-dialog {
      max-width: 700px;
    }

    .modal-header {
      color: $navy;
      font-size: 23px;
      font-weight: 500;
      button.close {
        color: $navy !important;
      }
    }
    .modal-body {
      padding: 50px 20px !important;
      label {
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        color: $carolina-blue;
        text-transform: uppercase;
        &.switch {
          margin-left: 0;
          margin-top: 20px;
          display: block;
        }
      }
      .input-container {
        position: relative;
        i {
          top: 30%;
          right: 10px;
          font-size: 16px;
          position: absolute;
          color: $carolina-blue;
        }
      }
      input {
        border-radius: 4px;
        &::-webkit-input-placeholder { /* Edge */
          color: $carolina-blue;
        }
        
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: $carolina-blue;
        }
        
        &::placeholder {
          color: $carolina-blue;
        }
      }
      .primary {
        width: 120px;
        height: 40px;
        font-size: 14px;
        font-weight: bold;
      }
      .project-container {
        border-radius: 0 0 4px 4px;
        border: 1px solid $lavender-blue;
        border-top: 0;
        .input-outer-container {
          padding: 10px;
        }
        .custom-control-input:checked ~ .custom-control-indicator {
          border-radius: 4px;
        }
        .projects {
          .projects__item {
            height: 100px;
            display: flex;
            padding: 0 20px;
            align-items: center;
            border-top: 1px solid $lavender-blue;
            &__photo {
              width: 72px;
              height: 64px;
              margin: 0 10px;
            }
            &__name {
              color: $navy;
              font-size: 16px;
              font-weight: bold;
            }
            &--checked {
              background-color: $ice-blue;
              border: 1px solid $medium-blue;
            }
          }
        }
      }
    }
  }
}

// Login modal
.modal-header {
  &.no-border {
    border: 0;
  }
}

.modal-footer {
  &.no-border {
    border: 0 !important;
  }
}

.invite-modal{
  &.modal.eoi-modal input[type="text"]{
    max-width: 70% !important;
    margin: 30px auto;
    text-align: center !important;
    font-size: 20px;
    border: 1px solid #CDCDCD !important;
  }
  &.modal .modal-body span {
    margin-top: -25px;
    margin-left: 60px;
  }
  .staff{
    margin-left: 10px !important;
    display: inline-block !important;
  }
}

.error{
  margin-top: -25px;
  margin-left: 90px;
}

.modal {
  &.ams-modal {
    .modal-dialog {
      max-width: 750px;
    }
    .modal-header {
      border: 0;
    }
    .modal-body {
      position: relative;
      .navigation {
        top: 40%;
        position: absolute;
        transform: translateY(-40%);
        &.prev {
          left: 20px;
        }
        &.next {
          right: 20px;
        }
      }
    }
  }
  .modal-header {
    position: relative;
    &.with-border {
      border: 1px solid $main-nav;
    }
    &.without-border {
      border: 0;
    }
    .modal-title {
      font-size: 21px;
      font-weight: 400;
      padding-left: 35px;
    }
    button.close {
      top: 15px;
      right: 15px;
      position: absolute;
    }
  }

  .modal-body {
    padding: 20px 70px;
    &--less-padding {
      padding: 20px 50px;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 0.5;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      opacity: 0.5;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      opacity: 0.5;
    }

    .agent-fields-container {
      display: none;
      &.show {
        display: block;
      }
    }
    
    .btn-container-row {
      display: flex;
      justify-content: space-between;
      .btn {
        width: 40%;
        font-size: 12px;
        position: relative;
        .fa {
          left: 20px;
          font-size: 20px;
        }
      }
    }
    
    .share-buttons {
      display: flex;
      justify-content: space-between;
      a.btn {
        width: 20%;
        .fa {
          left: 0;
          top: 0;
          position: relative;
        }
      }
    }
    .col-6 {
      &.with-border {
        padding-left: 15px;
        padding-right: 15px;
        &:first-child {
          border-right: 1px solid $main-nav;
        }
      }
      
      .signup-container {
        padding: 2.5rem 3.5rem;
        a {
          text-decoration: none;
          &:hover {
            .icon {
              color: darken($color: $main-nav, $amount: 10%);
            }
          }
        } 
        .icon {
          font-size: 100px;
        }
      }
    }
    .icon {
      font-size: 50px;
    }
    span {
      display: block;
    }
    label.mandatory {
      color: red;
    }
  }

  .modal-footer {
    font-size: 15px;
    justify-content: center;
    border-top: 1px solid $fixed;
    p {
      margin-bottom: 0;
    }
  }

  .modal-content {
    background-color: white;
    border: 0;
    border-radius: 0;
  }

  .or-container {
    text-align: center;
    margin: 0;
    margin: 20px 0;
    clear: both;
    .or-hr {
      position: relative;
      top: 17px;
      width: 50%;
      margin: 0 auto;
      height: 0;
      border: 0;
      border-top: 1px solid $fixed;
    }
    .or {
      display: inline-block;
      position: relative;
      padding: 3px;
      background-color: white;
    }
  }

  p {
    &.smaller {
      font-size: 14px;
    }
  }

  a, a:focus {
    color: $main-nav;
  }

  a.btn {
    color: white;
    display: block;
    margin: 20px auto;
    position: relative;
    font-weight: bold;
    cursor: pointer;
    
    
    .fa {
      top: 15px;
      left: 20px;
      font-size: 30px;
      position: absolute;
    }
    
    &:hover {
      color: white;
    }
  }
  
  button.btn {
    color: white;
    padding: 10px;
  }
  
  .btn-facebook {
    position: relative;
    background-color: $facebook;
  }

  .btn-google {
    background-color: $google;
  }
  
  .btn-twitter {
    background-color: $twitter;
  }
  
  .btn-email {
    background-color: $email;
  }
  
  .btn-linkedin {
    background-color: $linkedin;
  }
  
  .btn-pinterest {
    background-color: $pinterest;
  }
  
  .btn-page-link {
    background-color: $copy-link;
  }

  .form-check-input {
    margin-left: 0;
    position: initial;
  }

  .form-group {
    select {
      margin-top: 10px;
    }
    span.flag-icon {
      display: inline-block;
    }
  }

  .form-group.error {
    color: $red;
    input {
      color: $red;
      border-color: $red;
    }
  }

  input, .custom-select {
    padding: 8px;
    background-color: transparent;
    transition: all .3s ease-in-out;
    &:focus {
      border-color: black;
      background-color: transparent;
    }
  }

  input[type="checkbox"] {
    font-weight: bold;
  }

  .terms {
    font-size: 14px;
    text-align: center;
    color: #aeb2b3;
  }

  &.eoi-modal {
    .modal-content {
      .success-icon {
        width: 50px;
        height: 50px;
        display: block;
        margin: 20px auto;
      }
    }
    .modal-header {
      border: none;
      .modal-title {
        padding-left: 0;
        font-weight: 600;
      }
    }

    .modal-body {
      padding: 20px;
      a {
        font-size: 14px;
        font-weight: normal;
        text-decoration: underline;
        color: $medium-blue !important;
      }
    }

    input[type="text"] {
      border: 1px solid $fixed !important;
      margin: 30px auto 5px;
      text-align: center;
      font-size: 25px;
      &:focus {
        border: 1px solid black !important;
      }
      &::placeholder {
        opacity: 0.4;
        color: black;
        font-size: 25px;
        font-weight: bold;
        text-align: center;
      }
    }

    .modal-select input[type="text"] {
      text-align:left;
      max-width: 100%;
      margin: 0px 10px 0px 0px;
      font-size: 14px;
      border: 1px solid #ddd !important;
      &:focus {
        border: 1px solid #ddd !important;
      }
      &::placeholder {
        text-align: left;
        font-size: 14px;
      }
    }
  }
  &.family-successful-modal {
    .modal-header {
      border: none;
    }
    .modal-body {
      p {
        padding: 6rem 0;
      }
    }
  }
}

// Sign Up modal
.signup-modal {
  overflow-y: scroll;

  .btn-register {
    background-color: $cancel-bg;
  }

  .btn-cancel {
    background-color: $google;
  }
}

// Enquiry modal
.enquiry-modal {
  .modal-lg {
    max-width: 100%;
  }
  a {
    color: $link;
    text-decoration: underline;
  }
  .modal-content {
    [class*="col"] {
      padding: 50px;
    }
    .col-lg-4.with-border {
      border-right: 1px solid grey;
    }

    p {
      font-weight: 500;
      margin-bottom: 20px;

      &.mandatory {
        font-size: 14px;
        font-weight: normal;
      }
    }

    form {
      position: relative;

      .close {
        top: 20px;
        right: 10px;
        position: absolute;
        z-index: 10000;
      }

      .required {
        position: relative;
        .fa {
          top: 50%;
          right: 10px;
          font-size: 6px;
          color: #0078c1;
          position: absolute;
          transform: translateY(-50%);
        }
      }
      p {
        span {
          color: #0078c1;
        }
      }

      h3 {
        margin-bottom: 20px;
        font-weight: 900;
        color: #0078c1;
      }
    }
  }
}

// Dashboard Modals

.profile-modal {
  .modal-dialog {
    max-width: 770px;
    .modal-header {
      background-color: $sold;
      .modal-title {
        .icon {
          color: $link;
        }
      }
      .close {
        color: $red;
      }
    }
  }
}

.accept-offer {
  .modal-dialog {
    max-width: 710px;
    .modal-header {
      color: white;
      background-color: green;
    }
    .modal-body {
      padding: 65px;
      background-color: $sold;
      h5 {
        margin-bottom: 20px;
      }
      .btn {
        border-radius: 5px;
        margin: 150px 0;
        width: 250px;
      }
      .btn-primary {
        &.btn-dark {
          min-width: 250px !important;
        }
      }
    }
  }
}

.modal-header.view3d {
  border-bottom: 0;
  justify-content: flex-end;
}

.modal-footer.view3d {
  border-top: 0;
}

@media only screen and (max-width: 768px) {
  .modal-content:not(.default-bs-modal-content) {
    [class*="col"] {
      padding: 25px;
    }
  }
}

@media only screen and (max-width: 640px) {
  .modal {
    .modal-header {
      .modal-title {
        font-size: 16px;
      }
    }
    .modal-body {
      padding: 20px 45px;
      .col-6 {
        .signup-container {
          .icon {
            font-size: 80px;
          }
          a {
            font-size: 12px;
          }
        }
      }
      .btn-container-row {
        .btn {
          width: 45%;
        }
      }
    }
    a.btn {
      width: 100%;
    }
  }
}