.agent-subscription {
  .content-block {
    padding: 0 80px;
    min-height: 400px;
    border-radius: 4px;
    margin-bottom: 50px;
    background-color: white;
    border: 1px solid $sold;
    .subscription-title {
      padding: 20px 0;
      border-bottom: 1px solid $sold;
    }
    .subscription-type {
      margin-top: 50px;
      .type-title {
        font-size: 50px;
        line-height: 1.1;
        color: $dashboard-agent;
        span {
          display: block;
          font-size: 40px;
        }
      }
    }
  } 
  .buy-now {
    border-radius: 5px !important;
    background-color: $navy;
  }
}