.document-signature-container {
  top: 200px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
  height: calc(100% - 200px);

  .carousel {
    height: calc(100vh - 300px);
    margin-top: 100px;

    .carousel-item {
      strong {
        color: black;
      }
    }

    .carousel-indicators li {
      background-color: $greyish-blue;
      &.active {
        border: 0;
        background-color: $mid-sky-blue;
      }
    }

    .primary {
      background-color: $mid-sky-blue !important;
    }
  }

  a {
    bottom: 25px;
    position: absolute;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    color: $mid-sky-blue;
    &:hover {
      text-decoration: none;
    }
    &.back {
      left: 30%;
      z-index: 500;
    }
    &.skip {
      right: 30%;
      z-index: 500;
    }
  }

}

.document-signature-logo {
  max-width: 120px;
  margin-bottom: 30px;
}

.disclaimer {
  font-size: 14px;
  font-weight: bold;
  color: $main-nav;
}

.property-name {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: $main-nav;
}

.step-demo {
  max-width: 100px;
}