.featured-project {
	margin: 2% 0;
	font-size: 14px;
	border-bottom: 1px solid $border-grey;
}

.btn-view {
	width: 95px;
	height: 65px;
	color: $main-nav;
	background: none;
	text-align: center;
	i {
		display: block;
		font-size: 30px;
	}
	span {
		font-size: 14px;
		display: block;
		margin-top: -10px
	}
	&:hover {
		cursor: pointer;
	}
}

.project-availability {

	.project-developer {
		height: 180px;
		line-height: 180px;
		border: 1px solid $main-nav;
		img {
			vertical-align: middle;
		}
	}

	.project-stock {
		color: $main-nav;
		padding-top: 20px;
		text-transform: uppercase;
		p {
			border-bottom: 1px solid $border-grey;
			span {
				font-weight: bold;
				display: inline-block;
				width: 70%;
			}
		}
	}
}

.developers {
	.developer-logo-container {
		display: flex;
		img {
			height: 50px;
			max-width: 100px;
		}
	}
}

.categories {
	margin: 50px 0;
	.category {
		position: relative;
		h4 {
			top: 50%;
			left: 50%;
			color: white;
			font-weight: 400;
			position: absolute;
			text-transform: uppercase;
			transform: translate(-50%, -50%);
		}
		&:hover {
			cursor: pointer;
		}
	}
}

@media only screen and (max-width: 768px) {
	.featured-project {
		border-bottom: 1px solid $border-grey;
		.col-md-5 {
			padding: 20px 30px 0;
		}
	}

	.categories {
		.category {
			h4 {
				font-size: 16px;
			}
		}
	}
	
	.project-availability {
	
		.project-stock {
			p {
				font-size: 11px;
			}
		}
	}

}

@media only screen and (max-width: 640px) {

	.categories {
		.category {
			h4 {
				font-size: 12px;
			}
		}
	}
}