// breadcrumb

.breadcrumbs-container {
  .container-fluid, .container {
    .breadcrumb {
      padding: 0;
      margin: 20px 0;
      padding-left: 20px; 
      background-color: transparent;
    }
  }
}

.breadcrumbs-dashboards {
  list-style: none;
  padding-top: 20px;

  li {
    margin-right: 8px;
    display: inline-block;
  }
  h2 {
    color: $navy;
    display: block;
    font-size: 32px !important;
  }
  a, i {
    font-weight: bold;
    color: $medium-blue;
  }
}

@media only screen and (max-width: 768px) {
  .breadcrumbs-container {
    margin-top: 80px;
    li, li a {
      font-size: smaller;
      text-transform: uppercase;
    }
  }
}