.careers-container {
  display: flex;
  flex-wrap: wrap;
  .career-block {
      flex: 1 33%;
      text-align: center;
      margin-bottom: 2rem;
      color: $main-nav;
      text-decoration: none;
      .career-icon {
        width: 180px;
        height: 180px;
        font-size: 90px;
        line-height: 180px;
        margin: 0 auto 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 3px solid $main-nav;
        transition: transform .3s ease-in-out;
      }
      &:hover {
        .career-icon {
          transform: scale(1.1);
        }
      }
  }
}

.careers {
  ul {
    padding-top: 10px;
    padding-left: 20px;
    li {
      line-height: 2;
    }
  }

  .btn {
    width: 150px;
  }
}

.career {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  border: 3px solid $main-nav;
  .icon {
    font-size: 200px;
  }
}

@media only screen and (max-width: 768px) {
  .careers-container {
    .career-block {
      flex: 1 50%;
      .career-icon {
        width: 120px;
        height: 120px;
        font-size: 60px;
      }
      .career-title {
        font-size: 14px;
      }
    }
  }
}