.navbar-landing {
	justify-content: space-between;
}

.hero {
  height: 700px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
	background-position: center;
  background-image: url(../images/landing-background.jpg);
  clip-path: polygon(0% 0%,100% 0px, 100% 81%, 0% 100%);
	-webkit-clip-path: polygon(0% 0%,100% 0px, 100% 81%, 0% 100%);
	margin-bottom: -198px;
	z-index: 3;

  .content {
    width: 50%;
	  h1 {
	  	color: white;
			padding: 50px 0 0 50px;
	  	font-size: 3.5rem;
	  	text-transform: uppercase;
	  	span {
	  		display: block;
	  		font-size: 4.5rem;
	  	}
	  }
		.form-container {
			height: 300px;
			padding: 20px;			
			margin: 20px auto 0;
			max-width: 550px;
			
			.input-group {
				input {
					border-radius: 8px 0 0 8px;
					border: 2px solid black;
					border-right: 0;
				}
		
				.btn {
					border-radius: 0 8px 8px 0;
					border: 2px solid black;
					border-left: 0;
				}
			}
			
			p {
				color: white;
				font-size: 48px;
				line-height: 1;
				span {
					font-size: 30px;
				}
			}
		}
	}
}

.coming-soon {
	height: 700px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
	background-position: center;
  background-image: url(../images/pins&dots.png);
}

@media only screen and (max-width: 768px) {
	.hero {
		.content {
			h1 {
				font-size: 2.5;
				span {
					font-size: 3rem;
				}
			}
		}
	}
}

@media only screen and (max-width: 640px) {
	.hero {
		height: 500px;
		.content {
			width: 100%;
			h1 {
				font-size: 1.5rem;
				span {
					font-size: 2rem;
				}
			}
			.form-container {
				p {
					font-size: 35px;
					span {
						font-size: 25px;
					}
				}
			}
		}
	}
	
	.coming-soon {
		height: 450px;
	}
}
