$main-nav:              #898989;
$nav-dropdown:          #ECEBEB;
$tabs-grey:             #D8D8D8;
$new-ui-border:         #DAD9D9;
$fixed:                 #CDCDCD;
$eoi:                   #4A4A4A;
$height:                300;

$navy:                  #071d49;
$mild-navy:             #103a71;
$red:                   #e70f47;
$error-red:             #d34836;
$chilli-red:            #d34836;
$expired-red:           #f8e1de;
$yellow:                #ffd800;
$dark-grey:             #8b8d8f;
$grey:                  #dfe1e3;
$lt-grey:               #575a5d;
$text-grey:             #73736e;
$lighter-grey:          #d0d0c6;
$border-grey:           #7f7f83;
$dashboard-bg:          #e5e8ef;
$logo-divider:          #eaeaea;
$nav-link-inactive:     #191f28;
$nav-link-active:       #0078c1;
$tabbed-nav-inactive:   #9e9c9c;
$tabbed-nav-active:     #676565;
$button-grey:           #cfcfcf;
$button-navy:           #011024;
$greyish-brown:         #4a4a4a;
$greyish:               #b6b6b6;
$warm-grey:             $main-nav;
$availability-grey:     #adada3;
$border-blue:           #326dcf;
$medium-blue:           #2a6ebb;
$mild-blue:             #e5f3fc;
$ice-blue:              #e5f3fc;
$lavender-blue:         #bcc6d9;
$carolina-blue:         #91a1bf;
$greyish-blue:          #e5e8ef;
$post-it-blue:          #3891e0;
$mid-sky-blue:          #3f9fed;
$sky-blue:              #71BBF2;
$ice-blue:              #e5f3fc;
$cornflower:            #687DA5;
$btn-primary-blue:      #2a6ebb;
$badge-green:           #009645;
$lime-green:            #4cd964;
$success-alert:         #00C039;
$lime-green-light:      #e7faea;

$badge-red:             #d42e12;
$badge-purple:          #9900ab;
$badge-orange:          #fa9e0d;
$badge-blue:            #005ec4;
$badge-brown:           #734538;
$badge-teal:            #0099aa;
$badge-lrt:             #748477;

$disabled-bg:           #5e5e5e;
$cancel-bg:             #818181;
$link:                  #3291cb;
$add-property:          #333333;
$dashboard-nav-active:  #959fa9;
$dash-active-border:    #51667f;
$dashboard-content:     #f6f7fa;
$btn-view:              #666666;
$dashboard-card-header: #e5e5e5;
$dashboard-agent:       #024b8a;
$drone-view-dd:         #e0e1e4;
$dd-background:         #f0f0f0;
$light-blue:            #0077c8;

// Custom tabs colors-list
$ew:                  #41923e;
$ns:                  #ca392d;
$ne:                  #8429ac;
$cc:                  #f19e39;
$dt:                  #2155a0;
$te:                  #6d473b;
$bp:                  #778378;
$sk:                  #778379;
$pg:                  #778380;
$btn-bg:              #d7d2d2;
$btn-schools:         #333131;

// Custom button colors
$ph:                  #898989;
$bed5:                #c088af;
$bed4:                #33127a;
$bed3:                #0a63c1;
$bed2:                #017842;
$bed1:                #a97b40;
$sold:                #cccccc;
$draft:               #ffe6e6;
$reserved:            #cc6600;
$detached:            #2f5c5c;
$semi-detached:       #a9c6af;
$stackview-selected:  #F5F500;
$corner-terrace:      #be523f;
$interlaced-terrace:  #f9c05c;
$sold-red:            #D34836;
$booked:              #FF9933;

$bedrooms:            #cbcbcb;
$facebook:            #3b5998;
$submit:              #8996a0;
$google:              #df4a32;
$twitter:             #1da1f2;
$email:               #d4af37;
$linkedin:            #0084bf;
$pinterest:           #bd081c;
$copy-link:           #be9b7b;

$stackview-retail:    #b7caca;
$stackview-br1:       #fff7c9;
$stackview-br2:       #ffd2d4;
$stackview-br3:       #e6efb9;
$stackview-br4:       #bbd5f2;
$stackview-br5:       #be865c;
$stackview-ph:        #9b91ab;
$stackview-bba:       #ffc354;
$stackview-abd:       #91e038;

// Dashboard EOI buttons
$db-darker-navy:      #0d253f;
$db-dark-navy:        #212c6a;
$db-light-navy:       #1d4b7f;
$db-blue:             #254bd0;
$db-lt-blue:          #1073e8;
$db-cyan:             #00a9e2;
$db-lt-gray:          $tabs-grey;
