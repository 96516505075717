.my-folio {
  margin: 50px 0 100px;
  .card-container {
    color: black;
    text-decoration: none;
    .card {
      transition: transform .3s ease-in-out;
      &:hover {
        transform: scale(1.05);
      }
      .card-header {
        font-weight: bold;
        text-align: center;
      }
      .card-block {
        padding: 80px;
        img {
          width: 100px;
          height: 100px;
          margin: 0 auto;
          display: block;
        }
      }
      &.disabled {
        opacity: 0.3;
      }
    }
  }

  p {
    padding: 20px;
  }
}

// My profile

.profile {
  margin-top: 50px;

  .profile-container {
    padding-left: 0;
    padding-right: 0;
    .card-header {
      .icon, .fa {
        padding-right: 10px;
      }
      .btn {
        padding: 0;
        border: 0;
        margin: 0 10px;
        background-color: transparent;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .card-block {
      .icon-information-circular-button-interface-symbol {
          color: darkturquoise;
        }
      .profile-image-container {
        padding: 40px;
        text-align: center;
        border: 1px solid $sold;
        p, .icon, a {
          color: $sold;
        }
        .icon {
          font-size: 100px;
        }
      }
      .profile-image-uploaded {
        display: none;
        position: relative;
        .icon {
          top: 20px;
          right: 20px;
          color: white;
          position: absolute;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .text-center {
        margin-top: 20px;
      }

      hr {
        margin-top: 100px;
      }

      .btn {
        width: 100%;
      }

      .text-container {
        text-align: center;
        box-sizing: border-box;
        border: 1px solid $sold;
        .text-container-header {
          background: black;
          color: white;

          p {
            padding: 10px 30px;
          }
        }
        .text-container-body {
          min-height: 200px;

          .align-middle {
            padding: 40px;
          }
          p {
            span {
              font-size: 40px;
              font-weight: bold;
            }
          }
        }
      }
      .document-upload-container {
        display: flex;
        justify-content: space-between;
        .document-upload {
          width: 230px;
          height: 230px;
          margin-bottom: 30px;
          .profile-image-container {
            box-sizing: border-box;
            min-width: 150px;
            min-height: 150px;
            padding: 20px;
            &.add-more {
              height: 230px;
              padding: 60px;
              .icon {
                font-size: 80px;
              }
            }
            .icon {
              font-size: 50px;
            }
          }
        }
      }
    }
  }
}

.all-projects {
  .btn-secondary {
    margin: 20px 0;
    .btn {
      border: 1px solid $sold;
    }
  }
}

.all-unit-types {
  p {
    padding-top: 3px;
  }
  
  table {
    margin-top: 20px;
  }
}

.company-generic-info {
  display: flex;
  .company-logo {
    flex: 1 160px;
    height: 160px;
    border: 1px solid $greyish;
  }
  .company-info {
    padding-left: 20px;
    box-sizing: border-box;
    flex: 1 calc(100% - 160px);
  }
}

.company-profile-text {
  column-count: 2;
  column-gap: 80px;
}

.folio-item {
  figure {
    min-height: 320px;
    img {
      width: 100%;
      height: auto;
    }
    figcaption {
      padding: 10px 0;
      a {
        font-size: 21px;
        color: $warm-grey;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}

.review-container {
  display: flex;
  padding: 30px;
  flex-wrap: wrap;
  border: 1px solid $greyish;
  margin-bottom: 20px;
  .review-rating {
    flex: 1 120px;
    figure {
      img {
        height: 120px;
        width: 120px;
      }
      figcaption {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        margin-top: 10px;
      }
    }
    .star-rating {
      ul {
        width: 100px;
        margin: 0 auto;
      }
    }
  }
  .review-content {
    position: relative;
    padding-left: 35px;
    flex: 1 calc(100% - 120px);
    a {
      bottom: 0;
      font-size: 16px;
      position: absolute;
    }
  }
}

.star-rating {
  ul {
    display: flex;
    list-style: none;
    padding-left: 0;
    li {
      margin-right: 5px;
      color: $border-blue;
    }
  }
}

@media only screen and (max-width: 768px) {
  .company-profile-text {
    column-count: 1;
  }

  .review-container {
    .review-content {
      a {
        position: relative;
        margin-top: 10px;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .review-container {
    .review-content {
      flex: 1 100%;
      padding-left: 0;
      margin-top: 30px;
    }
  }
}
