@import "../partials/variables";

.all-developers-input-container {
  color: $navy;
  height: auto;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
  background: white;
  border: 1px solid $carolina-blue;

  &__inner {
    border: 1px solid $carolina-blue;
    box-shadow: 0px 3px 6px #000029;
    
    &.active {
      border: 2px solid $medium-blue;
      background-color: $ice-blue;
    }
  }

  span {
    padding: 15px;
    font-size: 14px;
    display: inline-block;
  }

  .fa-angle-down {
    top: 25px;
    right: 10px;
    font-size: 30px;
    position: absolute;
    transform: translateY(-50%);
  }

  &__dropdown__container {
    display: none;
    .form-control {
      padding: 10px;
      color: $carolina-blue;
    }
    &.active {
      display: block;
    }
  }
  &__dropdown__input__container {
    position: relative;
    .form-control {
      padding-left: 40px;
    }
    .icon {
      top: 50%;
      left: 10px;
      position: absolute;
      color: $carolina-blue;
      transform: translateY(-50%);
    }
  }
  .developer-selection {
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    color: $carolina-blue;
    
    &:not(:last-child) {
      border-bottom: 1px solid $carolina-blue;
    }
  }
}