.newsroom {
	font-size: 14px;
	.newsroom-nav {
		width: 60%;
		ul {
			padding-left: 0;
			list-style-type: none;
			li {
				padding: 10px 0;
				border-top: 1px solid $main-nav;
				a {
					display: block;
					color: $main-nav;
					text-decoration: none;
					&.selected {
						font-weight: bold;
					}
				}
			}
		}
	}
	small {
		display: block;
		margin-bottom: 20px;
	}

	.btn {
		width: 155px;
	}

	.prev-next {
		ul {
			li {
				margin-right: 20px;
				a {
					color: $main-nav;
					text-decoration: none;
				}
			}
		}
	}

	.pagination {
		li {
			a {
				color: $main-nav;
				text-decoration: none;
				&.active {
					color: black;
				}
			}
			&:before {
				display: inline-block;
		    padding-right: .5rem;
		    padding-left: .5rem;
		    content: "/";
			}
			&:first-child:before {
				content: "";
			}
		}
	}

	.select-container {
		display: none;
		.nice-select {
			width: 100%;
			border-radius: 0;
			border: 1px solid $main-nav;
			.list {
				width: 100%;
				border-radius: 0;
				border: 1px solid $main-nav;
				li {
					border-bottom: 1px solid $main-nav;
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.newsroom {
		.newsroom-nav {
			display: none;
		}
		.select-container {
			display: block;
		}
	}
}