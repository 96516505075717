.step-block {
  margin-bottom: 80px;
  &:hover {
    cursor: default;
  }
  .steps {
    list-style: none;
    margin: 0;
    padding: 0;    
    font-size: 16px;   
    &:after{
      content: "";
      display: block;
      clear: both;
    } 
    li {            
      align-items: bottom;
      line-height: 16px;
      line-height: 1.7;
      padding-top: 15px;
      position: relative;
      float: left;
      width: 20%;
      &:first-child:after{
        display: none;
      }
      &:after{
        position: absolute;
        left: -50%;
        height: 7px;
        width: 100%;
        background: white;
        border-top: 1px solid $fixed;
        border-bottom: 1px solid $fixed;
        top: 25px;
        display: block;
        content: "";
      }
      &.step-active {
        &:after{
          background: $eoi;
        }
        .step-link {
          .step-count {
            background: $eoi;
            color: #fff;
          }
          .step-name {
            color: #000;
          }
        }
      }
      &.step-over {
        &:after{
          background: $eoi;
          border: 0;
        }
        .step-link {
          .step-count {
            border: 0;
            color: white;
            background: $eoi;
          }
        }
      }
      &.step-over{
        border-color: $eoi;
      }
      .step-link {
        position: relative;
        display: block;
        z-index: 7;
        .step-count {
          display: block;
          position: absolute;
          top: -5px;
          left: 50%;
          margin-left: -15px;          
          background: white;
          border-radius: 50%;
          width: 35px;
          height: 35px;
          line-height: 35px;
          text-align: center;
          color: $eoi;
          border: 1px solid $fixed;
        }
        .step-name {
          display: block;
          position: absolute;
          color: grey;
          text-align: center;
          top: 35px;
          left: 50%;
          width: 120px;
          margin-left: -60px;
          font-size: 14px;
        }
      }      
    }
  }
  &.step-blocks-launch {
    .steps {
      li {
        width: calc(100% / 6);
      }
    }
  }
  &.step-blocks-launch-selection {
    .steps {
      li {
        width: calc(100% / 7);
      }
    }
  }
}

.dms-nav {
  top: 0;
  left: 0;
  bottom: 0;
  width: 80px;
  height: 100vh;
  position: fixed;
  text-align: center;
  background-color: $navy;
  .logo-container {
    padding: 10px 0;
  }
  ul {
    max-height: calc(100vh - 80px);
    overflow-y: scroll;

    li {
      width: 100%;
      box-sizing: border-box;
      &:hover, &.active {
        background-color: $mild-navy;
      }
      a {
        color: white;
        padding: 15px 0;
        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
        display: inline-block;
      }
      .icon {
        color: white;
        display: block;
        margin-bottom: -5px;
      }
    }
  }
}

a.project-page {
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  i {
    font-size: 20px;
    font-weight: bold;
    margin-right: 20px;
  }
  &.project-page--right-icon {
    i {
      margin-right: 0;
      margin-left: 20px;
    }
  }
}

.dms-projects-nav {
  border-bottom: 1px solid $lavender-blue;
  .list-unstyled {
    display: flex;
    padding-bottom: 0;
    margin-bottom: 0;
    li {
      font-size: 20px;
      font-weight: bold;
      padding-right: 30px;
      padding-bottom: 10px;
      a {
        color: $navy;
        text-decoration: none;
      }
      &.selected {
        border-bottom: 2px solid $medium-blue;
        a {
          color: $medium-blue;
        }
      }
    }
  }
}

.unit-information {
  ul {
    display: flex;
    margin-bottom: 0;
    li {
      margin: 0 10px;
      a {
        font-size: 20px;
        font-weight: bold;
        font-family: Roboto;
        color: $carolina-blue;
        padding-bottom: 10px;
        &.selected {
          color: $medium-blue;
          border-bottom: 2px solid $medium-blue;
        }
      }
    }
  }
}

.payment-nav {
  a {
    font-size: 20px;
    padding: 20px 20px 23px 20px;
    font-weight: bold;
    color: $carolina-blue !important;
    &.active {
      color: $medium-blue !important;
      border-bottom: 1px solid $medium-blue;
    }
  }
}

.lms-notifications-nav {
  display: flex;
  padding: 15px 0;
  list-style: none;

  li {
    margin-left: 20px;

    a {
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 10px;
      color: $carolina-blue;
      text-decoration: none;
      &.selected {
        color: $medium-blue;
        border-bottom: 2px solid $medium-blue;
      }
    }
  }
}