.table-striped {
  overflow-x: scroll;
}

.project-view {
  .carousel-inner {
    height: calc(100vh - 220px);
    &.unit-view-inner {
      height: 700px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
    }
  }

  .detailed-view {
    position: relative;

    .soldout {
      top: 0;
      left: 0;
      width: 70px;
      position: absolute;
      z-index: 1;
    }
    
    .carousel {
      height: 600px;
      .carousel-inner {
        height: 100%;
        .carousel-item {
          height: 100%;
        }
      }
    }
  }
}

.project-view-info {
  ul {
    li {
      font-size: 14px;
      margin-left: 5%;
      i {
        padding-right: 10px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .btn {
    text-align: left;
    position: relative;
    i {
      right: 10px;
      font-size: 20px;
      position: absolute
    }
    &.btn-primary.disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
  i {
    &:hover {
      cursor: pointer;
    }
  }
}

.units-btn-group {
  display: flex;
  flex-wrap: wrap;
  button {
    background: none;
    color: $main-nav;
    margin-bottom: 5px;
    margin-right: 1%;
    border-radius: 0;
    font-size: 13px;
    height: 30px;
    width: 24%;
    padding: 1px 1px;
  }
}

.in-page-nav {
  .container {
    nav {
      border-top: 1px solid $fixed;
      border-bottom: 1px solid $fixed;
    }
  }
  .list-unstyled {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    padding: 10px 0;
    li {
      width: 150px;
      text-align: center;
      position: relative;
      &.last {
        margin-left: auto;
      }
      a {
        font-size: 14px;
        color: $main-nav;
      }
      &.disabled {
        a {
          opacity: 0.5;
          cursor: not-allowed;
          text-decoration: none;
        }
      }
      &:last-child {
        &:after {
          content: "";
        }
      }
    }
  }
  &.unit {
    .list-unstyled {
      padding: 10px 5%;
      li {
        a {
          .icon {
            font-size: 20px;
            padding-right: 5px;
          }
        }
      }
    }
  }
}

.project-video {
  .video-container {
    font-size: 60px;
    height: 700px;
    display: flex;
    color: white;
    position: relative;
    background: #4A4A4A;
    align-items: center;
    justify-content: center;
  }
}

// Facilities Styles

.facilities {
  a.d-sm-none {
    display: inline-block;
    margin:  20px;  
    &:hover {
      cursor: pointer;
    }
  }
}

.flex-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .column {
    padding: 20px;
    box-sizing: border-box; 
    flex-basis: 25%;
    max-width: 25%;
    border-right: 1px solid $fixed;
    &:last-child {
      border-right: none;
    }
    p {
      margin-bottom: 6px;
      .icon {
        font-size: 18px;
      }
    }
  }
}

// Location map
.location-map {
  .map-container {
    height: 600px;
  }
}

.developer-info {
  text-align: justify;
  .logo-container {
    height: 150px;
    padding: 20px 0;
    line-height: 150px;
    img {
      vertical-align: middle;
    }
  }
}

.box-border {
  padding: 20px;
  border-right: 1px solid $fixed;
  &:last-child {
    border-right: none;
  }
  p {
    margin-bottom: 10px;
    i {
      font-size: 20px;
    }
  }
}

// Storey View 

.drone-views {
  margin: 50px 0;
  .drone-custom-tab {
    width: 15%;
    height: auto;
    display: inline-block;
    
    .drone-views-wrapper {
      width: 100%;
      button {
        width: 100%;
        border: none;
        outline: none;
        padding: 25px;
        color: white;
        font-size: 20px;
        text-align: center;
        margin-bottom: 2px;
        cursor: pointer;
        transition: 0.3s;
        font-size: 17px;
        z-index: 2000;
        background: $navy;
        position: relative;
      }
      
      .dropdown-container {
        width: 260px;
        height: 500px;
        display: none;
        z-index: 10000;
        overflow-y: scroll;
        position: absolute;
        background-color: $drone-view-dd;
        
        &.show {
          display: block;
        }
        ul {
          padding: 30px 0;
          list-style-type: none;
          li {
            padding: 5px 0;
            a {
              color: black;
              display: block;
              padding: 0 30px;
            }
            img {
              width: 80px;
              text-align: center;
              margin-right: 10px;
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .tabcontent {
    width: 85%;
    height: auto;
    float: right;
    padding: 0 20px;
    box-sizing: border-box;

    .level {
      img {
        max-height: 600px;
      }
    }
  }
}


body.is-fullscreen {
  overflow: hidden;
}

.carousel-item.active.fullscreen-img.fullscreen-img-open {
    height: 100% !important;
}

.fullscreen-img {
  &.fullscreen-img-open {
    & > .fullscreen-img-title {
      left: 50%;
      bottom: 15%;
      color: white;
      z-index: 1001;
      font-size: 20px;
      display: block;
      position: fixed;
      transform: translateX(-50%);
    }
    & > .fullscreen-img-navigation, .fa, .zoom-navigation {
      left: 0;
      top: 50%;
      width: 200px;
      padding: 20px;
      z-index: 1001;
      display: block;
      position: fixed;
      transform: translateY(-50%);
      button {
        width: 100%;
        padding: 20px;
        border: 1px solid black;
        background: $drone-view-dd;
        border-bottom: 2px solid black;
        i {
          color: white;
        }
      }
      ul {
        padding: 0;
        width: 100%;
        list-style: none;
        max-height: 200px;
        overflow-y: scroll;
        li {
          color: black;
          padding: 20px;
          text-align: left;
          background: rgba(255,255,255,0.2);
          a {
            color: white;
            display: block;
            text-decoration: none;
          }
        }
      }
      &.gallery {
        top: 0;
        left: 50%;
        bottom: 5%;
        width: auto;
        position: absolute;
        transform: translateX(-50%);
        img {
          top: 100%;
          width: 100px;
          background: none;
          position: relative;
          bottom: 0 !important;
          display: inline-block;
          height: 100px !important;
          
          &.selected {
            border: 1px solid $red;
          }
          
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .fa {
      &:hover {
        cursor: pointer;
      }
      color: white;
      
      &.fa-arrow-right {
        left: 96%;
      }
    }
    & > .zoom-navigation {
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100px;
      background: black;
      .fa {
        top: 75px;
        font-size: 20px;
        &.fa-search-plus {
          left: 82%;
        }
        &.fa-search-minus {
          left: 85%;
        }
        &.fa-play-circle {
          left: 88%;
        }
        &.fa-expand {
          left: 91%;
        }
      }
    }
    & > a.close-fullscreen-img {
      top: 5px;
      right: 20px;
      color: white;
      z-index: 1001;
      display: block;
      position: fixed;
      font-size: 30px;
      text-decoration: none;
    }
    img {
      width: 100%;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;
      max-width: 100%;
      object-fit: contain !important;
      height: 100% !important;
      max-height: 100% !important;
      background: darkgrey;
    }
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    background: rgba(255, 255, 255, 0.5);
    width: 100% !important;
  }
  a.close-fullscreen-img, .fullscreen-img-navigation, .fullscreen-img-title {
    display: none;
  }
}

.unit-types {
  table {
    th {
      span {
        font-size: 15px;
      }
    }
  }

  .btn {
    float: right;
  }
  
  .btn-primary {
    width: 200px;
    padding: 10px;
    position: relative;
    .icon {
      position: absolute;
      left: 15px;
    }
  }
}

.developer-info {
  margin: 80px 0;
  img {
    height: auto;
    max-width: 200px;
  }
  .btn-primary {
    min-width: 200px;
  }
}

.project-desc {
  font-size: 12px;
}

// Prev and next Project Navigation 

.prev-next-project {
  padding: 20px 0;
  margin: 50px 0;
  border-top: 1px solid $border-grey;
  border-bottom: 1px solid $border-grey;
  a {
    display: flex;
    color: $main-nav;
    align-items: center;
    text-decoration: none;
    i {
      transition: all .3s ease-in;    
    }
    &:hover i {
      color: $navy;
    }
    span {
      margin-left: 20px;
      small {
        display: block;
      }
    }
    &.next {
      justify-content: flex-end;
      span {
        padding-right: 20px;
      }
    }
  }
}

.container-tours {
  width: 100%;
}

.project-tours-container {

  &--variation {
    height: 100vh !important;
    .tour {
      width: 100%;
      height: 100%;
      border: 1px solid black;
      img {
        width: 100%;
        height: 100%;
        //object-fit: cover;
      }
    }
  }

  &--two-children {
    display: flex;
    height: 600px;
    justify-content: space-between;
    .tour {
      width: calc(50% - 5px);
    }
  }

  &--one-child {
    height: 600px;
    display: flex;
    .tour {
      width: 100%;
      height: 100%;
    }
  }

  display: grid;
  grid-gap: 10px;
  height: 600px;
  grid-template-rows: repeat(2, 1fr);

  .tour-1 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .tour-2 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 4;
  }

  .tour-3 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
  }
}

@media only screen and (max-width: 1024px) {
  .project-view {
    .carousel-inner {
      height: 300px;
    }
  }

  .project-view-info {
    font-size: 14px;
    ul {
      li {
        span {
          display: none;
        }
        i {
          font-size: 20px;
          padding-right: 0;
          margin-right: 20px;
        }
      }
    }
  }

  .in-page-nav {
    .list-unstyled {
      li {
        font-size: 14px;
        span {
          display: none;
        }
        i {
          font-size: 20px;
        }
        &:after {
          content: "";
        }
      }
    }
  }

  .project-video {
    .video-container {
      height: 400px;
    }
  }

  .unit-types {
    table {
      font-size: 16px;
    }
  }

  .location-map {
    .map-container {
      height: 400px;
    }
  }
}

@media only screen and (max-width: 780px) {
  .flex-container {
    .column {
      flex: 1 calc(100% / 2);
    }
  }
}

@media only screen and (max-width: 768px) {

  .project-view-info {
    ul {
      li {
        i {
          font-size: 15px;
          margin-right: 10px;
        }
      }
    }
  }
  
  .fullscreen-img.fullscreen-img-open > .fullscreen-img-navigation {
    width: 120px;
  }
  
  .fullscreen-img.fullscreen-img-open > .fullscreen-img-navigation button span {
    display: none;
  }
  
  .fullscreen-img.fullscreen-img-open img.browser-specific {
    height: 100vh !important;
    width: 100vw;
  }
  
  .fullscreen-img.fullscreen-img-open > .zoom-navigation .fa {
    font-size: 15px;
  }
  
  .fullscreen-img.fullscreen-img-open > .zoom-navigation .fa.fa-search-plus {
    left: 50%;
  }
  
  .fullscreen-img.fullscreen-img-open > .zoom-navigation .fa.fa-search-minus {
    left: 60%;
  }
  
  .fullscreen-img.fullscreen-img-open > .zoom-navigation .fa.fa-play-circle {
    left: 70%;
  }
  
  .fullscreen-img.fullscreen-img-open > .zoom-navigation .fa.fa-expand {
    left: 80%;
  }
  
  .fullscreen-img.fullscreen-img-open .fa.fa-arrow-right {
    left: 93%;
  }
  
  .fullscreen-img.fullscreen-img-open > .fullscreen-img-navigation.gallery img {
    width: 40px;
  }

  .overview {
    font-size: 14px;
  }

  .project-video {
    .video-container {
      height: 300px;
    }
  }

  .unit-types {
    table {
      font-size: 14px;
    }
  }

  .location-map {
    .map-container {
      height: 250px;
    }
  }

  .project-view {
    .detailed-view {
      .carousel {
        height: 350px;
      }
    } 
  }
}

@media only screen and (max-width: 640px) {
  
  .fullscreen-img.fullscreen-img-open > .zoom-navigation .fa.fa-search-plus {
    left: 45%;
  }
  
  .fullscreen-img.fullscreen-img-open > .zoom-navigation .fa.fa-search-minus {
    left: 55%;
  }
  
  .fullscreen-img.fullscreen-img-open > .zoom-navigation .fa.fa-play-circle {
    left: 65%;
  }
  
  .fullscreen-img.fullscreen-img-open > .zoom-navigation .fa.fa-expand {
    left: 75%;
  }
  
  .fullscreen-img.fullscreen-img-open .fa.fa-arrow-right {
    left: 85%;
  }

  .flex-container {
    .column {
      flex: 100%;
      max-width: 100%;
    }
  }

  .project-view-info {
    font-size: 14px;
    ul {
      li {
        margin-left: 0;
      }
    }
  }

  .list-inline-item:not(:last-child) {
    margin-right: 0;
  }
    
}
