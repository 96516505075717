.carousel {
  position: relative;
  .appstore, .googleplay {
    z-index: 100;
    position: absolute;
    right: 20px;
    &:hover {
      cursor: pointer;
    }
  }

  .appstore {
    top: 20px;
    width: 105px;
  }

  .googleplay {
    top: 60px;
    right: 12px;
    width: 120px;
  }

  .fullscreen {
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    z-index: 100;
    position: absolute;
  }

  .carousel-indicators {
    justify-content: center;

    li {
      height: 15px;
      max-width: 15px;
      border-radius: 50%;
      background-color: $main-nav;
      &.active {
        background-color: white;
        border: 1px solid $main-nav;
      }
    }
  }

  .carousel-control-prev, 
  .carousel-control-next {
    width: 40px;
    opacity: 1;
  }

  .carousel-control-prev-icon, .carousel-control-next-icon {
    width: 40px;
    height: 40px;
    display: block;
    background-image: none;
    background-color: $main-nav;

    .fa {
      font-size: 40px;
    }
  }
}

.carousel-item-inner {
  height: auto;
  max-width: 85%;
  margin: 0 auto;
  display: flex;
  .carousel-img-inner {
    width: calc(100% / 3);
    position: relative;

    h3 {
      top: 50%;
      left: 50%;
      color: white;
      font-weight: 400;
      position: absolute;
      text-transform: uppercase;
      transform: translate(-50%, -50%);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
  &.testimonials {
    max-width: 80%;
    .profile-container {
      text-align: center;
      font-size: 14px;
      p {
        margin-bottom: 0;
      }
      img {
        width: 200px;
        height: 200px;
      }
    }
    .testimonial-message {
      padding: 30px;
      color: $main-nav;
    }
  }
}

.detailed-view {
  .fa-angle-right:before, 
  .fa-angle-left:before {
    z-index: 100 !important;
    color: white !important;
  }
}

.main-content {
  .home-carousel {
    position: relative;
    
    .carousel-item {
      height: 400px;
      
      .carousel-caption {
        top: 30%;
        bottom: 20px;
        left: 8%;
        text-align: left;
      }

      .heading-container {
        max-width: 200px;
        text-align: center;
        border-bottom: 4px solid white;
        h1 {
          font-size: 60px;
          text-transform: uppercase;
          span {
            font-size: 70px;
            margin-top: -10px;
            display: block;
          }
        }
      }
    }
  }
}

// Owl carousels Styles

.owl-carousel {
  .owl-nav {
    .owl-prev, .owl-next {
      top: 50%;
      width: 40px;
      height: 40px;
      color: white;
      position: absolute;
      background-color: $main-nav;
      transform: translateY(-50%);
      i {
        top: 50%;
        left: 50%;
        font-size: 40px;
        position: absolute;
        transform: translate(-50%, -50%);
      }
    }
    .owl-prev {
      left: 0;
    }
    .owl-next {
      right: 0;
    }
  }
} 

@media only screen and (max-width: 1024px) {
  .main-content {
    .home-carousel {
      .carousel-item {
        height: 300px;

        .heading-container {
          h1 {
            font-size: 40px;
            text-transform: uppercase;
            span {
              font-size: 50px;
            }
          }
        }
      }
    }
  }
  
  .site-map-carousel, .facilities-carousel {
    .carousel-item {
      height: 600px;
    }
  }
}

@media only screen and (max-width: 768px) {

  .main-content {
    margin-top: 80px;
    .carousel {
      
      .appstore {
        width: 80px;
      }
      .googleplay {
        top: 50px;
        width: 95px;
      }
    }
  }
  
  .site-map-carousel, .facilities-carousel {
    .carousel-item {
      height: 400px;
    }
  }
}


@media only screen and (max-width: 640px) {

  #home-carousel {
    .carousel-control-prev, 
    .carousel-control-next {
      display: none !important;
    }
  }
  
  .site-map-carousel, .facilities-carousel {
    .carousel-item {
      height: 300px;
    }
  }
}
