.documents-sign-container {
  width: 100%;
  height: 100vh;
  position: relative;

  header {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 4px 0 rgba(182, 182, 182, 0.38);

    a, span {
      font-size: 14px;
      font-weight: bold;
      color: $main-nav;
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      
      .fa {
        font-size: 25px;
        font-weight: bold;
        color: $medium-blue;
        margin-right: 5px;
      }
    }
  }

  .primary {
    right: 20px;
    bottom: 20px;
    width: 120px;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    position: absolute;
  }

  .sign-content {
    padding: 20px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: $main-nav;

  }

}

.sign__title {
  font-size: 20px;
  color: #6f6f6f;
  font-weight: bold;
}


.sign__status__container {
  width: 100%;
  height: 50px;
  display: flex;
  padding: 0 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 20px;
  border: solid 1px #f4f3f3;
  justify-content: space-between;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);

  .sign__status__user {
    display: flex;
    align-items: center;
    .sign__status__user__avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 10px;
      background-color: $medium-blue;
    }
  }

  .sign__status__user {
    color: $eoi;
    font-size: 14px;

  }

  .sign__status {
    font-size: 14px;
    font-weight: bold;
    text-align: right;

    &--completed {
      color: $lime-green;
    }

    &--progress {
      color: $eoi;
    }

  }

}

