.project-information {
	display: flex;
	
	&__developer {
		display: flex;
		width: 180px;
		flex-direction: column;

		&__logo {
			width: 180px;
			height: 180px;

			img {
				width: 100%;
        height: 100%;
        border: 1px solid $main-nav;
			}
		} 

		&__stock {
			padding: 10px;
			margin-top: 10px;
			
			&__info {
				display: flex;
				margin: 6px 0;
				align-items: center;
				align-content: center;
				justify-content: space-between;

				&:not(:last-child) {
					border-bottom: 1px solid;
				}

				&--text {
					font-weight: bold;
					display: inline-block;
				}

				&--number {
					display: inline-block;
					text-align: right;
				}


			}


		}

		&__developedby {
			margin-top: 20px;

			span {
				font-weight: bold;
				margin-bottom: 10px;
				display: inline-block;
			}

		}

	}

	&__textual {
		width: calc(100% - 200px);
		margin-left: 20px;

		&__title {
			display: flex;
			padding: 10px;
			align-items: center;

			&__project-name {
				width: 30%;
			}

			&__project-share {
				width: 70%;
				display: flex;
				justify-content: flex-end;

				.share {
					width: 50%;
					margin-left: auto;
				}

				.viewing-users {
					width: 20%;
					display: flex;
					justify-content: flex-end;
				}
			}
		}

		&__type {
			display: flex;
			padding: 15px;
			align-items: center;
			justify-content: space-between;

			&__details {
        width: 20%;
			}
			
			&__buttons {
				width: 80%;
				display: flex;
				justify-content: flex-end;
				&__btn__container {
					width: 25%;
					margin-left: 20px;
				}
			}
      
		}


		&__more {

			&__completion {
				padding-top: 20px;

				&__row {
					display: flex;
					margin-bottom: 2rem;

					&__element {
						width: 25%;

						&__link {
							color: #0077c8;
							text-decoration: none;
						}	

						&__title, &__data {
							display: block;
						}

						&__title {
							font-weight: bold;
							height: 55px;
						}
					}
				}

			}

			&__dev-info {
				display: flex;

				&__units {
					width: 40%;
					&__title {
						font-weight: bold;
						height: 40px;
					}
					&__container {
						display: flex;
						margin-right: 10px;
						flex-wrap: wrap;
						.unit {
							width: 30px;
						}
					}
				}

				&__logo {
					width: 55%;
					margin-left: 5%;
					span {
						display: block;
						font-weight: bold;
						height: 40px;
					}
					img {
						width: 100%;
					}
				}

			}


		}

	}

}

.custom-bg-color {
	background-color: lightgrey; 
	background-image: linear-gradient(to right, lightgrey , white);
}

@media screen and (max-width: 1200px) {
  .project-information__textual__more {
		flex-direction: column;
	}

	.project-information__textual__more__completion, 
	.project-information__textual__more__dev-info {
		width: 100%;
	}

}

@media screen and (max-width: 990px) {
	.project-information__textual__title__project-share .share {
		width: 50%;
	}

	.project-information__textual__title__project-share .viewing-users {
		width: auto;
		padding-top: 5px;
		align-content: center;
	}

	.project-information__textual__type__details {
    width: 30%;
	}

	.project-information__textual__type__buttons {
		width: 70%;
		flex-direction: column;
	}

	.project-information__textual__type__buttons__btn__container {
		width: 85%;
		margin-bottom: 10px;
	}

}

@media screen and (max-width: 768px) {

	.project-information {
		flex-direction: column;
	}

	.project-information__developer {
		width: 100%;
		align-items: center;
		flex-direction: row;
		margin-bottom: 20px;
		justify-content: space-between;
	}

	.project-information__textual {
    width: 100%;
    margin-left: 0;
	}

	.project-information__developer__logo {
		width: 100px;
		height: 100px;
		margin: 0;
	}

	.project-information__developer__stock {
		width: 120px;
		margin: 5px 10px;
		font-size: smaller;
	}

	.project-information__developer__developedby {
		margin-top: 0;
    width: 150px;
	}

  .project-information__textual__more__completion__row {
		flex-wrap: wrap;
	}

	.project-information__textual__more__completion__row__element {
		width: 50%;
		margin-right: 0;
		margin-bottom: 20px;
	}

	.project-information__textual__more__completion__row__element__title {
		height: 40px;
	}

}