
[data-simplebar] {
    position: relative;
    z-index: 0;
    overflow: hidden;
    -webkit-overflow-scrolling: touch; /* Trigger native scrolling for mobile, if not supported, plugin is used. */
}

[data-simplebar="init"] {
    display: flex;
}

.simplebar-scroll-content {
    overflow-x: hidden;
    overflow-y: scroll;
    min-width: 100%;
    box-sizing: content-box;
}

.simplebar-content {
    min-height: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    box-sizing: border-box;
    padding-bottom: 100px !important;
}

.simplebar-track {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 11px;
}

.simplebar-scrollbar {
    position: absolute;
    right: 2px;
    border-radius: 7px;
    min-height: 10px;
    width: 7px;
    opacity: 1;
    //transition: opacity 0.2s linear;
    background: $fixed;
    background-clip: padding-box;
}

.simplebar-track:hover .simplebar-scrollbar {
   cursor: pointer;
}

.simplebar-track .simplebar-scrollbar.visible {
    opacity: 1;
}

.simplebar-track.horizontal {
    left: 0;
    width: auto;
    height: 11px;
}

.simplebar-track.vertical {
    top: 0;
}

.horizontal.simplebar-track .simplebar-scrollbar {
    right: auto;
    top: 2px;
    height: 7px;
    min-height: 0;
    min-width: 10px;
    width: auto;
}