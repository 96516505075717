button {
  border: 0;
  cursor: pointer;
  &.keyword {
    font-size: 16px;
    padding: 5px 12px;
    color: $dark-grey;
    margin-bottom: 5px;
    border-radius: 20px;
    background: transparent;
    margin-bottom: 5px;
    border: 1px solid grey;
    &.active {
      color: white;
      background-color: $link;
      border: 1px solid $link;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &.s-error {
    color: white;
    padding: 5px 15px;
    border-radius: 3px;
    background-color: $chilli-red;
  }
  &.secondary {
    color: black;
    border-radius: 3px;
    background-color: $new-ui-border;
  }
  &.primary {
    color: white;
    padding: 5px 15px;
    border-radius: 3px;
    background-color: $btn-primary-blue;
    a {
      color: white;
    }
    &.with-border {
      border-bottom: 2px solid $navy;
    }
    &.rounded {
      padding: 5px 15px;
      border-radius: 20px;
    }
    &.no-radius {
      border-radius: 0;
      &.inverted {
        color: $link;
        background-color: white;
        border: 1px solid $link;
      }
    }
    &.icon-only {
      width: 30px;
      height: 30px;
      font-size: 15px;
      text-align: center;
      padding: 7px !important;
    }
  }
  &.padded {
    padding: 10px 0;
  }
  &.storey {
    color: white;
    &.bed-1 {
      background: $bed1;
    }
  }
  &.lime-green {
    color: white;
    padding: 5px 15px;
    border-radius: 3px;
    background-color: $lime-green;;
    a {
      color: white;
    }
    &.with-border {
      border-bottom: 2px solid $navy;
    }
    &.rounded {
      padding: 5px 15px;
      border-radius: 20px;
    }
  }
  &.with-icon {
    position: relative;
    i {
      top: 50%;
      font-size: 20px;
      font-weight: bold;
      position: absolute;
      transform: translateY(-50%);
    }
    &.with-icon-right {
      text-align: left;
      i {
        right: 15px;
        padding-left: 10px;
      }
    }
  }
  &.disabled {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
  }
}

.btn-container {
  position: relative;
  button {
    border: 0;
    width: 100%;
    padding: 5px;
    color: white;
    //background: $btn-primary-blue;
  }
  &:hover {
    cursor: pointer;
    .dropdown-dashboard {
      display: block;
    }
  }
  .dropdown-dashboard {
    top: 35px;
    width: 270px;
    display: none;
    z-index: 100;
    position: absolute;
    background-color: white;
    border: 1px solid $dark-grey;
    ul {
      padding-left: 0;
      list-style: none;
      margin-bottom: 0;
      li {
        padding: 10px;
        border-bottom: 1px solid $dark-grey;
        &:last-child {
          border: 0;
        }
        a {
          color: $dark-grey;
          text-decoration: none;
        }
      }
    }
  }
}

.badge-mrt {
  width: 40px;
  height: 24px;
  color:white;
  font-size: 14px;
  padding: 2px 5px;
  font-weight: bold;
  border-radius: 2px;
  
  &.badge-green {
    background-color: $badge-green;
  }
  &.badge-red {
    background-color: $badge-red;
  }
  &.badge-purple {
    background-color: $badge-purple;
  }
  &.badge-orange {
    background-color: $badge-orange;
  }
  &.badge-blue {
    background-color: $badge-blue;
  }
  &.badge-brown {
    background-color: $badge-brown;
  }
  &.badge-teal {
    background-color: $badge-teal;
  }
  &.badge-lrt {
    background-color: $badge-lrt;
  }
}

// Filter Tags DMS

.dms-tag {
  width: 85px;
  height: 24px;
  margin: 0 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  border-radius: 11px;
  text-align: center;
  line-height: 24px;
  &.available {
    color: $lime-green;
    border: 1px solid $lime-green;
    background-color: $lime-green-light;
  }
  &.expired {
    color: $error-red;
    background-color: $expired-red;
  }
  &.applied {
    color: $medium-blue;
    background-color: $mild-blue;
  }
}

.dms-control {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: white;
  color: $medium-blue;
  border: 1px solid $medium-blue;
  box-shadow: 0 4px 6px 0 rgba(31, 80, 156, 0.25), 0 1px 3px 0 rgba(31, 80, 156, 0.08);
  &.icon-only {
    .icon {
      font-size: 16px;
    }
  }
}

.stackview-block {
  width: 100px;
  height: 48px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: $medium-blue;
  border: solid 1px $medium-blue;
  box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.08), 
              0 0 3px 1px rgba(6, 44, 82, 0.1);
  &.stackview-block--selected {
    color: white;
    background-color: $medium-blue;
  }

  &.stackview-block--status {
    width: calc((100% / 6) - 10px);
  }

  &.stackview-block--auto {
    width: auto;
  }

  &.stackview-block--bed-filters {
    border: 0;
    color: black;
    &.small-font {
      font-size: 10px;
      font-weight: bold;
      line-height: 12px;
    }
    //width: calc((100% / 7) - 10px);
    &.stackview-block--bed-filters--retail {
      background-color: $stackview-retail;
    }
    &.stackview-block--bed-filters--1br {
      background-color: $stackview-br1;
    }
    &.stackview-block--bed-filters--2br {
      background-color: $stackview-br2;
    }
    &.stackview-block--bed-filters--3br {
      background-color: $stackview-br3;
    }
    &.stackview-block--bed-filters--4br {
      background-color: $stackview-br4;
    }
    &.stackview-block--bed-filters--5br {
      background-color: $stackview-br5;
    }
    &.stackview-block--bed-filters--ph {
      background-color: $stackview-ph;
    }
    &.stackview-block--bed-filters--bba {
      color: $stackview-bba;
      border: 3px solid $stackview-bba;
    }

    &.stackview-block--bed-filters--rba {
      color: $post-it-blue;
      border: 3px solid $post-it-blue;
    }

    &.stackview-block--bed-filters--abd {
      color: $stackview-abd;
      border: 3px solid $stackview-abd;
    }

    &.stackview-block--bed-filters--sold {
      color: $error-red;
      border: 3px solid $error-red;
    }

    &.stackview-block--bed-filters--unreleased {
      color: $greyish-blue;
      border: 1px solid $carolina-blue;
    }
  }
}

@mixin triangle() {
  width: 0;
  height: 0;
  border-style: solid;
  display: inline-block;
  border-width: 8px 8px 0 8px;
}

.triangle {
  @include triangle();
  &.triangle--white {
    border-color: white transparent transparent transparent;
  }
  &.triangle--blue {
    border-color: $medium-blue transparent transparent transparent;
  }
}

.selected-filter {
  font-size: 14px;
  border-radius: 4px;
  color: $medium-blue;
  border: 1px solid $medium-blue;
  .fa {
    color: $red;
  }
}
.dms-report-settings {
  width: auto;
  padding: 8px;
  color: $navy;
  font-size: 16px;
  font-weight: bold;
  border-radius: 24px;
  display: inline-block;
  margin-bottom: 8px;
  background-color: $greyish-blue;

  i {
    font-size: 20px;
    &:hover {
      cursor: pointer;
    }
  }

}

.dms-reports-alert {
  width: 100%;
  height: 65px;
  padding: 20px 40px;

  &--success {
    color: $success-alert;
    background-color: $lime-green-light;
  }
}

.transparent {
  font-size: 12px;
  border-radius: 4px;
  color: $main-nav;
  font-family: 'Roboto';
  border: 1px solid $main-nav;
}
