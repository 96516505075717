.secondary-header {
  height: 60px;
  padding: 15px 5px;
  border-bottom: 1px solid $border-grey; 

  .list-unstyled {
    text-align: right;
    li {
      font-size: 16px;
      padding: 0 10px;
      color: $main-nav;
      display: inline-block;
      &.line-height {
        padding: 0;
        .fa {
          font-size: 20px;
        }
      }
      
      button {
        margin: 3px;
        width: auto;
        font-size: 14px;
        color: $main-nav;
        border: 1px solid $main-nav; 
        font-family: 'Roboto', sans-serif;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .search {
    padding: 0 15px 0 0;
    margin-top: 5px;
    border-right: 1px solid $main-nav;

    .form-search-input {
      width: 0;
      border: 0;
      outline: 0;
      padding: 0;
      transition: all .5s;

      &.show {
        width: 200px;
        border-bottom: 1px solid black;
      }
    }

    .fa {
      &:hover {
        cursor: pointer;
      }
    }
  }

}

.main-header {
  height: 100px;
  padding: 20px 0;
  background-color: #ffffff;

  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }

  .container-fluid.with-border {
      border-bottom: 1px solid $main-nav;
      &:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
      }
  }

  .nav-container {
      // Logo and branding
    .brand {
      float: left;
      width: 300px; 
      text-transform: uppercase;
      a,
      a:visited {
        text-decoration: none;
      }
    }

    // Navigation 
    nav {
      float: left;
      width: calc(100% - 300px);
      ul {
        background-color: white;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          float: left;
          line-height: 60px;
          position: relative;
          background-color: white;
          a,
          a:visited {
            display: block;
            padding: 0 20px;
            color: $main-nav;
            text-decoration: none;
            &:not(:only-child):after {
              font-family: "FontAwesome";
              padding-left: 4px;
              content: "\f107";
            }
          } // Dropdown list
          ul li {
            min-width: 190px;
            a {
              padding: 15px;
              line-height: 20px;
            }
          }
          &.float-md-right {
            .btn {
              margin-top: 10px;
              border-radius: 0;
              border-color: $navy;        
              background-color: $navy;
              &:hover {
                background-color: lighten($color: $navy, $amount: 15%);
              }
            }
          }
        }
      }
    }

    // Dropdown list binds to JS toggle event
    .nav-dropdown {
      z-index: 1;
      display: none;
      padding: 20px 10px;
      position: absolute;
      background-color: white;
      border: 1px solid $nav-dropdown;
      &:before {
        content: '';
        width: 0;
        height: 0;
        top: -9px;
        border-color: rgba(204, 204, 204, 0);
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid $nav-dropdown;
        left: 30px;
        position: absolute;
      }
      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-color: rgba(204, 204, 204, 0);
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid white;
        position: absolute;
        left: 31px;
        position: absolute;
        top: -7px;
      }
    }

    /* Mobile navigation */

    // Binds to JS Toggle
    .nav-mobile {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      height: 70px;
      width: 70px;
    }
    @media only screen and (max-width: 798px) {
      // Hamburger nav visible on mobile only
      .nav-mobile {
        display: block;
      }
      nav {
        width: 100%;
        ul {
          left: 0;
          top: 70px;
          width: 100%;
          height: 350px;
          display: none;
          z-index: 10000;
          position: absolute;
          background-color: white;
          //box-shadow: 20px 20px 50px 15px $main-nav;
          li {
            float: none;
            border-bottom: 1px solid $main-nav;

            &.float-md-right {
              border-bottom: 0;
              .btn-group {
                margin-left: 10px;
                .btn {
                  width: 150px;
                }
              }
            }
            a {
              padding: 15px;
              line-height: 20px;
            }
            ul {
             li {
               border: 0;
              a {
                padding-left: 30px;
              }
             }
            }
          }
        }
      }
      .nav-dropdown {
        position: static;
        height: auto;
        
        &:before, &:after {
          border-left: 0;
          border-right: 0;
          border-bottom: 0;
        }
        
      }
    }
    @media screen and (min-width: 798px) {
      .nav-list {
        display: block !important;
      }
    }
    #nav-toggle {
      position: absolute;
      left: 18px;
      top: 18px;
      cursor: pointer;
      padding: 10px 35px 16px 0px;
      span,
      span:before,
      span:after {
        cursor: pointer;
        border-radius: 1px;
        height: 3px;
        width: 30px;
        background: $light-blue;
        position: absolute;
        display: block;
        content: '';
        transition: all 300ms ease-in-out;
      }
      span:before {
        top: -10px;
      }
      span:after {
        bottom: -10px;
      }
      &.active span {
        background-color: transparent;
        &:before,
        &:after {
          top: 0;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }

}

@media only screen and (max-width: 1200px) {
  .main-header {
    padding: 10px 0;

  }
}

@media only screen and (max-width: 1024px) {
  .main-header {
    height: 80px;
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.5);
    .nav-container {
      .brand {
        width: 250px;
        padding: 8px;
      }
    }
  }
} 

@media only screen and (max-width: 768px) {
  hgroup {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    position: fixed;
    z-index: 1000;
    background-color: white;
  }
}

@media only screen and (max-width: 640px) {
  .header {
    .navbar-brand {
      img {
        width: 80%;
      }
    } 
  }
}


// .header {
//   padding: 20px 0;

//   .container.with-border {
//       padding-left: 0;
//       padding-right: 0;
//       padding-bottom: 10px;
//       border-bottom: 1px solid $logo-divider;
//   }

//   .navbar-brand {
//     padding-right: 20px;
//     border-right: 3px solid $logo-divider;
//     img {
//       width: 300px;
//     }
//   }
  
//   .navbar-collapse {
//     label {
//       font-size: 13px;
//     }
//   }

//   .navbar-toggleable-md {
//     .navbar-nav {
//       .nav-link {
//         color: $nav-link-inactive;
//         padding: 0 15px;
//       }
//       .nav-item {
//         &.active {
//           border-bottom: 2px solid $nav-link-active;
//           .nav-link {
//             color: $nav-link-active;
//           }
//         }
//         &.dropdown {
//           &:focus {
//             border-bottom: 2px solid $nav-link-active;
//           }
//           .dropdown-toggle::after {
//             color: $nav-link-active;
//           }
//           .dropdown-menu {
//             top: 50px;
//             border: 0;
//             padding: 0;
//             min-width: 13rem;
//             .dropdown-item {
//               padding: 10px 8px;
//               border-top: 1px solid #b1b4b8;
//               border-left: 1px solid #b1b4b8;
//               border-right: 1px solid #b1b4b8;
//               &:last-child {
//                 border-bottom: 1px solid #b1b4b8;
//               }
//             }
//           }
//         }
//       }
//     }
//   }

//   .navbar-toggler-icon {
//     background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(0, 120, 193, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/></svg>");
//   }
// }

// @media only screen and (max-width: 1200px) {
//   .header {
//     padding: 10px 0;

//     .navbar-brand {
//       border-right: 0;
//     }

//     .navbar-toggleable-md {
//       .navbar-nav {
//         .nav-item {
//           font-size: 16px;
//          .nav-link {
//             padding: 0 10px;
//           } 
//         }
//       }
//     }
//   }
// }

// @media only screen and (max-width: 990px) {
//   .header {
//     .navbar-collapse {
//       position: absolute;
//       background: white;
//       padding: 20px;
//       top: 69px;
//       width: 100%;
//       left: 0px;
//       z-index: 1000;
//     }
    
//     .navbar-toggleable-md {
//       .navbar-nav {
//         .nav-item {
//           .nav-link {
//             padding: 10px 0;
//           }
//           &.dropdown {
//             .dropdown-menu {
//               .dropdown-item {
//                 border: 0;
//                 &:last-child {
//                   border: 0;
//                 }
//               }
//             }
//           }
//         }
//       }
//     }

//     .btn-primary {
//       width: 100%;
//     }
//   }
// }

// @media only screen and (max-width: 640px) {
//   .header {
//     .navbar-brand {
//       img {
//         width: 80%;
//       }
//     } 
//   }
// }
