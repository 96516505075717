.accordion { 
  padding: 0;
  width: 100%;
  display: block;
  list-style: none;  
}

.accordion li { 
  border-bottom: 2px solid white; 
}

.accordion > li > a {
  color: $main-nav;
  padding: 20px 0;
  display: block; 
  text-align: left;
  background: white;
  position: relative;
  text-decoration: none;
  border-bottom: 1px solid $main-nav;
}

.accordion > li > a .icon {
  padding-right: 10px;
}

.accordion li ul {  
  padding: 0; 
  width: 100%;
  display: none;
  list-style: none;
  max-height: 200px;
  position: relative;
}

.accordion li ul li {
  border: 0;
}

.accordion li ul li a { 
  color: $main-nav;
  display: block; 
  padding: 20px 0px;
  text-align: center;
  text-decoration: none;
  background: white;
  box-sizing: border-box;
}

.accordion li ul li a span:hover, .accordion li ul li.selected a span {
  height: 40px;
  width: 40px;
  display: inline-block;
  text-align: center;
  position: relative;
  border: 2px solid $border-grey;

  &:before {
    top: -25px;
    font-family: "FontAwesome";
    content: "\f106";
    position: absolute;
  }

  &:after {
    left: 50%;
    bottom: -25px;
    content: "\f107";
    position: absolute;
    font-family: "FontAwesome";
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 1024px) {
  
  .accordion > li > a {
    padding: 10px;
  }
  
  .accordion > li > a span {
    display: none;
  }
  
}

@media only screen and (max-width: 640px) {
  
  .accordion > li > a {
    font-size: 14px;
    padding: 5px;
  }
  
}