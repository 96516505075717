.label-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.label-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  position: absolute;
  background-color: white;
  border: 1px solid $border-grey;
}

/* When the checkbox is checked, add a blue background */
.label-container input:checked ~ .checkmark {
  background-color: $border-grey;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.label-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.label-container .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

// Radio Buttons

.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio-container > .checkmark {
  top: 8px;
  left: 0;
  width: 16px;
  height: 16px;
  margin-top: 2px;
  border-radius: 50%;
  position: absolute;
  border: 1px solid $border-grey;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-container > .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
 	top: 50%;
	left: 50%;
	width: 8px;
	height: 8px;
	border-radius: 50%;
  background: $dark-grey;
  transform: translate(-50%, -50%);
}

// Advanced Search Checkboxes

.advanced-search-label {
  display: block;
  cursor: pointer;
  user-select: none;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
}

/* Hide the browser's default checkbox */
.advanced-search-label input {
  opacity: 0;
  cursor: pointer;
  position: absolute;
}

/* Create a custom checkbox */
.advanced-search-label .checkmark {
  top: 6px;
  left: 7px;
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 3px;
  border: 1px solid $warm-grey;;
}


/* When the checkbox is checked, add a blue background */
.advanced-search-label input:checked ~ .checkmark {
  background-color: white;
  border: 2px solid black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  display: none;
  position: absolute;
}

/* Show the checkmark when checked */
.advanced-search-label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.advanced-search-label .checkmark:after {
  top: 0px;
  left: 4px;
  width: 6px;
  height: 12px;
  border: solid black;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

input[type=text] {
  &::placeholder {
    opacity: 0.2;
  }
}