.contact {
	.contact-us {

		label {
			font-size: 14px;
			display: block;
			margin: 15px 0 5px 0;
		}

		.nice-select {
			border-radius: 0;
			border-top: 0;
			border-left: 0;
			border-right: 0;
			padding-left: 0;
			.list {
				border-radius: 0;
				li {
					border-bottom: 1px solid $border-grey;
				}
			}
		}
		input {
			border-top: 0;
			border-left: 0;
			border-right: 0;
			padding-left: 0;
			transition: border .3s ease-in;
			&:focus {
				border-bottom: 1px solid $main-nav;
			}
		}
		textarea {
			&:focus {
				border: 1px solid $main-nav;
			}
		}
		button[type="submit"] {
			width: 200px;
		}
	}
}