.alphabetical-order {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;
  border-top: 1px solid $greyish-brown;
  border-bottom: 1px solid $greyish-brown;
  &.reduced {
    justify-content: initial;
    a {
      margin: 0 10px;
    }
  }
  a {
    color: $warm-grey;
    &.selected {
      font-weight: bold;
      color: $greyish-brown;
      text-decoration: underline;
    }
  }
}

.agent-list-block {
  display: flex;
  height: 242px;
  margin-bottom: 20px;
  border: 1px solid $warm-grey;
  .agent-thumb {
    flex: 1 200px;
    img {
      width: 100%;
      height: 240px;
      padding: 1px;
      border: solid 1px;
    }
  }
  .agent-list-info {
    display: flex;
    padding: 20px;
    flex: 1 calc(100% - 240px);
    .agent-list-info-content {
      flex: 1 40%;
      p {
        margin-bottom: 5px;
      }
    }
    .agent-list-info-cta {
      flex: 1 60%;
      p {
        margin-top: 140px;
      }
    }
  }
  &.no-border {
    border: 0;
    .agent-list-info {
      padding: 0 0 0 20px;
      a {
        font-size: 16px;
        text-decoration: underline;
      }
      ul {
        margin-bottom: 0;
      }
      hr {
        margin: 10px 0;
        border-top: 1px solid $greyish-brown;
      }
      .agent-list-info-content {
        position: relative;
        button {
          top: 50%;
          right: 0;
          position: absolute;
        }
      }
    }
  }
}