.vp-player-layout {
  left: 10% !important;
  right: 10% !important;
}
.text-verified{
  color: #228B22 !important;
}
.text-warning{
  color: #f0ad4e !important;
}
a {
  &.advanced {
    color: darkgrey;
    padding-top: 10px;
    text-decoration: underline;
  }
}

.home-advanced-search {
  padding: 50px 0;
  background-color: #f3f3f3;;
  .input-group-append {
    .btn-primary {
      border-radius: 0;
      border-bottom: 0;
    }
  }
  input[type=text] {
    &::placeholder {
      opacity: 0.2;
    }
  }
}

.btn-transparent {
  border: 1px solid $tabbed-nav-active;
  color: $tabbed-nav-active;
  background: transparent;
  padding: 15px 25px;
  margin: 50px auto;
  display: block;
  width: 250px;
  &:focus, &:hover {
    background: lightgrey;
  }
}

.sliding {
  display: block;
  margin: 20px 0;
  overflow-x: hidden;
  white-space: nowrap;
  .img-block {
    min-width: 150px;
    margin-right: 15px;
    box-sizing: border-box;
    margin-bottom: 15px;
    height: 110px;
    display: inline-block;
    position: relative;
    span, h5 {
      left: 15px;
      white-space: normal;
    }
    .separator {
      top: 15px;
      background: white;
      content: "";
      width: 100px;
      height: 5px;
      position: absolute;
    }
    h5 {
      top: 30px;
      color: white;
      position: absolute;
    }
    &.sponsored {
      .sponsored-text {
        position: absolute;
        font-size: 12px;
        color: white;
        bottom: 5px;
        left:60px;
      }
    }
  }
}

.home-tabs {
  margin: 0 0 30px 0;
  .container {
    z-index: 10;
  }
  .justify-content-end {
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;

    h2 {
      margin: 3px;
      padding-top: 5px;
    }
  }
  .nav-tabs {
    padding: 0 40px;
  }
  .nav-tabs {
    border: 0;
    .nav-link {
      border: 0;
      font-weight: bold;
      color: $tabbed-nav-inactive;
      &.active {
        color: $tabbed-nav-active;
        border-bottom: 1px solid $grey;
      }
    }
  }
  .tab-content {
    .map-container {
      height: 800px;
    }
    & > .active {
      padding-top: 20px;
    }
  }
}

.project-card {
  color: $main-nav;
  margin-bottom: 20px;
  border: 1px solid $fixed;

  .project-card-text {
    padding: 20px;
    font-weight: 400;
    .project-card-logo img {
      height: auto;
      min-height: 100px;
      border: 1px solid $fixed;
    }
    .project-card-details {
      h6 {
        margin-bottom: 10px;
        a {
          color: $main-nav;
        }
      }
      p {
        margin-bottom: 0;
        &.address {
          line-height: 1;
          height: 40px;
        }
      }
    }
    .project-card-stock {
      p {
        span {
          font-weight: bold;
          display: inline-block;
          width: 60%;
        }
        margin-bottom: 0;
        font-size: 15px;
        border-bottom: 1px solid $main-nav;
        &:first-child {
          border-bottom: 0;
        }
      }
    }
    .project-card-currencies {
      p {
        font-size: 14px;
        margin-bottom: -5px;
      }
    }

    .social-container {
      ul {
        list-style: none;
        padding-left: 0;
        li {
          display: inline-block;
          padding-right: 10px;
          a {
            color: $main-nav;
          }
        }
      }
    }
  }

  .project-card-footer{
    border: 0;
    height: 40px;
    width: inherit;
    color:#fff;
    background-color: #8b8d8f;
    padding-top: 8px;
    padding-left: 20px;

    i{
      margin-right: 20px;
      margin-top: 4px;
    }
  }

  .project-card-img {
    margin: 0;
    height: 330px;
    position: relative;
    .label {
      top: 0;
      left: 0;
      width: 70px;
      position: absolute;
    }

    .soldout {
      top: 0;
      right: 0;
      width: 70px;
      position: absolute;
    }
  }
}

.custom-card {
  display: flex;
  color: $main-nav;
  font-weight: 400;
  margin-bottom: 20px;
  border: 1px solid $main-nav;
  .project-thumb {
    width: 25%;
    .project-thumb-img {
      width: 100%;
      height: 100%;
      position: relative;
      .card-label, .card-soldout {
        top: 0;
        left: 0;
        width: 70px;
        height: 70px;
        position: absolute;
      }
      img {
        height: 100%;
      }
      .label {
        top: 0;
        left: 0;
        max-width: 70px;
        max-height: 70px;
        position: absolute;
      }
      .soldout {
        top: 0;
        right: 0;
        max-width: 70px;
        max-height: 70px;
        position: absolute;
      }
    }
    
  }
  .project-info {
    width: 75% !important;
    padding: 15px;
    .project-info-logo img {
      width: 105px;
      height: 95px;
      border: 1px solid $grey;
    }
    .project-info-text {
      .text-container {
        padding: 10px 0;
        p {
          margin-bottom: 0;
        }
      }
    }
    .bottom-info {
      p {
        margin-bottom: 0;
      }
      .social-container {
        ul {
          list-style: none;
          padding-left: 0;
          li {
            display: inline-block;
            padding-left: 10px;
            a {
              color: $main-nav;
            }
          }
        }
      }
    }
  }
}

.testimonials {
  height: auto;
  min-height: 300px;
}

.project-partners {
  border-top: 1px solid $greyish;
  border-bottom: 1px solid $greyish;
  .col-4, .col-md-3 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .partners-logos {
    .partner-block {
      width: 200px;
      height: 120px;
      img {
        height: 120px;
        vertical-align: middle;
      }
    }
  }
}

.newsletter-signup {
  border-bottom: 1px solid $border-grey;
  .newsletter-signup-container {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .newsletter-signup-block {
      width: calc(100% / 3);
      font-size: 16px;
      color: $main-nav;
      img {
        max-width: 80%;
        padding-bottom: 20px;
      }
      h3 {
        font-weight: 400;
        color: $light-blue;
      }
      p {
        max-width: 70%;
      }
    }
    form {
      position: relative;
      .input-container {
        padding-right: 100px;
        input {
          margin: 0 auto;
          border-radius: 20px;
        }
        button {
          top: 0;
          right: 100px;
          outline: none;
          color: $main-nav;
          position: absolute;
          background-color: transparent;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .project-partners {
    .partners-logos {
      .partner-block {
        width: auto;
        height: 120px;
        img {
          height: 120px;
          vertical-align: middle;
        }
      }
    }
  }
}
 
@media only screen and (max-width: 768px) {
  .search-home {
    .col-lg-6, .col-md-6 {
      margin-bottom: 10px;
    }
  }
  
  .home-tabs {
    .tab-content {
      .map-container {
        height: 250px;
      }
    }
  }

  .project-card {
    color: $main-nav;
    margin-bottom: 20px;
    border: 1px solid $fixed;
  
    .project-card-text {
      
      .social-container {
        ul {
          li {
            padding-right: 5px;
          }
        }
      }
    }
  }

  .newsletter-signup {
    .newsletter-signup-container {
      .newsletter-signup-block {
        width: 100%;
        padding: 20px;

        img {
          max-width: 100%;
        }
      }
      form .input-container {
        padding-right: 0;
        button {
          right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  
  .search-home {
    .form-hide-mobile {
      display: none;
      &.active {
        display: block;
      }
    }
    .form-group {
      .plus {
        position: relative;
        .absolute {
          right: -12px;
          bottom: 6px;
          display: block;
          font-size: 24px;
          position: absolute;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .home-tabs {
    .row.justify-content-end {
      padding-left: 0;
    }
    .nav-tabs {
      padding: 10px 0;
      
      .nav-link {
        font-size: 12px;  
        &.active {
          border-bottom: 0;
        }
      }
    }
  }
}

.projects-container {
  padding: 0 1em;
  margin: 0 auto;
  max-width: 1296px;
}

.project {
  margin-right: 1em;
  margin-bottom: 1em;
  display: inline-block;
  width: calc(33% - .6em);
  border: 1px solid $fixed;
  
  &:nth-child(3n + 3) {
    margin-right: 0;
  }
  
  &__image {
    margin: 0;
    padding: 0;
    height: 320px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      .label {
        top: 0;
        left: 0;
        width: 70px;
        position: absolute;
      }
  
      .soldout {
        top: 0;
        right: 0;
        width: 70px;
        position: absolute;
      }
    }
  }
  
  &__content {
    padding: 24px;
    &__details {
      display: flex;
      .project__logo {
        margin: 0;
        padding: 0;
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        border: solid 1px $fixed;
        img {
          max-height: 60%;
          height: auto;
        }
      }
      .project__text {
        width: 75%;
        margin-left: 16px;
        border-bottom: solid 1px $fixed;

        &__address,
        &__type,
        &__tenure {
          display: block;
          font-size: 13px;
          font-weight: normal;
          color: $main-nav;
        }

        &__name {
          font-size: 21px;
          font-weight: bold;
          color: $dark-grey;
        }

        hr {
          margin-top: 16px;
        }
      }
    }
  }
}

.project__units__container {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  .project__units {
    width: 115px;
    font-size: 12px;

    &__text {
      padding: 3px 0;
      color: $main-nav;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $fixed;

      &__title {
        font-weight: bold;
      }

      &__number {
        text-align: right;
        display: inline-block;
      }

    }
  }
  .share-container {
    margin-top: auto;
    ul {
      margin: 0;
      display: flex;
      list-style: none;

      li {
        margin-left: 10px;
        a {
          color: $main-nav;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .project {
    width: calc(50% - .6em);
    border: 1px solid $fixed;
    &:nth-child(3n + 3) {
      margin-right: 1em;
    }

    &:nth-child(2n + 2) {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 660px) {
  .project {
    width: calc(33% - .6em);
    border: 1px solid $fixed;
    
    &:nth-child(3n + 3) {
      margin-right: 0;
    }

    &:nth-child(2n + 2) {
      margin-right: 0;
    }

    &__content {
      &__details {
        .project__logo {
          width: 100px;
          height: 100px;
        }
        .project__text {
          padding-bottom: 5px;
        }
      }
    }
  }
}
