.circle {
	width: 80px;
    padding-top: 0;
    position: relative;
    text-align: center;
	vertical-align: top;
    display: inline-block;
	strong {
      left: 0;
      top: 22px;
      width: 100%;
      font-size: 12px;
      text-align: center;
      position: absolute;
	}
	.inner-circle {
      top: 10px;
      left: 10px;
      right: 10px;
      height: 60px;
      margin: auto;
      color: $navy;
      position: absolute;
      border-radius: 50%;
      width: 60px !important;
      border: 6px solid #dfe1e3;
	}
}

@media only screen and (max-width: 1024px) {
  .circle {
      width: 80px;
      padding-top: 0;
      position: relative;
      text-align: center;
      vertical-align: top;
      display: inline-block;
    
      canvas {
        width: 60px !important;
        height: 60px !important;
      }
    
      strong {
        top: 15px;
        width: 100%;
        font-size: 10px;
      }
    
      .inner-circle {
        top: 8px;
        left: 8px;
        right: 8px;
        height: 45px;
        width: 45px !important;
        border: 5px solid #dfe1e3;
    }
  }
}
