.userprofile{
  
    .progressbar {
          width: 50%;
          height: 15px;
          background-color: #fff;
          padding: 0px;
          margin: .6em 0;
          border: 1px #000 double;
          clear: both;
          border-radius:20px;

           #progress {
              border-radius:20px;
              background: #6f6f6f; /*-- Color of the bar --*/
              height: 13px;
              width: 0%;
              max-width: 100%;
              float: left;
              -webkit-animation: progress 2s 1 forwards;
              -moz-animation: progress 2s 1 forwards;
              -ms-animation: progress 2s 1 forwards;
              animation: progress 2s 1 forwards;

              .pbaranim {
                  height: 15px;
                  width: 100%;
                  overflow: hidden;
                  -moz-opacity: 0.25;
                  -khtml-opacity: 0.25;
                  opacity: 0.25;
                  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=25);
                  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=25);
                  filter: alpha(opacity=25);
              }

          }

      }

      .access{
        color:#fff;
        background-color: #000;
        border-color: #000;
      }

      .pd-2{
        padding: 2rem !important;
      }

      mt-1{
        margin-top: 1rem !important;
      }

      .verify{
         display: inline-block;
         margin-top:30px;
          a{
           color:#fff !important;
           padding-left: 40px;
           padding-right: 40px;
        }
      }

      .btn-verify{
         color:#fff !important;
         padding-left: 40px;
         padding-right: 40px;
      }
}