.project-block {
  margin: 30px 0;
  position: relative;
  padding: 20px 20px 0;
  background-color: white;
  border: 1px solid $sold;
  a {
    text-decoration: none;
  }
  .project-indication {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    &.live {      
      background-color: #5ABA0C;
    }
    &.draft {
      background-color: grey;
    }    
  }
  p {
    font-size: 16px;
  }
  .date-updated {
    color: $sold;
    font-size: 14px;
    margin-top: -15px;
    &.review {
      color: orange;
    }
    &.rejected {
      color: $red;
    }
    &.approved {
      color: green;
    }
    .fa, .icon {
      padding-right: 5px;  
    }
  }
  img.logo {
    right: 5px;
    bottom: 5px;
    width: 100px;
    max-height: 15px;
    position: absolute;    
  } 
}

.info-block {
  height: 100%;
  padding: 20px;  
  background-color: white;
  &.total-sales {
    ul {
      padding-left: 0;
    }
    .circular-bar {
      width: 70%;
      margin: 0 auto;
    }
  }
  .btn {
    margin-top: 0;
  }
  h4 {
    padding-top: 5px;
  }
  p {
    margin-bottom: 10px;
  }
}

// Update project info

.update-information {
  padding: 10px;
  position: relative;
  border: 1px solid $navy;
  .col-sm-4.p-top {
    padding-top: 80px;
    .btn {
      color: $navy;
      min-width: 100%;
      text-align: left;
      font-weight: bold;
      position: relative;
      margin-bottom: 10px;
      border: 1px solid $navy;
      background-color: white;
      .icon {
        right: 10px;
        position: absolute;
        &.icon-favorite-2 {
          color: $red;
        }
      }
    }
  }
}

// Animated Pie

.pie {
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;
  background-color: white;
  font-size: 7em;
  text-align: center;
  @include border-radius(50%);
}
.slice-right {
  position: absolute;
  z-index: 11;
  background-color: blue;
  width: 100%;
  height: 100%;
  clip: rect(0, .5em, .5em, .5em);
  @include border-radius(50%);
}
.slice-left {
  position: absolute;
  z-index: 11;
  background-color: blue;
  width: 100%;
  height: 100%;
  clip: rect(.5em, .5em, 1em, .5em);
  @include border-radius(50%);
  }
.percent {
  position: absolute;
  z-index: 20;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  background: white;
  @include border-radius(50%);
}
.number {
  position: absolute;
  z-index: 30;
  width: 100%;
  height: 100%;
  padding-top: 50%;
  line-height: 0;
  font-size: .3em;
  color: #485c65;
}

$animation-speed: .3s;
.slice-right {
  -webkit-animation-delay: $animation-speed * 4;
  -webkit-animation-duration: $animation-speed;
  -webkit-animation-name: right-slice;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
}

.slice-left {
  -webkit-animation-delay: $animation-speed * 5;
  -webkit-animation-duration: $animation-speed;
  -webkit-animation-name: left-slice;
  -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
}

@-webkit-keyframes right-slice {
  from {
    clip: rect(0, 50%, .5em, .5em);
  }
  50% {
    clip: rect(0, 1em, .5em, .5em);
  }
  to {
    clip: rect(0, 1em, 1em, .5em);
  }
}

@-webkit-keyframes left-slice {
  from {
    clip: rect(.5em, .5em, 1em, .5em);
  }
  50% {
    clip: rect(.5em, .5em, 1em, 0)
  }
  to {
    clip: rect(.3em, .5em, 1em, 0);
  }
}

// Dashboard edit project steps

.dashboard-steps {
  position: relative;
  &:before {
    left: 0;
    right: 0;
    top: 24px;
    width: 100%;
    content: '';
    position: absolute;
    border-top: 1px dashed black;
  }
  ul {
    li {
      width: 19%;
      color: $navy;
      font-size: 14px;
      line-height: 20px;
      vertical-align: top;
      text-align: center;
      display: inline-block;
      font-family: 'Lato', sans-serif;
      .numeric {
        display: block;
        width: 50px;
        height: 50px;
        z-index: 1;        
        font-size: 18px;
        background: white;
        position: relative;        
        margin: 0 auto 10px;
        line-height: 41px;
        border-radius: 100%;
        border: 5px solid $navy;
      }
      &.active {
        .numeric {
          background: $navy;
          color: white;
        }
      }
    }
  }
}

// Add new project form

.add-new-project {
  margin-top: 100px;
  
  h2 {
    margin-bottom: 50px;
  }
}
