@import "../partials/variables";

.edit-alias-controls-container {
  display: flex;
  justify-content: space-between;

  .edit-alias-controls__tabs {
    display: flex;
    .edit-alias__tab {
      width: 215px;
      height: 60px;
      display: flex;
      padding: 0 10px;
      cursor: pointer;
      align-items: center;
      border-bottom: none;
      justify-content: space-between;
      background-color: $dashboard-bg;
      border: 1px solid $carolina-blue;

      &--active {
        .edit-alias__tab__title {
          color: $navy;
          font-size: 16px;
          font-weight: bold;
        }
        background-color: white;
        border-bottom: 0;
      }
      &__title {
        font-weight: 300;
        color: $carolina-blue;
      }

      &__icon {
        color: $navy;
        font-size: 20px;
      }
    }
  }

  .edit-alias-controls__buttons {
    display: flex;
    justify-content: space-between;
    &__btn {
      width: 50px;
      height: 50px;
      color: white;
      border-radius: 50%;
      text-align: center;
      margin-left: 10px;
      background-color: $medium-blue;
    box-shadow: 0px 3px 6px #000029;
      &--active {
        background-color: $sky-blue;
      }

      i {
        display: flex !important;
        align-items: center;
        justify-content: center;
      }

    }
  }
  
}

.edit-alias-fields-container{
  margin-top: -1px;
  position: relative;
  background-color: white;
  padding: 60px 30px 30px 30px;
  border: 1px solid $carolina-blue;
  .edit-alias-fields-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title {
      color: $navy;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.controls-file-formats {
  &__title, &__label-container {
    color: $navy;
    font-size: 16px;
    font-weight: bold;
  }
  &__label-container {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    user-select: none;
    
    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    
    /* Create a custom checkbox */
    .checkmark {
      top: 3px;
      left: 12px;
      width: 16px;
      height: 16px;
      border-radius: 4px;
      position: absolute;
    }
    
    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
      background-color: transparent;
    }
    
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }
    
    /* Style the checkmark/indicator */
    .checkmark:after {
      top: 1px;
      left: 4px;
      width: 7px;
      height: 11px;
      border: solid $medium-blue;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
}

.edit-alias-fields-blocks-container {
  margin-top: 60px;
  border: 1px solid $carolina-blue;

  &__select-all {
    padding: 10px;
    border-bottom: 1px solid $carolina-blue;;
  }
}

.edit-alias-fields-block {
  padding: 20px;
  height: 180px;
  &:not(:last-child) {
    border-bottom: 1px solid $carolina-blue;
  }

  .edit-alias-input-fields {
    display: flex;
    margin: 20px 0;
    margin-left: 40px;
    align-items: center;

    &__indicator {
      width: 24px;
      height: 30px;
      margin-bottom: 20px;
      border-left: 1px solid $carolina-blue;
      border-bottom: 1px solid $carolina-blue;
    }
    
    &__input {
      padding-left: 10px;
      width: calc(100% - 180px);
      border: 10px solid $dashboard-bg;
      border-right: 20px solid $dashboard-bg;
    }

    &__btn-container {
      margin-left: 20px;
      display: flex;
      width: 120px;
      

      .edit-alias-input-fields__btn {
        width: 40px;
        height: 40px;
        display: flex;
        border-radius: 50%;
        margin-left: 15px;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 3px 6px #000029;
        .icon {
          display: flex;
          align-items: center;
        }

        &--cancel {
          color: $navy;
          background-color: $ice-blue;
        }

        &--continue {
          color: white;
          background-color: $sky-blue;
        }
      }

      .primary {
        width: 100%;
        height: 40px;
        font-size: 14px;
        box-shadow: 2px 3px 2px black;
      }
    }
  }

  .controls-file-formats {
    margin-left: 40px;
  }
}

.delete-selected-reports {
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  position: absolute;
  align-items: center;
  background-color: $ice-blue;
  button {
    width: 150px;
    height: 40px;
    font-size: 14px;
    margin-left: 30px;
    font-weight: bold;
    border-radius: 4px;
    color: $medium-blue;
    border: 2px solid $medium-blue;
    box-shadow: 2px 3px 2px black;
  }
}

.save-cancel-btn-container {
  padding: 20px;
  display: flex;
  justify-content: flex-end;

  .control {
    width: 100px;
    height: 40px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;
    margin-left: 20px;
    box-shadow: 0px 3px 6px black;

    &--save {
      background-color: $medium-blue;
    }

    &--cancel {
      background-color: $red;
    }
  }
}