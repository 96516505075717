.profile-photo {
  width: 150px;
  height: 150px;
  padding: 20px 5px;
  box-sizing: border-box;
  border: 1px dashed $fixed; 
  position: relative;
  .overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    background-color: rgba(0,0,0,0.6);
    transition: opacity .3s ease-in-out;
    a {
      color: white;
      font-size: 12px;
      line-height: 150px;
      text-decoration: none;
    }
  }
  &:hover {
    .overlay {
      //opacity: 1;
    }
  }
}

span.bind-html {
  color: $main-nav !important;
}

.eoi-modal {
  a.disabled {
    cursor: not-allowed;
    text-decoration: none;
    opacity: 0.5;
  }
}
.eoi {

  .profile-photo {
    width: 150px;
    height: 150px;
    padding: 20px 5px;
    box-sizing: border-box;
    border: 1px dashed $fixed; 
    position: relative;
    .overlay {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: none;
      position: absolute;
      background-color: rgba(0,0,0,0.6);
      transition: opacity .3s ease-in-out;
      a {
        color: white;
        font-size: 12px;
        line-height: 150px;
        text-decoration: none;
      }
    }
    &:hover {
      .overlay {
        //opacity: 1;
      }
    }
  }

  h4 {
    padding: 10px;
    position: relative;
    border-top: 1px solid $new-ui-border;
    border-bottom: 1px solid $new-ui-border;

    span {
      top: 50%;
      right: 10px;
      font-size: 14px;
      position: absolute;
      transform: translateY(-50%);
      a {
        color: $main-nav;

        img {
          width: 15%;
        }
      }
    }
  }

  .inputs-container {
    max-width: 80%;
    margin: 0 auto;
  }
  label {
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    span {
      color: red;
    }
  }

  input:focus {
    border-color: $main-nav;
  }

  .nice-select {
    width: 100%;
    border-radius: 0;
    .list {
      width: 100%;
      border-radius: 0;
      max-height: 100%;
    }
  }
  .add-buyer {
    height: 40px;
    padding: 0 20px;
    line-height: 40px;
    border: 1px dashed $new-ui-border;
    &:hover {
      cursor: pointer;
    }
  }
  .declaration-form {
    padding: 50px;
    border: 1px solid $new-ui-border;
  }
  .text-container {
    width: 60%;
    height: 300px;
    overflow-y: scroll;
  }

  .digital-signature {
    height: 110px;
    max-width: 270px;
    border: 1px solid $new-ui-border;
  }

  .main-checkbox-container {
    padding: 10px;
  }

  .inner-checkbox-container {
    width: 70%;
    padding-left: 30px;
  }

  .pdi-letter-container {
    max-width: 65%;
    border: 1px solid $new-ui-border;

    .pdi-text-container {
      padding: 30px;
      box-sizing: border-box;

      p.larger {
        font-size: 20px;
        a {
          color: $main-nav;
          text-decoration: none;
          &.completed {
            i {
              color: green;
            }
            &:hover {
              cursor: not-allowed;
            }
          }
        }
      }
    }

    .pdi-controls-container {
      border-top: 1px solid $new-ui-border;
      ul {
        padding: 10px 30px;
        margin-bottom: 0;
        text-align: right;
        li {
          margin-left: 30px;
          a {
            color: $main-nav;
            text-decoration: none;
            &.incompleted {
              opacity: 0.3;
              &:hover {
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }
  }

  .search-user-view, .buyer-profile-found, .alert-content-container {
    padding: 50px;
    margin-bottom: 50px;
    border: 1px solid $fixed;
  }

  .btn-container {
    display: flex;
    button {
      width: 15%;
      height: 60px;
      background: none;
      color: $eoi;
      border: 1px solid $fixed;
      &:hover {
        cursor: pointer;
      }
      &.selected {
        .fa {
          top: 50%;
          right: 15px;
          position: absolute;
          transform: translateY(-50%);
        }
        position: relative;
        background: $eoi;
        color: white;
        border: 0;
      }
      &.unit {
        .fa {
          top: 50%;
          right: 15px;
          position: absolute;
          transform: translateY(-50%);
        }
        position: relative;
      }
    }
  }

  .unit-selection {
    padding: 30px 0;
    position: relative;
    border-top: 1px solid $fixed;
    border-bottom: 1px solid $fixed;
    .prev, .next {
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      &:hover {
        cursor: pointer;
      }
    }
    .prev {
      left: -30px;
    }
    .next {
      right: -30px;
    }
    .unit-container {
      display: flex;
      .unit {
        width: 33.33%;
        padding-top: 15px;
        text-align: center;
        box-sizing: border-box;
        border: 1px solid $fixed;
        position: relative;
        .fa-times {
          top: 20px;
          right: 20px;
          position: absolute;
        }
        &.error {
          border: 1px solid red;
        }
        .select-container {
          padding: 30px;
          min-height: 200px;
          .nice-select {
            margin-bottom: 10px;
          }
        }

        .unit-block {
          border-top: 1px solid $fixed;
          padding: 20px 0 0 20px;
        }

        .unit-image {
          border-top: 1px solid $fixed;
          border-bottom: 1px solid $fixed;
        }

        .unit-info {
          text-align: left;
          padding: 20px 0 0 20px;
          border-bottom: 1px solid $fixed;
        }

        .unit-size {
          display: flex;
          border-bottom: 1px solid $fixed;
          .size-block {
            width: 50%;
            padding: 20px;
            text-align: left;
            box-sizing: border-box;
            p {
              margin-bottom: 0;
            }
          }
        }

        .unit-facilities {
          display: flex;
          .facility {
            width: 33.33%;
            padding: 20px;
            text-align: left;
            box-sizing: border-box;
            p {
              margin-bottom: 0;
            }
          }
        }

        &.additional {
          border-left: 0;
          border-top: 1px dotted $fixed;
          border-right: 1px dotted $fixed;
          border-bottom: 1px dotted $fixed;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .eoi-confirmation-container {
    padding: 100px;
    border: 1px solid $fixed;
    &.text-scroll {
      height: 450px;
      overflow-y: scroll;
    }
  }

  .btn-primary {
    &.with-icon {
      min-width: 100%;
    }
  }

  .btn {
    &.disabled {
      cursor: not-allowed;
    }
  }

  #personPurchaseDetails {
    input[type=text] {
      text-transform: uppercase;
    }
  }
}

.buyer-profile {
  padding: 40px;
  border-radius: 4px;
  border: 1px solid $fixed;
  p {
    i {
      font-size: 20px;
    }
    &.updated {
      color: black;
      font-weight: bold;
      i {
        color: green;
      }
    }
  }
  small {
    bottom: 0;
    position: absolute;
    a {
      color: $dark-grey;
      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
        text-decoration: none;
      }
    }
  }
  .buyer-profile-icon {
    width: 150px;
    height: 150px;
    border-radius: 4px;
    border: 1px solid $fixed;
    text-align: center;
    i {
      line-height: 150px;
      &.icon-single, &.icon-buyer {
        font-size: 50px;
      }
    }
  }
  &.add-profile {
    border-radius: 0;
    border: 1px dashed $fixed;
    .buyer-profile-icon {
      border: 0;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.document-photos-container {
  display: flex;
  &.disabled{
    opacity: 0.5;
    cursor: not-allowed;
  }
  .review-img{
    margin-bottom: 90px;
  }
  .document-container {
    margin-right: 20px;
    &.final {
      .document-photo {
        padding: 0;
        border: 0;
        img {
          height: 100%;
        }
      }
    }
    .document-photo {
      width: 250px;
      height: 125px;
      padding: 20px;
      padding-top: 20px;
      border-radius: 4px;
      text-align: center;
      border: 1px dashed $fixed;
      margin-right: 20px;
      position: relative;
      i {
        font-size: 40px;
      }
      .overlay {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 10px;
        position: absolute;
        border-radius: 4px;
        background-color: rgba(0,0,0,0.3);
        a {
          i {
            font-size: 12px;
          }

          background: white;
          color: black;
          font-size: 12px;
          padding: 2px 4px;
          text-decoration: none;
        }
      }
    }
    p {
      font-size: 14px;
      padding-top: 5px;
      span {
        color: red;
      }
    }
    
    .review{
      i {
        font-size: 22px;
      }
      a{
        text-decoration: none;
        margin-right: 10px;
      }
    }

  }
}

@media only screen and (max-width: 768px) {
  .eoi {
    .justify-content-center {
      .btn {
        margin-bottom: 10px;
      }
    }
    .pdi-letter-container {
      max-width: 100%;
      .pdi-controls-container {
        position: relative;
        ul {
          padding: 20px 10px;
          display: flex;
          li {
            flex: 1 calc(100% / 3);
            text-align: center;
            margin-left: 0;
          }
        }
      }
    }
    .eoi-confirmation-container {
      padding: 30px;
    }
  }
}

@media only screen and (max-width: 640px) {
  .eoi {
    .btn-container {
      button {
        width: 50%;
      }
    }
    .unit-selection {
      .unit-container {
        .unit {
          width: 50%;
          .unit-facilities {
            flex-direction: column;
            .facility {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .document-photos-container {
    .document-container {
      .document-photo {
        height: auto;
      }
    }
  }
  
}

@for $i from 1 through 12 {
    .col-print-#{$i} {
        width: #{percentage(round($i*8.33)/100)};
        float: left;
    }
}

@media print {
  .hidden-print {
    visibility: hidden;
  }
}

#document_signing_container {
  -webkit-overflow-scrolling: touch!important;
  overflow-y: scroll!important;
  height: 100%;
  position: absolute;
}

/** Entity Card **/
.entity-card {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid $fixed;

  &:not(:last-child){
    margin-bottom: 1.5rem;
  }

  &.signatory-card{
    margin-left: 1rem;
  }

  .entity-card-body{
    gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .entity-info{
      row-gap: 0.5rem;
      gap: 1rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .entity-info-name{
        display: flex;
        flex-direction: column;
      }
    }

    .btn-control{
      padding: 0.25rem 0;
      color: #8B8D8F;
      font-size: 14px;
      font-weight: bold;
      background: none;

      &:hover{
        background: #FFFFFF;
        filter: brightness(0.8);
      }
    }
  }

  p {
    i {
      font-size: 20px;
    }
    &.updated {
      color: black;
      font-weight: bold;
      i {
        color: green;
      }
    }
  }

  .entity-icon {
    display: inline-block;
    border-radius: 0.5rem;
    border: 1px solid $fixed;
    text-align: center;
    background: #F0F0F0;
    padding: 2rem;
    i {
      line-height: 2rem;

      &.icon-single, &.icon-buyer, &.icon-heatmap, &.fa-child, &.fa-users {
        font-size: 2rem;
        min-width: 2rem;
      }
    }
  }

  &.add-entity {
    border: 1px dashed $fixed;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    background: #F0F0F0;

    &:not(.disabled):hover {
      cursor: pointer;
      background: #DDD;
    }

    &.disabled {
      background: #F9F9F9;
      cursor: not-allowed;
    }

    .entity-icon {
      border: none;
    }
    .entity-info-name{
      font-weight: 600;
    }
  }
}
@media (min-width: 768px) {
  .entity-card {
    /*padding: 1.5rem;*/

    .entity-card-body{
      flex-direction: row;
      align-items: center;
    }

    .entity-icon {
      padding: 2rem;
      i {
        line-height: 3rem;
        &.icon-single, &.icon-buyer, &.icon-heatmap, &.fa-child, &.fa-users {
          font-size: 3rem;
          min-width: 3rem;
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .entity-card {
    /*padding: 2rem;*/
    padding: 1rem;

    &:not(:last-child){
      margin-bottom: 2rem;
    }
    &.add-entity {
      margin-top: 2rem;
      margin-bottom: 3rem !important;
    }
  }
}
