.card {
  .card-header {
    position: relative;
    background-color: white;  

    .applications {
      top: 5px;
      right: 20px;
      width: 40px;
      height: 40px;
      padding: 10px;
      color: white;
      font-size: 14px;
      position: absolute;
      border-radius: 50%;
      background-color: dodgerblue;
    }
  }
  .card-block {
    &.border-top {
      border-top: 1px solid $fixed;
    }
    a {
      color: $medium-blue;
      text-decoration: none;
      i {
        font-size: 20px;
      }

      &.disabled {
        cursor: not-allowed;
      }
    }
    .mr-1{
      a {
        margin-right: 10px;
      }
    }
  }

  .ml-3{
    margin-left: 30px;
  }

  &.sales {
    &:hover {
      cursor: pointer;
    }
    .card-block {
      .icon {
        font-size: 120px;
      }
      text-align: center;
    }
    &.selected {
      border: 2px solid $dark-grey;
      .card-header {
        border-bottom: 2px solid $dark-grey; 
      }
    }
  }
}

.agent-container {
  display: flex;
  margin-bottom: 50px;
  &.selected {
    .agent-photo {
      img {
        border: 1px solid $nav-link-active;
      }
    }
  }
  .agent-photo, .agent-info {
    flex: 1 40%;
    padding-right: 10%;
  }
  .agent-info {
    padding-left: 10px;
    .btn {
      border-radius: 4px;
    }
  }
}

.card-hover{
  border:1px solid $nav-link-active;
  background-color: #e5f1f9;


}