.floor-plan, .unit-specification {
  p {
    margin-bottom: 5px;
  }
}

.calculators {
  margin-top: 100px;
  border-top: 1px solid $fixed;
  padding: 100px;

  .calculator-tabs {
    margin: 0;
    padding: 0;
    float: left;
    list-style: none;
    height: 32px;
    width: 100%;
  }
  
  .calculator-tabs li {
    float: left;
    margin: 0;
    display: flex;
    cursor: pointer;
    padding: 20px 10px;
    align-items: center;
    background-color: white;
    border: 1px solid $border-grey;
    color: $main-nav;
    overflow: hidden;
    position: relative;
    line-height: 20px;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    .icon-container {
      padding-right: 10px;
      .icon {
        font-size: 40px;
      }
    }
  }
  
  .calculator-tabs li.active {
    box-shadow: 0px 15px 10px -15px $dark-grey;
    background-color: darken($dark-grey, 30%);
    color: white;
  }
  
  .calculators_tab_container {
    float: left;
    width: 100%;
    clear: both;
    overflow: auto;
    border-top: none;
    margin-top: 100px;
    background: white;
    .tab_content {
      border-top: 0;
      border-bottom: 0; 
      .mortgage-calc {
        input[type="text"] {
          border-top: 0;
          border-left: 0;
          border-right: 0;
          max-width: 50%;
          &::placeholder {
            opacity: 0.2;
          }
          &:focus {
            border-bottom-color: black;
          }
        }
      }
      .range-slider {
        -webkit-appearance: none;
        width: 70%;
        height: 16px;
        background: white;
        border-radius: 10px;
        border: 1px solid $dark-grey;
        outline: 0;
      }
      
      .range-slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        background: white;
        cursor: pointer;
        border: 5px solid $dark-grey;
        border-radius: 50%;
      }

      .range-slider::-moz-range-thumb {
        border: 5px solid $dark-grey;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: white;
        cursor: pointer;
      }
      
      .range-slider::-moz-range-track {
        width: 0%;
        height: 16px;
        background: white;
        border-radius: 10px;
        outline: 0;
      }
     
    }
  }

  .tab_content {
    padding: 20px;
    display: none;
  }
}

.repayment-results {
  display: flex;
  border-top: 1px solid $border-grey;
  border-bottom: 1px solid $border-grey;
  .block {
    flex: 1 33%;
    padding: 10px;
    border-right: 1px solid $border-grey;
    &:last-child {
      border-right: 0;
    }
    small {
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .calculators {
    padding: 25px;
    .calculators_tab_container {
      .tab_content {
        .range-slider {
          width: 100%;
        }
      } 
    } 
    .tab_content {
      padding: 0;
    }
  }
}


