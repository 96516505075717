.advanced-search-tabs {
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    padding: 10px 0;
    list-style-type: none;
    border-top: 1px solid $text-grey;
    border-bottom: 1px solid $text-grey;
    li {
      width: 10%;
      text-align: center;
      a {
        color: $warm-grey;
        text-decoration: none;
      }
      &.selected {
        a {
          font-weight: bold;
          color: $greyish-brown;
        }
      }
    }
  }
  .advanced-search-tabs-content {
    padding: 30px 0;
  }
}

.flexbox-container {
  display: flex;
  //flex-wrap: wrap;
  .flexbox-child {
    flex: 1 20%;
  }
}

.search-result-wrapper {
  margin-bottom: 40px;
  border: 1px solid $fixed;
  .search-result {
    display: flex;
    .search-result-thumb {
      position: relative;
      flex: 1 30%;
      margin: 0;
      .units-available {
        top: 10px;
        right: 10px;
        width: 80px;
        height: 80px;
        color: $navy;
        display: table;
        font-weight: bold;
        position: absolute;
        border-radius: 50%;
        text-align: center;
        background-color: white;
        span {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
    .search-result-content {
      flex: 1 70%;
      padding: 10px 20px;
      .top-row {
        display: flex;
        .search-result-logo {
          flex: 1 120px;
          padding: 10px;
          border: 1px solid $fixed;
        }
        .search-result-project-name {
          padding: 0 15px;
          flex: 1 calc(100% - 120px);
          p {
            margin-bottom: -4px;
          }
        }
      }
      .bottom-row {
        display: flex;
        .units {
          width: 160px;
        }
        .average-psf {
          display: flex;
          padding-left: 100px;
          align-items: flex-end;
          flex: 1 calc(100% - 160px);
          justify-content: space-between;
        }
      }
    }
  }
  .search-result-matching-criteria {
    color: white;
    background-color: $btn-primary-blue;
    a {
      color: white;
      font-weight: bold;
      padding-top: 10px;
      padding-right: 10px;
      text-decoration: underline;
    }
    p {
      padding-top: 10px;
    }
  }
}