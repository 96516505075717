.compare {
  .container {
    &.with-border {
      border: 1px solid $fixed;
    }
    p {
      margin-bottom: 0;
    }
  }
  .btn {
    width: 150px;
    margin-top: 20px;
  }
  p {
    &.heading {
      padding: 10px;
      border-top: 1px solid $fixed;
      border-bottom: 1px solid $fixed;
    }
    strong {
      font-size: 18px;
    }
  }
  a {
    &.view-more {
      color: $main-nav;
    }
  }
  .compare-project {
    padding: 20px;
    font-size: 14px;
    border-right: 1px solid $fixed;
    border-bottom: 1px solid $fixed;
    &.text-center {
      p {
        margin-bottom: 0;
      }
    }
    .nice-select {
      position: relative;
      padding-left: 40px;
      margin-bottom: 20px;
      border-radius: 0;
      width: 100%;
      &.field-remove {
        &:before {
          font-family: "showsuite";
          position: absolute;
          font-size: 20px;
          content: "\e009";
          left: 10px;
        }
      }
      &:after {
        border: 0;
        content: "\f107";
        margin-top: -18px;
        transform: rotate(0);
        font-family: "FontAwesome";
      }
      .list {
        width: 100%;
        border-radius: 0;
      }
    }
    .ghost-container {
      width: 100%;
      font-size: 14px;
      padding: 9px 30px;
      position: relative;
      border: 1px dashed $fixed;
      &:hover {
        cursor: pointer;
      }
      &:before {
        font-family: "FontAwesome";
        position: absolute;
        content: "\f067";
        left: 10px;
      }
    }
    .unit {
      p {
        width: 60%;
        margin-bottom: 0;
        border-bottom: 1px solid $fixed;
        span {
          float: right;
        }
      }
    }
    .facilities {
      p {
        margin-bottom: 10px;
      }
      .hidden {
        display: none;
        &.open {
          display: block;
        }
      }
    }
  }
}