.stack-view {
  margin-top: 30px;

  .btn-group {
    button {
      width: 100px;
      padding: 10px;
      background: none;
      color: $border-grey;
      border: 1px solid $fixed;
      &.selected {
        color: white;
        border: none;
        background-color: $dark-grey;
      }
      &:hover {
        cursor: pointer;
      }
      &.disabled {
        cursor:not-allowed;
      }
    }
  }
  
  .last-updated {
    color: $red;
    font-size: 14px;
  }

  .nice-select {
    width: 100%;
    border-radius: 0;
    
    .list {
      width: 100%;
      border-radius: 0;
    }
  }

  .row {
    &.with-border {
      padding-top: 10px;
      box-sizing: border-box;
      border-top: 1px solid $fixed;
      p {
        padding-bottom: 10px;
        margin-bottom: 0;
      }
    }
  }
  
  label {
    display: block;
    font-size: 12px;
    margin-top: 5px;
    text-align: center;
  }

  .button-container {
    width: 95px;
    text-align: center;
    display: inline-block;

    button {
      min-width: 55px;
      max-width: 75px;
      min-height: 55px;
      max-height: 75px;
      display: block;
      font-size: 12px;
      font-weight: bold;
      margin: 0 auto 10px;
      padding: 0.375rem;

      word-wrap: break-word; /* IE */
      &:hover {
        cursor: pointer;
      }
    }

    &.bigger {
      width: 105px;
    }
  }

  .scroll {
    max-width: 1200px;
    padding: 0 10px 35px 15px;
    overflow-x: auto;
    overflow-y: hidden;
    
    .buttons-row {
      padding: 0;
      display: flex;
      gap: 0.25rem;

      .stackview {
        margin: 0;
        padding: 5px;
        height: 42px;
        width: 100%;
        max-width: 95px;
        flex-shrink: 0;
        flex-basis: 95px;
        border-radius: 0;
        /*margin-right: 1px;
        margin-bottom: 1px;*/
        &.selected {
          border: 2px solid #F5F500 !important;
        }

        word-wrap: break-word;
        white-space: normal;

        .stack-cell-info {
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: normal;
          white-space: nowrap;
        }
      }
    }
  }

  .btn-group {
    display: flex;

    .stackview {
      margin-right: 10px;
    }
  }
}

.table-pagination {
  width: 100%;
  padding-top: 15px;
  margin-bottom: 5rem;
  border-top: 1px solid $fixed;
  border-bottom: 1px solid $fixed;
  ul {
    list-style: none;
    font-size: 14px;
    padding-left: 0;
    li {
      padding-left: 10px;
      display: inline-block;
      a {
        color: $border-grey;
        text-decoration: none;
      }
    }
    &.text-right {
      li {
        &:after {
          content: "/";
          padding: 0 5px;
        }
        &:last-child {
          &:after {
            content: "";
            padding: 0;
          }
        }
      }
    }
  }
}

.available-units {
  margin-top: 10px;

  .col-md-12 {
    padding: 0;
    color: $border-grey;
    .pull-left {
      padding-top: 3px;
      padding-left: 10px;
      box-sizing: border-box;
    }
  }

  table {
    margin-top: 20px;
    th, td {
      border-top: 0;
    }
  }

  .previous{
      padding-right: 15px;
  }

  .next{
      padding-left: 15px;
  }
  .selected-page{
    font-weight: bold;
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .stack-view {
    padding-right: 15px;
    .list-unstyled {
      li {
        display: inline;
      }
    }
  }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
  .stack-view .scroll {
    width: 1000px;
  }
}

@media only screen and (max-width: 640px) {
  .stack-view {
    margin-top: 0;
    .button-container {
      width: 65px;
      label {
        font-size: 10px;
      }
      button {
        width: 40px;
        height: 40px;
        font-size: 10px;
        border-radius: 10px;
      }
    }
  }

  .available-units {
    margin-top: 20px;
  
    table {
      font-size: 9px;
    }
  }

  .nice-select {
    margin-top: 20px;
  }
}
