@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/0.8.2/css/flag-icon.min.css');


@font-face {
  font-family: 'lg';
  src: url("/fonts/lg.eot?n1z373");
  src: url("/fonts/lg.eot?#iefixn1z373") format("embedded-opentype"), 
  url("/fonts/lg.woff?n1z373") format("woff"), 
  url("/fonts/lg.ttf?n1z373") format("truetype"), url("/fonts/lg.svg?n1z373#lg") format("svg");
  font-weight: normal;
  font-style: normal;
}

// Showsuite custom icon font

@font-face {
  font-family: "Showsuite";
  src:url("/fonts/showsuite.eot");
  src:url("/fonts/showsuite.eot?#iefix") format("embedded-opentype"),
    url("/fonts/showsuite.woff") format("woff"),
    url("/fonts/showsuite.ttf") format("truetype"),
    url("/fonts/showsuite.svg#showsuite") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "showsuite" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "showsuite" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-360-photo:before {
  content: "\61";
}
.icon-3d-vr:before {
  content: "\63";
}
.icon-view-list-button:before {
  content: "\64";
}
.icon-tenure:before {
  content: "\65";
}
.icon-map-view:before {
  content: "\66";
}
.icon-aerial-view:before {
  content: "\67";
}
.icon-category:before {
  content: "\68";
}
.icon-facebook:before {
  content: "\69";
}
.icon-favorite:before {
  content: "\6a";
}
.icon-google-plus:before {
  content: "\6b";
}
.icon-guide:before {
  content: "\6c";
}
.icon-price-psf:before {
  content: "\70";
}
.icon-share-copy:before {
  content: "\71";
}
.icon-play:before {
  content: "\62";
}
.icon-interview:before {
  content: "\72";
}
.icon-video:before {
  content: "\73";
}
.icon-exit:before {
  content: "\6d";
}
.icon-affordability:before {
  content: "\6e";
}
.icon-aquasport:before {
  content: "\74";
}
.icon-arrivallounge:before {
  content: "\75";
}
.icon-balcony:before {
  content: "\76";
}
.icon-barlounge:before {
  content: "\77";
}
.icon-bathroom:before {
  content: "\78";
}
.icon-bedroom:before {
  content: "\79";
}
.icon-bicycle-bay:before {
  content: "\7a";
}
.icon-communal-deck:before {
  content: "\41";
}
.icon-cocktail-03:before {
  content: "\42";
}
.icon-cocktail-02:before {
  content: "\43";
}
.icon-cocktail-01:before {
  content: "\44";
}
.icon-childplaycorner:before {
  content: "\45";
}
.icon-childcare:before {
  content: "\46";
}
.icon-chess:before {
  content: "\47";
}
.icon-changingroom:before {
  content: "\48";
}
.icon-car:before {
  content: "\49";
}
.icon-bus:before {
  content: "\4b";
}
.icon-bin-center:before {
  content: "\4c";
}
.icon-billard:before {
  content: "\4d";
}
.icon-developer:before {
  content: "\4f";
}
.icon-dining-01:before {
  content: "\50";
}
.icon-dining-02:before {
  content: "\51";
}
.icon-currency-01:before {
  content: "\4e";
}
.icon-dropoffarea:before {
  content: "\52";
}
.icon-entrance:before {
  content: "\53";
}
.icon-facilities-01:before {
  content: "\54";
}
.icon-fern-garden:before {
  content: "\55";
}
.icon-fullscreen:before {
  content: "\56";
}
.icon-gameroom:before {
  content: "\57";
}
.icon-language-01:before {
  content: "\58";
}
.icon-jacuzzi:before {
  content: "\59";
}
.icon-icons-map:before {
  content: "\5a";
}
.icon-hammock:before {
  content: "\30";
}
.icon-gym:before {
  content: "\31";
}
.icon-guide-01:before {
  content: "\32";
}
.icon-grill:before {
  content: "\33";
}
.icon-garden-zen:before {
  content: "\34";
}
.icon-garden-scent:before {
  content: "\35";
}
.icon-garden-01:before {
  content: "\36";
}
.icon-garden-02:before {
  content: "\37";
}
.icon-lap-pool:before {
  content: "\38";
}
.icon-laundrette:before {
  content: "\39";
}
.icon-laundrette-01:before {
  content: "\21";
}
.icon-lawn:before {
  content: "\22";
}
.icon-lobby:before {
  content: "\23";
}
.icon-lobby-lounge:before {
  content: "\24";
}
.icon-location:before {
  content: "\25";
}
.icon-meetingroom:before {
  content: "\26";
}
.icon-mortgage:before {
  content: "\27";
}
.icon-mrt:before {
  content: "\28";
}
.icon-office:before {
  content: "\29";
}
.icon-otherpool-01:before {
  content: "\2a";
}
.icon-restroom:before {
  content: "\2b";
}
.icon-residentlounge:before {
  content: "\2c";
}
.icon-reflexology:before {
  content: "\2d";
}
.icon-recreation:before {
  content: "\2e";
}
.icon-reading:before {
  content: "\2f";
}
.icon-rainshower:before {
  content: "\3a";
}
.icon-pool-01:before {
  content: "\3b";
}
.icon-playground:before {
  content: "\3d";
}
.icon-place-of-interest:before {
  content: "\3c";
}
.icon-overview:before {
  content: "\3f";
}
.icon-school:before {
  content: "\40";
}
.icon-security-guard:before {
  content: "\5b";
}
.icon-shop:before {
  content: "\5f";
}
.icon-sitemap:before {
  content: "\60";
}
.icon-spa:before {
  content: "\7b";
}
.icon-steamroom:before {
  content: "\7c";
}
.icon-substation:before {
  content: "\7d";
}
.icon-sundeck:before {
  content: "\7e";
}
.icon-tealounge:before {
  content: "\5c";
}
.icon-unit:before {
  content: "\e000";
}
.icon-video-category:before {
  content: "\e001";
}
.icon-viewing:before {
  content: "\e002";
}
.icon-workout:before {
  content: "\e003";
}
.icon-yoga:before {
  content: "\e004";
}
.icon-shelter-01:before {
  content: "\5e";
}
.icon-enquire-01:before {
  content: "\e005";
}
.icon-benches:before {
  content: "\e006";
}
.icon-faq-help:before {
  content: "\e007";
}
.icon-newsroom:before {
  content: "\e008";
}
.icon-checkout:before {
  content: "\e00f";
}
.icon-marketing-manager:before {
  content: "\e00b";
}
.icon-data-scientist:before {
  content: "\e00d";
}
.icon-chief-financial-officer:before {
  content: "\e00e";
}
.icon-backend-developer:before {
  content: "\e012";
}
.icon-minus:before {
  content: "\e009";
}
.icon-feature:before {
  content: "\e010";
}
.icon-eoi-01:before {
  content: "\e015";
}
.icon-unit-specification:before {
  content: "\e016";
}
.icon-built-in-wardrobe:before {
  content: "\e018";
}
.icon-approved-door:before {
  content: "\e019";
}
.icon-built-in-oven:before {
  content: "\e01a";
}
.icon-designer:before {
  content: "\e00c";
}
.icon-audio-video-intercom:before {
  content: "\e01b";
}
.icon-fridge:before {
  content: "\e01c";
}
.icon-hood:before {
  content: "\e01d";
}
.icon-town-gas:before {
  content: "\e01e";
}
.icon-washer-dryer:before {
  content: "\e01f";
}
.icon-cooker-hob:before {
  content: "\e020";
}
.icon-kitchen-cabinet:before {
  content: "\e021";
}
.icon-kitchen:before {
  content: "\e022";
}
.icon-hot-water-supply:before {
  content: "\e023";
}
.icon-stainless-tap:before {
  content: "\e024";
}
.icon-3d-floor-plan:before {
  content: "\e025";
}
.icon-single:before {
  content: "\e026";
}
.icon-joint:before {
  content: "\e027";
}
.icon-book-now:before {
  content: "\e028";
}
.icon-3d-floor-plan-1:before {
  content: "\e029";
}
.icon-360:before {
  content: "\e02a";
}
.icon-3d:before {
  content: "\e02b";
}
.icon-unit-3d:before {
  content: "\e02c";
}
.icon-download:before {
  content: "\e02d";
}
.icon-download-1:before {
  content: "\e02e";
}
.icon-drone:before {
  content: "\e02f";
}
.icon-leads:before {
  content: "\e031";
}
.icon-live-stack-view:before {
  content: "\e032";
}
.icon-star-2:before {
  content: "\e033";
}
.icon-view-units:before {
  content: "\e034";
}
.icon-share:before {
  content: "\e035";
}
.icon-vr-icon:before {
  content: "\e036";
}
.icon-booking-engine:before {
  content: "\e037";
}
.icon-print:before {
  content: "\e038";
}
.icon-save:before {
  content: "\e039";
}
.icon-image:before {
  content: "\e03a";
}
.icon-view:before {
  content: "\e03c";
}
.icon-close:before {
  content: "\e03d";
}
.icon-notifications-button:before {
  content: "\e03e";
}
.icon-heatmap:before {
  content: "\e03f";
}
.icon-condo:before {
  content: "\e040";
}
.icon-keyplan:before {
  content: "\e041";
}
.icon-landed:before {
  content: "\e042";
}
.icon-view-units-1:before {
  content: "\e043";
}
.icon-update-status:before {
  content: "\e044";
}
.icon-sales:before {
  content: "\e045";
}
.icon-offers:before {
  content: "\e047";
}
.icon-mortgage-calculator:before {
  content: "\e048";
}
.icon-ec:before {
  content: "\e04b";
}
.icon-contract:before {
  content: "\e04c";
}
.icon-agency:before {
  content: "\e04d";
}
.icon-document:before {
  content: "\e04e";
}
.icon-accept:before {
  content: "\e046";
}
.icon-reject:before {
  content: "\e04f";
}
.icon-floorplan:before {
  content: "\e050";
}
.icon-upload-image:before {
  content: "\e051";
}
.icon-information:before {
  content: "\e052";
}
.icon-user-01:before {
  content: "\e011";
}
.icon-profile:before {
  content: "\e053";
}
.icon-admin:before {
  content: "\e054";
}
.icon-marketing:before {
  content: "\e055";
}
.icon-analytics-1:before {
  content: "\e056";
}
.icon-tenure-1:before {
  content: "\e058";
}
.icon-projects:before {
  content: "\e059";
}
.icon-sales-1:before {
  content: "\e05a";
}
.icon-application:before {
  content: "\e05b";
}
.icon-live-stack-view-1:before {
  content: "\e05c";
}
.icon-petcorner:before {
  content: "\3e";
}
.icon-payment-method:before {
  content: "\e05d";
}
.icon-counter-booking:before {
  content: "\e05e";
}
.icon-registration-balloting:before {
  content: "\e05f";
}
.icon-promo-code:before {
  content: "\e060";
}
.icon-booking:before {
  content: "\e061";
}
.icon-call:before {
  content: "\4a";
}
.icon-buyer:before {
  content: "\e062";
}
.icon-playlist:before {
  content: "\e014";
}
.icon-general-video:before {
  content: "\e063";
}
.icon-email:before {
  content: "\e064";
}
.icon-portfolio:before {
  content: "\e065";
}
.icon-awards:before {
  content: "\e066";
}
.icon-cea-no:before {
  content: "\e067";
}
.icon-website:before {
  content: "\e068";
}
.icon-reviews:before {
  content: "\e069";
}
.icon-company-profile:before {
  content: "\e06a";
}
.icon-mobile-developer:before {
  content: "\e06b";
}
.icon-agency-1:before {
  content: "\e06c";
}
.icon-dashboard:before {
  content: "\e04a";
}
.icon-invites:before {
  content: "\e06d";
}
.icon-home:before {
  content: "\e06e";
}
.icon-eoi-1:before {
  content: "\e06f";
}
.icon-projects-1:before {
  content: "\e057";
}
.icon-sales-2:before {
  content: "\e070";
}
.icon-leads-1:before {
  content: "\e071";
}
.icon-notification:before {
  content: "\e072";
}
.icon-settings-1:before {
  content: "\e073";
}
.icon-project-info:before {
  content: "\5d";
}
.icon-project-setting:before {
  content: "\e074";
}
.icon-project-assets:before {
  content: "\e076";
}
.icon-upload:before {
  content: "\e075";
}
.icon-filter:before {
  content: "\e077";
}
.icon-list-view-02:before {
  content: "\e078";
}
.icon-list-view-03:before {
  content: "\e079";
}
.icon-list-view-01:before {
  content: "\e07a";
}
.icon-grid-view-01:before {
  content: "\e07b";
}
.icon-more:before {
  content: "\e013";
}
.icon-calendar:before {
  content: "\e07c";
}
.icon-plus:before {
  content: "\e07e";
}
.icon-api-configuration:before {
  content: "\e07f";
}
.icon-instagram-01:before {
  content: "\e080";
}
.icon-facebook-01:before {
  content: "\e081";
}
.icon-youtube-01:before {
  content: "\e082";
}
.icon-menu:before {
  content: "\e083";
}
.icon-menu-selected:before {
  content: "\e084";
}
.icon-linkedin-01:before {
  content: "\e085";
}
.icon-favourite-0:before {
  content: "\e086";
}
.icon-feedback:before {
  content: "\e088";
}
.icon-log-out:before {
  content: "\e08a";
}
.icon-saved-search:before {
  content: "\e08b";
}
.icon-account:before {
  content: "\e08c";
}
.icon-contact:before {
  content: "\e08d";
}
.icon-about-us:before {
  content: "\e08f";
}
.icon-video-01:before {
  content: "\e090";
}
.icon-tools:before {
  content: "\e091";
}
.icon-news:before {
  content: "\e092";
}
.icon-add-01:before {
  content: "\e089";
}
.icon-remark:before {
  content: "\e08e";
}
.icon-buyer-1:before {
  content: "\e093";
}
.icon-vector-report:before {
  content: "\e094";
}
.icon-edit-1:before {
  content: "\e07d";
}
.icon-search:before {
  content: "\e095";
}
.icon-download-2:before {
  content: "\e096";
}
.icon-edit:before {
  content: "\e097";
}
.icon-check:before {
  content: "\e030";
}
.icon-1teamwork:before {
  content: "\6f";
}
.icon-3d-modeller-01:before {
  content: "\e00a";
}
.icon-03-agent-01:before {
  content: "\e017";
}
.icon-33409:before {
  content: "\e03b";
}
.icon-abort:before {
  content: "\e049";
}
.icon-analytics:before {
  content: "\e087";
}
.icon-air-con:before {
  content: "\e098";
}
.icon-agent-01:before {
  content: "\e099";
}
.icon-propeller:before {
  content: "\e09a";
}
.icon-propeller-2:before {
  content: "\e09b";
}
.icon-unit-specification-1:before {
  content: "\e09c";
}
.icon-share-copy-1:before {
  content: "\e09d";
}
.icon-view-list-button-1:before {
  content: "\e09e";
}
.icon-price-psf-1:before {
  content: "\e09f";
}
.icon-initiate-new-phase:before {
  content: "\e0a0";
}
.icon-help:before {
  content: "\e0a1";
}
.icon-icons-view:before {
  content: "\e0a2";
}
.icon-google-plus-1:before {
  content: "\e0a3";
}
.icon-facebook-logo:before {
  content: "\e0a4";
}
.icon-favorite-1:before {
  content: "\e0a5";
}
.icon-edit-2:before {
  content: "\e0a6";
}
.icon-aerial-view-1:before {
  content: "\e0a7";
}
.icon-add-to-compare:before {
  content: "\e0a8";
}
