.table-dropdown-container {
  position: relative;
  background-color: white;
  &:hover {
    cursor: pointer;
    .table-dropdown {
      display: block;
    }
  }
  .table-dropdown {
    top: 10px;
    right: 30px;
    width: 100px;
    display: none;
    z-index: 100;
    position: absolute;
    background-color: white;
    border: 1px solid $dark-grey;
    a {
      padding: 5px;
      display: block;
      text-align: left;
      color: $dark-grey;
      text-decoration: none;
      border-bottom: 1px solid $dark-grey;
      &:last-child {
        border: 0;
      }
    }
  }
}

td.ellipsis {
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}