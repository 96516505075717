.video-tabs {
	margin: 0;
	padding: 0;
  float: left;
  width: 100%;
	list-style: none;
    margin-left: 20px;

  li {
    margin: 0;
    width: auto;
    float: left;
    cursor: pointer;
    padding: 10px 21px;
    text-align: center;
    font-weight: bold;
    color: $main-nav;
    border-bottom: 3px solid $tabs-grey;
    overflow: hidden;
    position: relative;
    &.active {
      color: $navy;
      display: block;
      border-bottom: 3px solid $navy;
    }
  }
}

.tab_container {
	width: 100%;
  overflow: auto;
}

.tab_content {
  border-bottom: 1px solid $tabs-grey;
  border-top: 1px solid $tabs-grey;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.tab_drawer_heading { display: none; }

.video-block {
  width: 25%;
  padding: 25px;

  p {
    padding: 20px 0;
  }

  .btn {
    min-width: 60%;
  }

    &.home-video-block {
      padding: 0;
      color: $main-nav;
      .home-video-block-img {
        position: relative;
        i {
          top: 50%;
          left: 50%;
          color: white;
          font-size: 50px;
          position: absolute;
          transform: translate(-50%, -50%);
        }
      }
      .home-video-block-content {
        p {
          padding: 0;
          &:last-child {
            margin-top: -15px;
          }
          &.title {
            width: 80%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
}

// Videos Page Video Container

.videos-container {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding-bottom: 20px;

  .videos-block {
    width: 340px;
    margin-right: 15px;
    white-space: normal;
    display: inline-block;
    box-sizing: border-box;
    .video-block-img {
      height: 220px;
      img {
        height: 100%;
      }
    }
    .video-block-content {
      font-size: 16px;
      p {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .video-block {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .video-block {
    h4 {
      font-size: 20px;
    }

    .btn {
      width: 85%;
    }

    &:nth-of-type(3),&:nth-of-type(4) {
      display: none;
    }

    &.home-video-block {
      .home-video-block-content {
        padding-left: 20px;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .video-block {
    width: 100%;
  }

  .video-tabs li {
    font-size: 15px;
    padding: 10px 15px;
  }
}