.advanced-search {
  padding: 0 40px;
  .row {
    padding: 0 20px;
  }
  .nav-tabs {
    border: none;
    padding-top: 20px;

    .nav-link {
      border: 0;
      padding: 0;
      margin-right: 15px;
      color: $tabbed-nav-inactive;
      border-bottom: 1px solid $tabbed-nav-inactive;
      &.active {
        color: black;
      }
    }
  }

  .tab-content {
    padding: 40px 0;

    form {
      p {
        padding-left: 15px;
      }
      &.developers {
        height: 500px;
        margin-top: 30px;
        overflow-y: scroll;
        overflow-x: hidden;

        .col-md-4.dev-column {
          min-height: 400px;
          margin-bottom: 20px;
        }
      }
    }
    .nav {
      &.check-uncheck {
        .nav-link {
          color: $tabbed-nav-inactive;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    /* Style the tab */

    $colors-list: $ew $ns $ne $cc $dt $te $bp $sk $pg;
    @each $current-color in $colors-list {
        $i: index($colors-list, $current-color);
        .district-#{$i} {
            background-color: $current-color;
        }
    }
    .custom-tab {
      width: 15%;
      // float: left;
      height: auto;
      margin-bottom: 100px;
      display: inline-block;

      button {
          display: block;
          padding: 10px 15px;
          margin-bottom: 2px;
          width: 100%;
          border: none;
          outline: none;
          text-align: center;
          cursor: pointer;
          transition: 0.3s;
          font-size: 17px;
          z-index: 2000;
          background: $btn-bg;

          span {
            color: white;
            padding: 5px 10px;
          }
      }

      &.schools {
        button {
          color: white;
          text-align: left;
          background: $btn-schools;
          &.active {
            background: steelblue;
          }
        }
      }
    }

    ul.alphabetical {
      li {
        a {
          color: black;
          text-decoration: underline;
        }
      }
    }

    .wrapper-letter {
      padding: 15px 0;
      border-bottom: 3px solid black;
    }

    .tabcontent {
        float: right;
        padding: 0px 12px;
        width: 85%;
        border-left: none;
        height: auto;
        box-sizing: border-box;

        .container {
          z-index: 1000;
        }

        .col-md-12 {
          padding-left: 0;
          padding-right: 0;
        }

        .form-check-label {
          span {
            color: white;
            padding: 5px 10px;
            display: inline-block;
            margin-right: 5px;
          }
        }
    }

  }
}

@media only screen and (max-width: 1024px) {
  .advanced-search {
    .tab-content {
      .custom-tab {
        width: 100%;
        margin-bottom: 0;

        button {
          width: 24%;
          display: inline-block;
        }
      }
      .tabcontent {
        width: 100%;
        padding: 20px 0;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .advanced-search {
    .tab-content {
      .custom-tab {
        button {
          width: 48%;
        }
      }
    }
  }
}
