@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');
@import "modules/all-developers-input-container";
@import "modules/bootstrap";
@import "partials/variables";
@import "modules/all-developers-input-container";
@import "modules/search-for-category";
@import "modules/dms-reports";
@import "modules/fonts";
@import "modules/scrollbar";
@import "modules/header";
@import "modules/breadcrumbs";
@import "modules/carousel";
@import "modules/home";
@import "modules/landing-page";
@import "modules/advanced-search";
@import "modules/project-view";
@import "modules/stackview";
@import "modules/folio";
@import "modules/dashboard";
@import "modules/dashboard-projects";
@import "modules/dashboard-notifications";
@import "modules/agent-subscription";
@import "modules/modals";
@import "modules/lightbox";
@import "modules/footer";
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
@import "modules/fixes";
@import "modules/bootstrap-slider";
@import "modules/accordion";
@import "modules/simple-donut";
@import "modules/mortgage-calculator";
@import "modules/videos";
@import "modules/featured-project";
@import "modules/eoi";
@import "modules/edit-alias";
@import "modules/profile";
@import "modules/niceselect";
@import "modules/unit";
@import "modules/forms";
@import "modules/contact";
@import "modules/careers";
@import "modules/compare";
@import "modules/navigation";
@import "modules/cards";
@import "modules/buttons";
@import "modules/tables";
@import "modules/agents";
@import "modules/tools";
@import "modules/newsroom";
@import "modules/projects-container";
@import "modules/project-information";

@import "modules/document-container";
@import "modules/documents-sign-container";

body {
  display: flex;
  font-size: 18px;
  color: $main-nav;
  min-height: 100vh;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
}

.greyish-brown {
  color: $greyish-brown;
}

label {
  margin-bottom: 0;
}

.search-select {
  width: 100%;
  height: 38px;
  margin-top: 0;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  .list {
    width: 100%;
    border-radius: 0;
  }
}

h1, h2, h3, h4, h5 {
  color: $main-nav;
  font-weight: bold;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
  &.heading-tower {
    font-size: 23px;
    font-weight: bold;
    color: $carolina-blue;
  }
}

h3 {
  font-size: 21px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-weight: 400;
  font-size: 16px;
}

h4.with-border {
  padding: 5px;
  border-top: 1px solid $fixed;
  border-bottom: 1px solid $fixed;
}

ul, ol {
  ul {
    list-style-type: disc;
    li {
      line-height: 2;
    }
  }
}

i {
  vertical-align: middle;
  display: inline-block;
}

span {
  &.mini-title {
    font-size: 12px;
    font-weight: 500;
    color: $cornflower;
  }
  &.custom-control-description {
    font-size: 16px;
    color: $navy;
  }

  &.plus {
    font-size: 20px;
    font-weight: 500;
    color: $lime-green;
    margin-left: 5px;
    display: inline-block;
  }

  &.quantity {
    width: 24px;
    height: 20px;
    color: white;
    font-size: 12px;
    border-radius: 9px;
    text-align: center;
    display: inline-block;
    background-color: $cornflower;
  }
  &.dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    &.active {
      background-color: $lime-green;
    }
    &.inactive {
      background-color: $cornflower;
    }
  }
  &.unit-status {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    &.sold {
      background-image: linear-gradient(343deg, #97cff6, #96cef5 28%, $medium-blue);
    }
    &.remaining {
      background-color: $lavender-blue;
    }
  }
  &.units {
    font-size: 12px;
    font-weight: 500;
    color: $medium-blue;
  }
  &.updated-on {
    font-size: 12px;
    font-weight: 500;
    color: $cornflower;
    display: block;
  }
  &.bed-type {
    width: 16px;
    height: 16px;
    margin-left: 5px;
    border-radius: 4px;
    display: inline-block;
    &.bed-1 {
      background-color: $bed1;
    }
  }
}

ul {
  li {
    &.ml-auto {
      a {
        padding-left: 10px;
        border-left: 1px solid grey;
      }
    }
  }
}

table {
  empty-cells: hide;

  &.text-smaller {
    font-size: 16px;
    color: black;
    td {
      &.text-success {
        color: #005F16 !important;
      }
      &.text-danger {
        color: #D34836 !important;
      }
    }
  }

  th {
    font-weight: 400;
    text-transform: uppercase;
  }

  td {
    &.dark-grey {
      background-color: #a6aaa9;
    }
    &.light-grey {
      background-color: #ebebeb;
    }
  }
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  &.available {
    background-color: #e5a056;
  }

  &.sold {
    padding-left: 10px;
    background-color: #e9654a;
  }
}

.btn {
  border-radius: 4px;
  &.btn-primary {
    border-bottom: 2px solid $navy;
  }
  &.white {
    border: 1px solid $tabbed-nav-inactive;
    color: $tabbed-nav-inactive;
  }
  &.btn-grey {
    background-color: $fixed;
  }
  &.btn-danger {
    border-bottom: 2px solid #d9534f;
  }
  &.with-icon {
    position: relative;
    i {
      top: 50%;
      right: 10px;
      font-size: 20px;
      position: absolute;
      transform: translateY(-50%);
    }
  }
  &.icon-left {
    text-align: center;
    border-bottom: 2px solid $navy;
    i {
      margin-right: 20px;
    }
  }
  &.no-icon {
    background-color: $dark-grey;
    border-radius: 4px;
    border: none;
    width: 100%;
  }

  &.disabled,
  &:disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
}

.button-flex-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
  a {
    margin-right: 10px;
    box-sizing: border-box;
    flex: 1 0 calc(100% / 7 - 10px);
    &:last-child {
      margin-right: 0;
    }
  }
}

.txt-black {
  color: black;
}

.file-block {
  display: flex;
  padding: 5px 10px;
  margin-bottom: 10px;
  justify-content: space-between;
  border: 1px solid $lighter-grey;
  &.dotted {
    border: 1px dotted;
  }
  a {
    font-size: 16px;
    color: $link !important;
  }
}

.form-control:focus {
  border-color: black;
}

.container-fullscreen {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: fixed;
  background-color: white;
}

.mb-50 {
  margin-bottom: 50%;
}

.m-100 {
  margin: 100px 0;
}

.mt-minus {
  margin-top: -15px;
}

.mt-100 {
  margin-top: 100px;
}

strong {
  font-weight: 500;
}

.larger {
  font-size: 18px;
}

.price-range-scroll {
  max-height: 250px;
  overflow-y: scroll;
}

a.details {
  font-size: 14px;
  font-weight: bold;
  color: $medium-blue !important;
  i {
    font-size: 30px;
    margin-left: 5px;
    font-weight: bold;
  }
}

a.navy-anchor {
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
  color: $navy !important;
  text-transform: uppercase;
}

#return-to-top {
  bottom: 100px;
  right: 20px;
  width: 40px;
  height: 40px;
  display: block;
  position: fixed;
  border-radius: 35px;
  //display: none;
  text-decoration: none;
  transition: all 0.3s ease;
  background: $navy;
  z-index: 1000;
  i {
    top: 11px;
    left: 12px;
    color: white;
    position: absolute;
    transition: all 0.3s ease;
  }
  &:hover {
    background: lighten($color: $navy, $amount: 30%);
  }
}

.application-container {
  width: 120px;
  height: 120px;
  padding: 30px;
  margin: 0 auto;
  border-radius: 50%;
  margin-bottom: 20px;
  border: 2px solid $red;
  
  &.single {
    img {
      max-width: 50px;
    }
  }
  
  &.joint {
    img {
      max-width: 60px;
    }
  }
}

.terms-scrolling {
  max-height: 500px;
  overflow-y: scroll;
}

a:focus {
  color: $link;
}

input, select {
  color: $tabbed-nav-inactive;
  font-family: 'Lato', sans-serif;
}

.custom-select {
  background: url(../../images/caret-down.png) right 0.75rem center / 15px 15px no-repeat rgb(255, 255, 255);
}

img {
  width: 100%;
}

img.obj-fit-cover  {
  height: 100%;
  object-fit: cover;
}

img.obj-fit-contain  {
  height: 100%;
  object-fit: contain;
}

// Buttons

button {
  &.selected {
    border: 2px solid $stackview-selected;
  }
}

button.stackview, a.stackview {
  border: none;
  color: white;
  padding: 10px;
  border-radius: 15px;
  background-color: transparent;
  &.block {
    border: 3px solid $fixed;
    color: $border-grey;
    &.sold-legend {
      border-color: $sold-red;
    }
    &.booked {
      border-color: $booked;
    }
    &:hover {
      background-color: white;
    }
  }
  &.fixed {
    i {
      font-size: 30px;
    }
    color: $nav-link-active;
    border: 1px solid $fixed;
  }
  &.retail {
    background-color: black;
  }
  &.ph {
    background-color: $main-nav;
  }
  &.bed5 {
    background-color: $bed5;
  }
  &.bed4 {
    background-color: $bed4;
  }
  &.bed3 {
    background-color: $bed3;
  }
  &.bed2 {
    background-color: $bed2;
  }
  &.bed1 {
    background-color: $bed1;
  }
  &.reserved {
    color: $reserved;
    background-color: $white;
    border: 1px solid $reserved;
  }
  &.detached {
    background-color: $detached;
  }
  &.semi-detached {
    background-color: $semi-detached;
  }
  &.corner-terrace {
    background-color: $corner-terrace;
  }
  &.interlaced-terrace {
    background-color: $interlaced-terrace;
  }
  &.sold {
    color: $white;
    background-color: $sold-red;
    border: 1px solid $sold-red;
  }
  &.empty {
    background: $sold;
  }
  &.draft {
    color: $sold;
    border: 1px solid $sold;
  }
  &.greyout {
    background: $sold;
    span {
      color: $sold;
    }
  }
  &.unclickable:hover {
    cursor: default;
  }
  &.selected {
    color: white;
    background-color: #8b8d8f;
    &:hover {
      background-color: #8b8d8f;
    }
  }
  &.legend {
    pointer-events: none;
  }
  
}

.btn {
  &:hover {
    cursor: pointer;
  }
}

.transparent-no-radius {
  background: transparent;
  border: 1px solid $sold;
  position: relative;
  width: 100%;
  .icon {
    top: 10px;
    right: 10px;
    position: absolute;
  }
}

.dropdown {
  .btn {
    color: $border-grey;

    &:hover, &:focus {
      background: white;
    }
  }
}

a.stackview {
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;

  &:hover {
    text-decoration: none;
  }
}

.custom-control-description {
  color: dimgrey;
  strong {
    display: inline-block;
    min-width: 150px;
  }
}

// Switches
.switch {
  width: 60px;
  height: 30px;
  margin-left: 40px;
  position: relative;
  display: inline-block;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.switch-slider:before {
  left: 1px;
  content: "";
  width: 32px;
  height: 32px;
  bottom: -1px;
  transition: .4s;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
}

input:checked + .switch-slider {
  background-color: #2196F3;
}

input:focus + .switch-slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .switch-slider:before {
  transform: translateX(26px);
}

/* Rounded switch-sliders */
.switch-slider.round {
  border-radius: 34px;
}

.switch-slider.round:before {
  border-radius: 50%;
}

// Custom Utilities

.padding-around {
  padding: 150px;

  p {
    padding: 0 25%;
  }
}

.table {
  .icon-reject {
    color: red;
    &:hover {
      cursor: pointer;
    }
  }
}

.text-container {
  max-height: 400px;
  overflow-y: scroll;
}

.custom-control-input {
  opacity: 1 !important;
  width: $custom-control-indicator-size !important;
  height: ($font-size-base * $line-height-base + $custom-control-indicator-size) / 2 !important;
}

@media (min-width: 1200px) {
  .container {
      width: 1300px;
      max-width: 100%;
  }
}

@media (min-width: 1024px) {
  .table-responsive {
    display: inline-table;
  }
}

@media (max-width: 768px) {
  body {
    font-size: 16px;
  }

  main {
    margin-top: 140px;
  }

  .vr-set {
    margin-top: 30px;
    .btn {
      width: 200px;
      margin: 0 auto;
    }
  }

}

@media only screen and (max-width: 640px) {

  .mt-mobile {
    margin-top: 10px;
  }

  button.stackview.fixed i, 
  a.stackview.fixed i {
    font-size: 20px;
  } 
}
