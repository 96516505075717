// Variables

$grey:      #cdcdcd;
$dark-grey: #6f6f6f;
$black:     #4a4a4a;

.projects-container {
  display: grid;
  grid-gap: 2rem;
  margin: 0 auto;
  max-width: 1296px;
  grid-column: center-start / center-end;
  -ms-grid-columns: (minmax(22rem, 1fr))[auto-fit];
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
}

.project {
  min-width: 400px;
  height: auto;
  // position: relative;
  border: 1px solid $grey;
  
  &__image {
    margin: 0;
    padding: 0;
    height: 320px;
    position: relative;
    img.card-label, img.card-soldout {
      top: 0;
      left: 0;
      width: 70px;
      height: auto;
      position: absolute;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  
  &__content {
    padding: 15px;
    &__details {
      display: flex;
      .project__logo {
        margin: 0;
        padding: 0;
        width: 120px;
        height: 120px;
        border: solid 1px $grey;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .project__text {
        width: 216px;
        margin-left: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border: 0 !important;
        .share-container {
          color: $main-nav;
          margin-top: -20px;
          a {
            font-size: 14px;
            color: $main-nav;
            margin-right: 5px;
            display: inline-block;
          }
        }

        &__address,
        &__type,
        &__tenure {
          display: block;
          font-size: 14px;
          font-weight: normal;
          color: $main-nav;
        }

        p {
          margin: 0;
        }

        &__name {
          line-height: 1;
          font-size: 21px;
          font-weight: bold;
          color: $dark-grey;
          a {
            color: $main-nav;
            text-decoration: none;
          }
        }

        hr {
          margin-top: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .project {
    
    &__content {
      &__details {
        .project__logo {
          width: 100px;
          height: 100px;
        }
        .project__text {
          padding-bottom: 5px;
        }
      }
    }
  }
}
