@import "../partials/variables";

.search-for-category-container {
  display: flex;
  height: 50px;

  .form-control {
    border-radius: 4px;
    margin-right: 15px;
    width: calc(100% - 150px);
    border: 1px solid $carolina-blue;
    box-shadow: 0px 3px 6px #00204E;

    &::-webkit-input-placeholder { /* Edge */
      color: $carolina-blue;
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $carolina-blue;
    }
    
    &::placeholder {
      color: $carolina-blue;
    }
  }

  .primary {
    width: 135px;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 2px 3px 2px black;
  }

}

.categories-created {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3.h3-light {
    font-size: 20px;
    font-weight: 300;
    width: calc(100% - 120px);
  }

  &__controls {
    width: 120px;
    display: flex;
    justify-content: space-between;
    &__btn {
      width: 50px;
      height: 50px;
      color: white;
      border-radius: 50%;
      text-align: center;
      margin-left: 10px;
      background-color: $medium-blue;
      box-shadow: 0px 3px 6px #000029;

      &--secondary {
        background-color: $sky-blue;
      }

      .icon, .fa {
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
    }
    &--variation {
      justify-content: flex-end;
    }
  }

}

.category-block {
  color: $navy;
  height: 60px;
  display: flex;
  padding: 20px;
  font-size: 16px;
  cursor: pointer;
  align-items: center;
  margin-bottom: 20px;
  background-color: white;
  border: 1px solid $carolina-blue;

  &__title {
    width: 40%;
    display: flex;
    justify-content: left;

    input[type="text"] {
      width: 70%;
      border-radius: 4px;
      border: 1px solid $carolina-blue;
    }
  }
  &__number {
    width: 50%;
    display: flex;
    justify-content: left;
  }
  &__icon {
    width: 10%;
    display: flex;
    font-size: 30px;
    font-weight: bold;
    justify-content: center;

    
    img {
      width: 40px;
      height: 40px;
    }
  }

  &--manage-emails {
    .category-block__title {
      width: 70%;
    }
    .category-block__switch {
      width: 25%;
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        font-weight: bold;
      }
      .switch {
        width: 60px;
        height: 28px;
        margin-left: 0;
        margin: 0 10px;
        position: relative;
        display: inline-block;
        
        input { 
          width: 0;
          height: 0;
          opacity: 0;
        }
      }
      
      .slider {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: .4s;
        cursor: pointer;
        position: absolute;
        background-color: $dashboard-bg;
      }
      
      .slider:before {
        position: absolute;
        content: "";
        height: 30px;
        width: 30px;
        left: 3px;
        bottom: 0;
        transition: .4s;
        background-color: white;
        box-shadow: -2px 1px 2px #00204E;
      }
      
      input:checked + .slider {
        background-color: $medium-blue;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px $medium-blue;
      }
      
      input:checked + .slider:before {
        transform: translateX(26px);
      }
      
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
        &:before {
          border-radius: 50%;
        }
      }
      
    }
    .category-block__icon {
      width: 5%;
    }
  }

  &__dropdown {
    height: auto;
    margin: -20px 0 20px 0;
    background-color: white;
    border: 1px solid $carolina-blue;
    border-top: 0;

    .title {
      color: $navy;
      font-size: 16px;
      font-weight: bold;
      padding-left: 20px;
    }

    &__frequency {
      height: 90px;
      padding: 10px 0;
      border-bottom: 1px solid $carolina-blue;

      .controls-file-formats {
        padding: 10px 0 10px 20px;

        &__label-container {
          width: 20%;
        }

      }
    }

    &__settings {
      padding: 20px;

      .title {
        padding-left: 0;
        margin-top: 20px;
      }

      .selection-area {
        padding: 10px;
        height: auto;
        margin: 20px 0;
        min-height: 150px;
        border-radius: 8px;
        position: relative;
        border: 1px solid $carolina-blue;

        .labels-container {
          margin-top: 50px;
        }

        input {
          top: 0;
          left: 0;
          right: 0;
          border: 0;
          width: 100%;
          height: 50px;
          padding-left: 20px;
          position: absolute;
          border-radius: 8px 8px 0 0;
          border-bottom: 1px solid $carolina-blue;

          &::-webkit-input-placeholder { /* Edge */
            font-size: 16px;
            color: $carolina-blue;
          }
          
          &:-ms-input-placeholder {
             /* Internet Explorer 10-11 */
            font-size: 16px;
            color: $carolina-blue;
          }
          
          &::placeholder {
            font-size: 16px;
            color: $carolina-blue;
          }

        }
        
      }
      .button-container {
        display: flex;
        justify-content: flex-end;
        .primary {
          height: 50px;
          width: 150px;
          font-size: 14px;
          font-weight: bold;
          border-radius: 8px;
          box-shadow: 0px 3px 6px #000029;
        }
      }
    }
  
  }
}