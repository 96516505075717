footer {
  font-size: 18px;
  position: relative;
  background: white;
  margin-top: 20px;
  .social-media {
    padding: 15px 0;
    .list-inline-item:not(:last-child) {
      margin-right: 20px;
    }
    a {
      font-size: 30px;
    }
  }

  .social-media {
    a {
      display: inline-block;
      img {
        display: block;
        max-width: 32px;
      }
    }
  }

  .footer-navigation {
    padding: 0 20px;
    
    .link-group {
      float: left;
      width: 20%;
      padding: 0 2.5%;
      .link-group-title {
        margin-bottom: 15px;
        font-weight: bold;
        color: $main-nav;
        .fa {
          display: none;
        }
      }
      ul {
        li {
          padding-bottom: 20px;
          a {
            color: $main-nav;
          }
        }
      }
    }
  }

  .bottom-footer {
    color: white;
    padding: 20px 0;
    font-size: 16px;
    background: $navy;
    position: relative;
    img {
      top: 5px;
      right: 5px;
      width: 222px; //90px;
      height: 54px; //50px;
      position: absolute;
    }
  }
  &.dashboard-footer {
    margin-top: 0;
  }
}

@media only screen and (max-width: 1024px) { 
  footer {
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {
  footer {
    font-size: 14px;
    .footer-navigation {
      .link-group {
        width: 50%;
        .link-group-title {
          .fa {
            display: inline-block;
            padding-left: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  
  footer {

    .footer-navigation {
      .link-group {
        .link-group-title {
          cursor: pointer;
        }
        ul {
          transition: all .5s ease;
        }
      }
    }
    
    .bottom-footer {
      font-size: 10px;
      img {
        top: 18px;
        right: 5px;
        width: 39px;
        height: auto;
      }
    }
  }
}