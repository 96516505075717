.mortgage-calculator {
    max-width: 60%;
    margin: 0 auto;

    #monthly-payment {
        width: 200px;
        height: 100px;
        display: flex;
        margin: 0 auto;
        background: $grey;
        align-items: center;
        justify-content: center;
    }

    // .slider {
    //     -webkit-appearance: none;
    //     height: 5px;
    //     width: 100%;
    //     background-image: gradient(
    //         linear,
    //         left top,
    //         right top,
    //         color-stop(0, #0077c8),
    //         color-stop(0, #C5C5C5)
    //     );
    //     background-color: $light-blue;
    // }



    // .slider::-moz-range-track {
    //     -moz-apperance: none;
    //     height: 5px;
    //     width: 100%;
    //     background-image: gradient(
    //         linear,
    //         left top,
    //         right top,
    //         color-stop(0, #0077c8),
    //         color-stop(0, #C5C5C5)
    //     );
    //     background-color: $light-blue;
    // }
        
    // .slider::-webkit-slider-thumb {
    //     -webkit-appearance: none !important;
    //     border: 1px solid $light-blue;
    //     background-color: white;
    //     border-radius: 50%;
    //     height: 30px;
    //     width: 30px;
    // }

    // .slider::-moz-range-thumb {
    //     background-color: white;
    //     border-radius: 50%;
    //     border: 1px solid $light-blue;  
    //     height: 30px;
    //     width: 30px;
    // }

    // .slider::-webkit-slider-thumb:hover {
    //     cursor: pointer;
    // }


    $track-color: $light-blue !default;
    $thumb-color: white !default;
    
    $thumb-radius: 50% !default;
    $thumb-height: 30px !default;
    $thumb-width: 30px !default;
    $thumb-shadow-size: 4px !default;
    $thumb-shadow-blur: 4px !default;
    $thumb-shadow-color: rgba(0, 0, 0, .2) !default;
    
    $track-width: 100% !default;
    $track-height: 5px !default;
    $track-shadow-size: 1px !default;
    $track-shadow-blur: 1px !default;
    $track-shadow-color: rgba(0, 0, 0, .2) !default;
    $track-border-width: 2px !default;
    $track-border-color: #cfd8dc !default;
    
    $contrast: 5% !default;
    
    @mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
      box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color, 0 0 $shadow-size lighten($shadow-color, 5%);
    }
    
    @mixin track {
      cursor: pointer;
      height: $track-height;
      transition: all .2s ease;
      width: $track-width;
    }
    
    @mixin thumb {
      @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
      background: $thumb-color;
      border-radius: $thumb-radius;
      cursor: pointer;
      height: $thumb-height;
      width: $thumb-width;
    }
    
    [type='range'].range-slider {
      -webkit-appearance: none;
      -moz-appearance: none;
      margin: $thumb-height / 2 0;
      width: $track-width;
    
      &:focus {
        outline: 0;

        &::-moz-range-track {
            background: lighten($track-color, $contrast);
        }
    
        &::-webkit-slider-runnable-track {
          background: lighten($track-color, $contrast);
        }
    
        &::-ms-fill-lower {
          background: $track-color;
        }
    
        &::-ms-fill-upper {
          background: lighten($track-color, $contrast);
        }
      }
    
      &::-webkit-slider-runnable-track {
        @include track;
        @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
        background: $track-color;
      }
    
      &::-webkit-slider-thumb {
        @include thumb;
        -webkit-appearance: none;
        margin-top: ((-$track-border-width * 2 + $track-height) / 2) - ($thumb-height / 2);
      }
    
      &::-moz-range-track {
        @include track;
        @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
        background: $track-color;
      }
    
      &::-moz-range-thumb {
        @include thumb;
      }
    
      &::-ms-track {
        @include track;
        background: transparent;
        border-color: transparent;
        border-width: ($thumb-height / 2) 0;
        color: transparent;
      }
    
      &::-ms-fill-lower {
        @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
        background: darken($track-color, $contrast);
      }
    
      &::-ms-fill-upper {
        @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
        background: $track-color;
      }
    
      &::-ms-thumb {
        @include thumb;
        margin-top: 0;
      }
    }


}