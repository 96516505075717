@import "../partials/variables";

.reports-container {
  height: auto;
  margin-bottom: 20px;
  box-sizing: border-box;
  border: 1px solid $carolina-blue;

  .report {
    height: 116px;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 20px;

    .report-thumb {
      width: 2%;
      top: 0;
      right: 0;
      position: absolute;
      .report-thumb-img {
        top: 0;
        right: 0;
        position: absolute;
        img {
          height: 40px;
          width: auto;
        }
      }

    }
    .report-info {
      width: 98%;
      padding: 20px 40px;
      .top-info {
        color: $navy;
        .report__title {
          font-weight: bold;
        }
      }
      .report-info-logo img {
        width: 105px;
        height: 95px;
        border: 1px solid $grey;
      }
      .report-info-text {
        .text-container {
          padding: 10px 0;
          p {
            margin-bottom: 0;
          }
        }
      }
      .bottom-info {
        p {
          margin-bottom: 0;
        }
        .social-container {
          ul {
            list-style: none;
            padding-left: 0;
            li {
              display: inline-block;
              padding-left: 10px;
              a {
                color: $main-nav;
              }
            }
          }
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid $carolina-blue;
    }

    &:nth-child(odd) {
      background-color: white;
    }

    &:nth-child(even) {
      background-color: $greyish-blue;
    }

    &__text {
      display: flex;
      flex-direction: column;

      &__title, &__description {
        color: $navy;
        font-size: 16px;
      }
  
      &__title {
        font-weight: bold;
      }
  
      &__description {
        font-weight: 300;
      }
    }

    &__download {
      top: 50%;
      right: 50px;
      color: $navy;
      cursor: pointer;
      font-size: 25px;
      position: absolute;
      transform: translateY(-50%);
      button {
        width: 40px;
        height: 40px;
        padding: 10px;
        color: white;
        font-size: 20px;
        border-radius: 50%;
        background-color: $medium-blue;
      }
    }
  }

}

.dms-reports-alert-container {
  margin-top: 20px;
}

.create-new-report {
  display: flex;
  font-size: 16px;
  color: $navy;
  font-weight: bold;
  align-items: center;
  justify-content: flex-end;
  button {
    width: 20px;
    height: 20px;
    padding: 2px;
    font-size: 14px;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    background-color: $medium-blue;
  }
}