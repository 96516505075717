.notifications-search {
  position: relative;
  .btn {
    top: 4px;
    left: 0;    
    position: absolute;
    color: $navy !important;
    outline: none;
    min-width: 20px !important;
    background-color: transparent;
  }
}

.notifications-panel-container {
  border: 1px solid $fixed;
  .notifications-panel-headers {
    display: flex;
    padding: 10px;
    background-color: $new-ui-border;
    height: 40px;
    position: relative;
    top: -1px;
    .notifications-panel-heading {
      font-size: 14px;
      flex: 1 (100% / 8);
      text-align: center;
    }
  }
  .notifications-panel-content-container {
    display: flex;
    padding: 20px 0;
    background-color: white;
    .notifications-panel-content {
      font-size: 14px;
      flex: 1 (100% / 8);
      text-align: center;
      p {
        margin-bottom: 0;
      }
      .status {
        width: 10px;
        height: 10px;
        margin: 0 auto;
        padding-top: 5px;
        border-radius: 50%;
        display: inline-block;
        &.active {
          background-color: steelblue;
        }
        &.inactive {
          background-color: gray;
        }
      }
    }
  }
  
  .notification-message {
    padding: 20px;
    font-size: 12px;
    textarea {
      padding: 20px;
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $fixed;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: $fixed;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: $fixed;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: $fixed;
      }
    }
    .btn-group {
      .btn {
        width: 100%;
      }
    }
  }

  .card-label {
    position: relative;
    width: 46px;
    height: 46px;
    left: 16px;
    bottom: 16px;
  }
}

.table-notifications {
  th {
    text-align: center;
    background-color: #ccc;
  }
  tbody {
    td {
      font-size: 14px;
      text-align: center;
      img {
        max-width: 100px;
      }
      &.review {
        text-align: left;
      }
      ul {
        li {
          color: grey;
          &.active {
            color: yellow;
          }
        }
      }
      span {
        display: block;
        margin-bottom: 5px;
        &:hover {
          cursor: pointer;
        }
        &.reject {
          .icon {
            color: $red;
          }              
        }
        &.accept {
          .icon {
            color: green;
          }              
        }
      }
      &.text-danger {
        &:hover {
          cursor: pointer;
        }
      }
    }
    background-color: white;
  }
  }